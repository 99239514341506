import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output, SimpleChanges,
  ViewChild
} from '@angular/core';
import {HelpersService} from '../../services/helpers.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-virtual-scroll-select',
  templateUrl: './virtual-scroll-select.component.html',
  styleUrls: ['./virtual-scroll-select.component.scss']
})
export class VirtualScrollSelectComponent implements OnChanges, OnInit, OnDestroy {

  @Input() data;
  @Input() value;
  @Input() uniqueSelectId;
  @Input() searchFull;
  @Input() hasError;
  @Input() isAirportsDisabled = false;
  @Input() isSmall = false;
  @Input() placeholder: string;
  @Output() emitSelectTouched = new EventEmitter();
  @Output() emitValueChanged = new EventEmitter();
  @Output() emitSearch = new EventEmitter();
  @ViewChild('searchInput') el: ElementRef;
  @ViewChild('valueContainerButton') valueContainerButton: ElementRef<HTMLButtonElement>;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  showData: any[];
  isShowList: boolean = false;
  inputValue: string = '';
  selectedIndex: number;
  listHeight: number = 0;
  itemListHeight: number = 31;
  city: string;

  @HostListener('document:click', ['$event']) clickout({ target }: { target: HTMLElement }) {
    const allowedClasses = ['input-search', 'value-container', 'main-select-wp', 'first-value', 'second-value'];

    if (!allowedClasses.some(className => target.classList.contains(className))) {
      this.isShowList = false;
      this.showData = [];
      this.inputValue = '';
    }
  }

  constructor(private helpersService: HelpersService) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value && changes.value.currentValue && !this.isSmall) {
      const airport = this.data.find((airport) => airport.id === this.value);
      if (airport?.city) {
        this.city = airport.city;
      } else if (airport?.text) {
        const metropolitanAreaIndex = airport.text.indexOf("Metropolitan Area of");
        this.city = metropolitanAreaIndex !== -1
          ? airport.text.substring(metropolitanAreaIndex + "Metropolitan Area of".length).trim()
          : ' ';
      } else {
        this.city = ' ';
      }
    }
  }

  search() {
    if (this.value) {
      this.isShowList = false;
      this.emitSearch.emit();
    }
  }

  ngOnInit() {
    this.showData = [];
    this.setListContainerHeight(this.showData.length);

    this.selectedIndex = 0;
    this.helpersService.nextActiveSelectId$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(res => {
        if (res && this.uniqueSelectId && res !== this.uniqueSelectId) {
          this.isShowList = false;
        }
      });
  }

  focusButton(): void {
    if (this.valueContainerButton) {
      this.valueContainerButton.nativeElement.focus();
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onSearchInput({target}) {
    if (!this.isValidInput(target.value)) {
      this.el.nativeElement.value = this.inputValue;
      return;
    }
    if (target.value.length > 3 && !this.searchFull) {
      this.el.nativeElement.value = target.value.slice(0, 3);
    }
    this.inputValue = target.value;
    this.selectedIndex = -1;
    if (!this.searchFull) {
      this.showData = this.data.filter(item => this.inputValue && item.text.toLowerCase().startsWith(this.inputValue.toLowerCase()));
    } else {
      let sortingArr = [];
      this.data.map(item => {
        if (this.inputValue && item.text.toLowerCase().startsWith(this.inputValue.toLowerCase()) && !sortingArr.includes(item.id)) {
          sortingArr.unshift(item.id)
        }
      });
      this.showData = this.data.filter(item => this.inputValue && this.inputValue && item.text.toLowerCase().includes(this.inputValue.toLowerCase()))
                                .sort((a, b) => a.id.localeCompare(b.id)).sort((a, b) => sortingArr.indexOf(b.id) - sortingArr.indexOf(a.id));
    }
    this.setListContainerHeight(this.showData.length);
  }

  showList() {
    this.listHeight = 0;
    this.isShowList = !this.isShowList;
    this.emitSelectTouched.emit(this.isShowList);
    this.showData = [];
    if (this.isShowList) {
      if (this.uniqueSelectId) {
        this.helpersService.nextActiveSelectId$.next(this.uniqueSelectId);
      }
      setTimeout(() => {
        if (this.el && this.el.nativeElement) {
          this.el.nativeElement.focus();
        }
      }, 30);
    }
  }

  selectedFreeItem() {
    this.emitValueChanged.emit({id: this.inputValue.toUpperCase(), text: this.inputValue});
    this.showData = [];
    this.inputValue = '';
    this.isShowList = false;
  }

  selectedListItem(item) {
    this.emitValueChanged.emit(item);
    this.showData = [];
    this.inputValue = '';
    this.isShowList = false;
  }

  keyDownHandler(event, vsRef: ElementRef) {
    switch (event.key) {
      case 'ArrowDown':
        this.onArrowDown(vsRef);
        return;
      case 'ArrowUp':
        this.onArrowUp(vsRef);
        return;
      case 'Enter':
        this.pressEnterKey();
        return;
    }
  }

  pressEnterKey() {
    let element: HTMLElement = document.getElementsByClassName('focus-background')[0] as HTMLElement;
    if (element !== undefined) {
      element.click();
    }
  }

  onArrowUp(vsRef) {
    if (this.inputValue && this.selectedIndex === -1) return;
    if (!this.inputValue && this.selectedIndex === 0) return;
    if (!this.showData.length) {
      this.selectedIndex = -1;
      return;
    }
    this.selectedIndex--;
    vsRef.scrollToIndex(this.selectedIndex - 4);
  }

  onArrowDown(vsRef) {
    if (this.selectedIndex === this.showData.length -1) return;
    this.selectedIndex++;
    if (!this.showData.length) {
      this.selectedIndex = -1;
    }
    vsRef.scrollToIndex(this.selectedIndex - 4);
  }

  setListContainerHeight(countItems: number) {
    if (countItems === 0) this.listHeight = 0;

    if (countItems > 0 && countItems < 6) {
      this.listHeight = this.itemListHeight * countItems;
    }
    if (countItems >= 6) {
      this.listHeight = 200;
    }
  }

  private isValidInput(str: string): boolean {
    const regex = /^[a-zA-Z0-9,\-\s]*$/;
    return regex.test(str);
  }
}
