<div class="modal-header">
  <h3 class="modal-title">{{ title }}</h3>
  <button type="button" class="close" aria-label="Close" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <h3 class="font-weight-normal lh-24">{{ message }}</h3>
  <div class="custom-control custom-checkbox my-1 mr-sm-2">
    <input type="checkbox" class="custom-control-input" id="customControlInline" (change)="ls.isInfoModalSkipped = $event.target.checked">
    <label class="custom-control-label fs-16" for="customControlInline">Do not show this message again</label>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="decline()">{{ btnCancelText }}</button>
  <button type="button" class="btn btn-primary" (click)="accept()">{{ btnOkText }}</button>
</div>
