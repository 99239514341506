<nav class="navbar navbar-expand-lg navbar-light bg-light static-top main-header">
  <div class="container">
    <a class="navbar-brand inversed" href="/" tabindex="-1">
      <img *ngIf="viewOpts.logo" [src]="frontendSelectedDependency.logoName | getLogoPath" (error)="showDefaultLogo($event)">
    </a>
    <div class="form-inline" *ngIf="authService.isAuth$ | async">
      <ul class="navbar-nav align-items-center">
        <li class="nav-item" *ngIf="viewOpts.search">
          <a
            (click)="navigate('/search', $event)"
            [class.active]="isSearchPage"
            class="nav-link px-3"
          >
            Shop </a>
        </li>
        <li class="nav-item" *ngIf="viewOpts.orders">
          <a
            (click)="navigate('/orders', $event)"
            [class.active]="isOrdersPage"
            class="nav-link px-3"
          >
            Orders</a>
        </li>
        <li *ngIf="viewOpts.subagencies" class="nav-item">
          <a
            (click)="navigate('/subagencies?showSubagencies=true', $event)"
            [class.active]="isCustomerOrdersPage"
            class="nav-link px-3"
          >
            Subagencies</a>
        </li>
        <li class="nav-item" *ngIf="viewOpts.corporates">
          <a
            (click)="navigate('/corporates', $event)"
            [class.active]="isCorporatesPage"
            class="nav-link px-3"
          >
            Corporates</a>
        </li>
        <li class="pl-4 pr-2">
          <div class="nav-item dropdown settings-dropdown" ngbDropdown autoClose="outside" placement="bottom" #settingsDropdown="ngbDropdown">
            <a class="nav-link dropdown-toggle" aria-haspopup="true" aria-expanded="false" data-bs-auto-close="false"
               ngbDropdownToggle>
              <span class="material-icons-outlined">
                settings
              </span>
            </a>
            <div class="dropdown-menu dropdown-menu-center" ngbDropdownMenu>
              <a *ngIf="viewOpts.agents"
                 (click)="helpers.go('/agents'); settingsDropdown.close()"
                 class="dropdown-item inversed"
              >
                Agents management
              </a>
              <a *ngIf="viewOpts.agencies"
                 (click)="helpers.go('/agencies'); settingsDropdown.close()"
                 class="dropdown-item inversed"
              >
                Subagencies management
              </a>
              <a *ngIf="viewOpts.presets"
                 (click)="helpers.go('/presets'); settingsDropdown.close()"
                 class="dropdown-item inversed"
              >
                Presets
              </a>
              <a *ngIf="viewOpts.settings"
                 (click)="openSettings(settingsModal); settingsDropdown.close()"
                 class="dropdown-item inversed">
                Settings
              </a>
            </div>
          </div>
        </li>
        <li *ngIf="viewOpts.user">
          <div class="nav-item dropdown username-dropdown" ngbDropdown autoClose="outside" #usernameDropdown="ngbDropdown">
            <a class="nav-link username-toggle dropdown-toggle" aria-haspopup="true" aria-expanded="false" data-bs-auto-close="false"
               ngbDropdownToggle>
              <div class="d-flex align-items-center gap-5">
                {{ ls.email }}
                <ng-container *ngIf="currentAgency">
                  <div class="agency-img agency-img-container current-agency">
                    {{currentAgency.name.substring(0, 1).toUpperCase()}}
                  </div>
                </ng-container>
              </div>
            </a>
            <div class="dropdown-menu username-dropdown-menu" ngbDropdownMenu>
              <div class="dropdown nested-agencies-dropdown" ngbDropdown [open]="usernameDropdown.isOpen()" *ngIf="agencies.length">
                <span class="dropdown-item inversed" aria-haspopup="true" aria-expanded="true">
                  Your agencies
                </span>
                <div class="dropdown-menu" ngbDropdownMenu>
                  <a *ngFor="let agency of agencies"
                     class="dropdown-item inversed"
                     [class.current-agency-item]="agency.id === currentAgency?.id">
                    <div class="d-flex align-items-center gap-8"
                         (click)="agency.id !== currentAgency?.id ? switchAgency(agency) : null; usernameDropdown.close()">
                      <div class="agency-img agency-img-container" [class.current-agency]="agency.id === currentAgency?.id">
                        {{agency.name.substring(0, 1).toUpperCase()}}
                      </div>
                      <div class="d-flex flex-column">
                        <div class="fw-500">
                          {{agency.name}}
                        </div>
                        <div *ngIf="agency.iata_number">
                          {{agency.iata_number}}
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <a class="dropdown-item inversed logout" (click)="logout()">
                <span class="material-symbols-outlined logout-icon">
                  logout
                </span>
                Log Out
              </a>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>
<div class="trace-info" *ngIf="service.lastSessionID && ls.settings.enableTracing">
  Trace Session ID: {{ service.lastSessionID }}
</div>


<ng-template #settingsModal let-c="close" let-d="dismiss">
  <div class="modal-card card">
    <div class="card-header" style="max-height: 75px">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="card-header-title">Settings</h3>
        </div>
        <div class="col-auto">
          <button type="button" class="close" data-dismiss="modal" (click)="d('closed')">×</button>
        </div>
      </div>
    </div>

    <div class="card-body modal-card-body-height settings-body">

      <div class="row item-wp">
        <div class="text-left item-control checkbox" [class.disabled]="selectDisabled">
          <input
            #enableLimitOfOffers
            id="enableLimitOfOffers"
            type="checkbox"
            [value]="1"
            [checked]="ls.settings.enableLimitOfOffers">
          <label for="enableLimitOfOffers">Enable limit of offers</label>
        </div>
      </div>

      <div class="row item-wp" *ngIf="enableLimitOfOffers.checked">
        <div class="text-left item-control" [class.disabled]="selectDisabled">
          <div>Max offers per cabin:</div>
          <div>
            <input
            id="maxOffersPerCabin"
            type="number"
            [value]="ls.settings.maxOffersPerCabin || 1"
            (change)="onChangeMaxOffersPerCabin($event.target.value)"
            class="form-control">
          </div>
        </div>
      </div>

      <div class="row item-wp">
        <div class="text-left item-control">
          <div>Currency:</div>
          <ng-select2
            [ngModel]="selectedCurrency"
            class="ng-select2-form-control"
            [class.disabled]="selectDisabled"
            [placeholder]="'Select currency ...'"
            (click)="helpers.setFocusToSelect2SearchField();"
            (valueChanged)="setSelectCurrency($event)"
          >
            <option value=""></option>
            <option *ngFor="let currency of currencies" [value]="currency">{{currency}} <img src="src/assets/img/illustrations/keyboard_arrow_down.svg" alt=""></option>
          </ng-select2>
        </div>
      </div>

      <div class="row item-wp">
        <div class="text-left item-control">
        <div class="">Date Format:</div>
          <div class="dropdown btn-group" ngbDropdown #dateDropdown="ngbDropdown">
            <button
              class="btn btn-light"
              aria-haspopup="true"
              aria-expanded="false"
              ngbDropdownToggle
            >
              {{ selectedDateFormat || 'Date format' }}
            </button>
            <ul ngbDropdownMenu class="list">
              <li *ngFor="let format of dateService.dateFormatOptions"
                  (click)="setDateFormat(format); dateDropdown.close()"
                  class="list-item"
              >
                <div>{{ format }}</div>
                <div>{{ currentDate | MomentDate: format | uppercase}}</div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="row item-wp">
        <div class="text-left item-control">
          <div>Time Zone:</div>
          <ng-select2
            [ngModel]="selectedTimezone"
            class="ng-select2-form-control"
            [placeholder]="'Select Time Zone ...'"
            (click)="helpers.setFocusToSelect2SearchField();"
            (valueChanged)="setTimezone($event)"
          >
            <option *ngFor="let tz of timezoneService.getTimezones()" [value]="tz">
              {{ tz }}
            </option>
          </ng-select2>
        </div>
      </div>

      <div class="row item-wp">
        <div class="text-left item-control">
          <div>Preferred stops during flight:</div>
          <div class="dropdown btn-group" ngbDropdown #stopsDropdown="ngbDropdown" [class.disabled]="selectDisabled">
            <button
              class="btn btn-light"
              aria-haspopup="true"
              aria-expanded="false"
              ngbDropdownToggle
            >
              {{ selectedStopsAmount.title }}
            </button>
            <ul ngbDropdownMenu class="list">
              <li *ngFor="let amount of stopDuringFlight"
                  (click)="setSelectStops(amount); stopsDropdown.close()"
                  class="list-item"
              >
                {{amount.title}}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="row item-wp">
        <div class="text-left item-control">
          <div class="">Country phone code:</div>
          <input
            (countryChange)="onCountryChange($event)"
            (intlTelInputObject)="telInputObject($event)"
            [ng2TelInputOptions]="helpers.defaultTelInputOptions"
            class="form-control"
            ng2TelInput>
        </div>
      </div>

      <div class="row item-wp">
        <div class="text-left item-control">
          <div>Version:</div>
          <div class="btn btn-light pointer-events-none">
            NDC API v1.2
          </div>
        </div>
      </div>

      <div class="row item-wp mt-3">
        <div class="item-control">
          <button class="btn btn-tonal-primary py-2" (click)="updatePassword()">Update password</button>
        </div>
      </div>

      <div class="row item-wp umbrella-items mt-3" *ngIf="ls.profilesType === 'umbrella'">
        <div class="item-control">
          <h3 class="text-center red-color">Umbrella</h3>
          <button class="btn btn-primary btn-umbrella-login mb-3" *ngIf="ls.clientId"
                  (click)="umbrellaService.redirectToUmbrellaLogin()"
                  [textContent]="!ls.umbrellaToken ? 'Log in' : 'Reconnect'"></button>
          <button *ngIf="ls.umbrellaToken" class="btn btn-primary" (click)="umbrellaLogOut()">Log out</button>
        </div>
      </div>

    </div>
    <div class="card-footer">
      <span
        (click)="changeSettings(selectedCurrency, enableLimitOfOffers.checked); c('ok')"
        class="btn btn-primary float-right"
      >Save</span>
      <span
        (click)=" d('close')"
        class="btn btn-secondary float-right mr-2"
      >Close</span>
    </div>
  </div>
</ng-template>
