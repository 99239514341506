<div class="row" *ngIf="showLoader">
  <div class="col">
    <div class="loader-wrapper">
      <app-loader></app-loader>
    </div>
  </div>
</div>

<ng-container *ngIf="!showLoader">
  <div class="row my-4">
    <div class="col search-corporate" *ngIf="corporates.length">
      <div class="input-group">
        <span class="input-group-prepend">
          <div class="input-group-text border-right-0"><i class="fa fa-search" [ngClass]="{'focused-search-icon': isSearchInputFocused}"></i></div>
        </span>
        <input class="form-control py-2 pl-0 border-left-0 border"
               type="text"
               autocomplete="off"
               placeholder="Search corporate"
               (input)="onSearchCorporate($event.target.value)"
               (focus)="isSearchInputFocused = true"
               (blur)="isSearchInputFocused = false"
        />
      </div>
    </div>
    <div class="col">
      <button class="btn btn-primary rounded float-right"
              title="Add new corporate"
              (click)="open(addCorporateModal, 'md')">
        <i class='fa fa-plus'></i>
      </button>
    </div>
  </div>

  <div class="table-responsive" *ngIf="filteredCorporates.length">
    <table class="table table-default table-hover mb-0">
      <thead>
      <tr>
        <th scope="col">Corporate</th>
        <th scope="col">Account ID</th>
        <th scope="col" class="text-center">Managers / Travelers</th>
        <th scope="col"></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let corporate of filteredCorporates | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize; let idx = index;"
          (click)="helpers.go('/corporates/' + corporate.id)">
        <td>
          {{corporate.name}}
        </td>
        <td>{{corporate.account_number}}</td>
        <td><ng-container
            [ngTemplateOutlet]="travAndMng"
            [ngTemplateOutletContext]="{
              travelerCountAndMng: corporate.counters.travelers + corporate.counters.managers,
              travelerCount: corporate.counters.travelers,
              managerCount: corporate.counters.managers
            }"></ng-container>
        </td>
        <td>
          <div class="d-flex">
            <i class="material-symbols-outlined"
               title="Edit"
               (click)="$event.stopPropagation(); openCorporateModal(editCorporateModal, 'md', corporate)">
              edit
            </i>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="row" *ngIf="!filteredCorporates.length">
    <div class="col">
      No corporates found
    </div>
  </div>
</ng-container>

<ng-template #travAndMng let-travelerCount='travelerCount' let-managerCount='managerCount'>
  <div class="d-flex align-items-center justify-content-center" style="gap: 25px">
    <div class="d-flex">
      <div class="mr-2">{{ managerCount }}</div>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 17 16" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.50037 1.16602C6.30376 1.16602 5.33371 2.13607 5.33371 3.33268C5.33371 4.5293 6.30376 5.49935 7.50037 5.49935C8.69699 5.49935 9.66704 4.5293 9.66704 3.33268C9.66704 2.13607 8.69699 1.16602 7.50037 1.16602ZM4.33371 3.33268C4.33371 1.58378 5.75147 0.166016 7.50037 0.166016C9.24928 0.166016 10.667 1.58378 10.667 3.33268C10.667 5.08158 9.24928 6.49935 7.50037 6.49935C5.75147 6.49935 4.33371 5.08158 4.33371 3.33268Z" fill="#313131"/>
        <path d="M9.6516 9.58124L11.0606 9.05288L12.4695 9.58124V10.6779C12.4695 10.9396 12.3966 11.196 12.2589 11.4186C12.1213 11.6412 11.9245 11.821 11.6904 11.938L11.0606 12.2531L10.4303 11.9384C10.1962 11.8213 9.99929 11.6413 9.86175 11.4186C9.72421 11.1959 9.65144 10.9392 9.6516 10.6774V9.58124Z" fill="#313131"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.3903 6.05976C11.1777 5.98008 10.9435 5.98008 10.7309 6.05976L7.44328 7.29261C7.2642 7.35974 7.10987 7.47993 7.00094 7.63713C6.892 7.79432 6.83364 7.98103 6.83367 8.17228V10.691C6.83365 11.476 7.05223 12.2455 7.46492 12.9133C7.8776 13.5811 8.46809 14.1207 9.17021 14.4718L10.7454 15.2589C10.8433 15.3079 10.9512 15.3333 11.0606 15.3333C11.17 15.3333 11.2779 15.3079 11.3757 15.2589L12.9509 14.4713C13.6531 14.1202 14.2435 13.5806 14.6562 12.9128C15.0689 12.2451 15.2875 11.4756 15.2875 10.6906V8.17275C15.2875 7.98149 15.2291 7.79479 15.1202 7.6376C15.0113 7.48041 14.8569 7.36021 14.6779 7.29308L11.3903 6.05976ZM11.3081 8.14269C11.1485 8.08281 10.9726 8.08281 10.8131 8.14269L9.16927 8.75888C9.03498 8.80927 8.91927 8.89945 8.83761 9.01737C8.75595 9.13528 8.71223 9.27531 8.71229 9.41874V10.6774C8.71222 11.1136 8.83363 11.5412 9.06291 11.9122C9.2922 12.2833 9.62029 12.5831 10.0104 12.7782L10.798 13.1718C10.8795 13.2125 10.9694 13.2337 11.0606 13.2337C11.1517 13.2337 11.2416 13.2125 11.3231 13.1718L12.1107 12.7782C12.5008 12.5832 12.8288 12.2834 13.0581 11.9124C13.2874 11.5415 13.4088 11.114 13.4089 10.6779V9.41874C13.4089 9.27531 13.3652 9.13528 13.2835 9.01737C13.2019 8.89945 13.0862 8.80927 12.9519 8.75888L11.3081 8.14269Z" fill="#313131"/>
        <path d="M6.51871 7.5462C5.36035 7.66112 4.30925 7.97687 3.48411 8.44101C2.44681 9.0245 1.66708 9.90883 1.66708 10.998L1.66704 11.066C1.66628 11.8405 1.66534 12.8126 2.51801 13.507C2.93766 13.8487 3.52472 14.0918 4.31788 14.2523C5.11325 14.4133 6.1499 14.498 7.50042 14.498C7.60588 14.498 7.70944 14.4975 7.81113 14.4965C7.53712 14.2254 7.31676 13.9029 7.16368 13.5457L7.14235 13.4959C6.00736 13.4821 5.1554 13.4016 4.51628 13.2722C3.80944 13.1291 3.39651 12.9328 3.14948 12.7316C2.69395 12.3606 2.66708 11.8698 2.66708 10.998C2.66708 10.4306 3.08135 9.81492 3.97438 9.31258C4.59172 8.96532 5.39142 8.70347 6.3 8.57876C6.31135 8.21865 6.38724 7.86981 6.51871 7.5462Z" fill="#313131"/>
      </svg>
    </div>
    <div class="d-flex">
      <div class="mr-2">{{ travelerCount }}</div>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 17 16" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.50045 1.16602C6.30383 1.16602 5.33378 2.13607 5.33378 3.33268C5.33378 4.5293 6.30383 5.49935 7.50045 5.49935C8.69706 5.49935 9.66711 4.5293 9.66711 3.33268C9.66711 2.13607 8.69706 1.16602 7.50045 1.16602ZM4.33378 3.33268C4.33378 1.58378 5.75155 0.166016 7.50045 0.166016C9.24935 0.166016 10.6671 1.58378 10.6671 3.33268C10.6671 5.08158 9.24935 6.49935 7.50045 6.49935C5.75155 6.49935 4.33378 5.08158 4.33378 3.33268Z" fill="#313131"/>
        <path d="M7.89233 7.50759C7.76267 7.50255 7.63199 7.5 7.50042 7.5C5.95812 7.5 4.53711 7.85064 3.48411 8.44296C2.44681 9.02646 1.66708 9.91078 1.66708 11L1.66704 11.068C1.66628 11.8424 1.66534 12.8146 2.51801 13.509C2.93766 13.8507 3.52472 14.0937 4.31788 14.2543C5.11325 14.4153 6.1499 14.5 7.50042 14.5C7.52149 14.5 7.54249 14.5 7.56341 14.4999C7.52229 14.3401 7.50042 14.1726 7.50042 14V13.5C6.18427 13.5 5.22091 13.4168 4.51629 13.2742C3.80944 13.1311 3.39651 12.9347 3.14948 12.7335C2.69395 12.3626 2.66708 11.8718 2.66708 11C2.66708 10.4326 3.08135 9.81688 3.97438 9.31454C4.85172 8.82102 6.09737 8.5 7.50042 8.5V8.26925C7.50042 7.95525 7.65505 7.67736 7.89233 7.50759Z" fill="#313131"/>
        <path d="M9.11945 14.8667C8.85755 14.8667 8.63326 14.7752 8.4466 14.5923C8.25993 14.4093 8.16675 14.1897 8.16707 13.9333V8.8C8.16707 8.54333 8.26041 8.32353 8.44707 8.1406C8.63374 7.95767 8.85787 7.86636 9.11945 7.86667H10.0718V6.7C10.0718 6.50556 10.1414 6.3402 10.2804 6.20393C10.4195 6.06767 10.588 5.99969 10.7861 6H12.2147C12.4131 6 12.5818 6.06813 12.7209 6.2044C12.8599 6.34067 12.9293 6.50587 12.929 6.7V7.86667H13.8814C14.1433 7.86667 14.3675 7.95813 14.5542 8.14107C14.7409 8.324 14.8341 8.54365 14.8337 8.8V13.9333C14.8337 14.19 14.7404 14.4098 14.5537 14.5927C14.3671 14.7757 14.1429 14.867 13.8814 14.8667C13.8814 14.9989 13.8356 15.1098 13.7442 15.1994C13.6528 15.289 13.5398 15.3336 13.4052 15.3333C13.2702 15.3333 13.1571 15.2885 13.0656 15.1989C12.9742 15.1093 12.9287 14.9986 12.929 14.8667H10.0718C10.0718 14.9989 10.0261 15.1098 9.93469 15.1994C9.84326 15.289 9.73025 15.3336 9.59564 15.3333C9.46072 15.3333 9.34755 15.2885 9.25612 15.1989C9.16469 15.1093 9.11914 14.9986 9.11945 14.8667ZM9.59564 13.4667H10.3099V9.26667H9.59564V13.4667ZM11.1433 13.4667H11.8575V9.26667H11.1433V13.4667ZM12.6909 13.4667H13.4052V9.26667H12.6909V13.4667ZM10.7861 7.86667H12.2147V6.7H10.7861V7.86667Z" fill="#313131"/>
      </svg>
    </div>
  </div>
</ng-template>

<ng-template #addCorporateModal let-c="close" let-d="dismiss">
  <app-add-or-edit-corporate
    [isNewRecord]="true"
    (emitDismiss)="d('closed')"
    (emitClose)="c('ok')">
  </app-add-or-edit-corporate>
</ng-template>

<ng-template #editCorporateModal let-c="close" let-d="dismiss">
  <app-add-or-edit-corporate
    [corporate]="selectedCorporate"
    (emitDismiss)="d('closed')"
    (emitClose)="c('ok')"
    (emitSaveCorporate)="getCorporates()">
  </app-add-or-edit-corporate>
</ng-template>

<ng-template #deleteCorporateModal let-c="close" let-d="dismiss">
  <app-delete-corporate-modal
    [corporate]="selectedCorporate"
    (emitDismiss)="d('closed')"
    (emitClose)="c('ok')"
    (emitDeleteCorporate)="getCorporates()">
  </app-delete-corporate-modal>
</ng-template>

<ngb-pagination *ngIf="filteredCorporates.length > pageSize"
                class="d-flex justify-content-end mt-4"
                [collectionSize]="filteredCorporates.length"
                [(page)]="page"
                [pageSize]="pageSize"
                [maxSize]="7"
                [boundaryLinks]="true"
                (pageChange)="onPageChange()"
></ngb-pagination>
