<i
  *ngIf="!isOpened"
  [ngbPopover]="alternativeIdsTooltip"
  [autoClose]="'outside'"
  popoverClass="alternative-ids-popover"
  class="material-icons"
  #popover="ngbPopover">
  {{buttonName}}
</i>

<div class="text-center mt-2" *ngIf="isOpened">
  <h5 class="gray-700-color mb-1">Alternative ID's</h5>
  <ul class="list-unstyled">
    <li *ngFor="let alternativeID of alternativeIDs">
      <span>{{alternativeID.providerID}}: {{alternativeID.id}} </span>
    </li>
  </ul>
</div>

<ng-template #alternativeIdsTooltip>
  <div class="alternative-ids-tooltip-wp select-text">
    <h4>Alternative ID's</h4>
    <ul>
      <li *ngFor="let alternativeID of alternativeIDs">
        <span>{{alternativeID.providerID}}: {{alternativeID.id}} </span>
      </li>
    </ul>
  </div>
</ng-template>
