import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";
import { HelpersService } from "./helpers.service";

@Injectable({
  providedIn: "root"
})
export class CorporateDiscountCodesService {

  private corporateCodes: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public corporateCodes$ = this.corporateCodes.asObservable();

  private selectedCorporate: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public selectedCorporate$ = this.selectedCorporate.asObservable();

  constructor(private helpersService: HelpersService) {
  }

  set setCorporateCodes(value: any) {
    this.corporateCodes.next(value);
  }

  set setSelectedCorporate(value: any) {
    this.selectedCorporate.next(value);
  }

  processCorporateCodes(corporates: any | any[]): void {
    const corporateCodes = {};

    // Normalize input to an array to handle single or multiple corporates
    const corporatesArray = Array.isArray(corporates) ? corporates : [corporates];

    corporatesArray.forEach(corporate => {
      this.helpersService.removeFalsyKeys(corporate.discount_codes);
      this.helpersService.removeFalsyKeys(corporate.loyalty_program_discount_codes);

      if (corporate.discount_codes && Object.keys(corporate.discount_codes).length > 0) {
        corporateCodes[corporate.id] = {
          ...(corporateCodes[corporate.id] || {}),
          discountCodes: corporate.discount_codes,
        };
      }

      if (corporate.loyalty_program_discount_codes && Object.keys(corporate.loyalty_program_discount_codes).length > 0) {
        corporateCodes[corporate.id] = {
          ...(corporateCodes[corporate.id] || {}),
          loyaltyProgramCodes: corporate.loyalty_program_discount_codes,
        };
      }
    });

    this.setCorporateCodes = corporateCodes;
  }

  processUmbrellaCorporateCodes(corporate: any, corporateId: string) {
    const airlineData = corporate?.data?.memberships?.airline || [];

    const discountCodes = airlineData
      .filter(membership => membership.type === "LOYALTY_ID")
      .reduce((acc, membership) => {
        acc[membership.alliance] = membership.memberNumber;
        return acc;
      }, {});

    if (Object.keys(discountCodes).length) {
      const corporateCodes = {
        [corporateId]: { discountCodes }
      };
      this.setCorporateCodes = corporateCodes;
    }
  }
}
