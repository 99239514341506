import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {HelpersService} from '../../shared/services/helpers.service';
import {CabinClass, CabinFare, PrepareFareTypeInfo} from '../../shared/interfaces/fare-types';
import {FlightInfo, SimpleOffer} from '../../shared/interfaces/offer';
import {OfferService} from '../../shared/services/offer.service';
import {Dictionary} from "../../shared/types/dictionary";


@Component({
  selector: 'app-leg',
  templateUrl: './leg.component.html',
  styleUrls: ['./leg.component.scss']
})
export class LegComponent implements OnInit, OnChanges {

  @Input() offer: any;
  @Input() currentOD: number;
  @Input() appropriateOffers = [];
  @Input() selectedOffer?: any;
  @Input() showRow = false;
  @Output() emitSelectOD = new EventEmitter();

  showOptions = false;
  simpleOffers: SimpleOffer[];
  filteredGroupNames = [];
  groupNames = [];
  fareTypeInfo: PrepareFareTypeInfo | null = null;
  cabinClassList: CabinClass[] = [];
  fareList: CabinFare[] = [];
  currentCabinClass: CabinClass;
  currentCabinFare: CabinFare;
  fareRulesCapacity: any;
  destinationCount = 1;
  flightInfos: FlightInfo[];
  simpleOffersByProvider = [];

  constructor(
    private offerService: OfferService,
    private helpers: HelpersService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.appropriateOffers && changes.appropriateOffers.previousValue) {
      this.updateLegData();
    }
  }

  ngOnInit(): void {
    this.updateLegData();
    this.flightInfos = this.offerService.getFlightInfo(this.offer);
  }

  updateLegData() {
    this.appropriateOffers = this.appropriateOffers
      .sort((a, b) => a.price.consumer.total - b.price.consumer.total);

    if (this.currentOD > 0 && this.selectedOffer) {
      this.offer = this.appropriateOffers.find(offer => {
        return offer.flights[this.currentOD - 1].segments[0].detail.classOfService.fare.basisCode ===
          this.selectedOffer.flights[this.currentOD - 1].segments[0].detail.classOfService.fare.basisCode;
      }) || this.appropriateOffers[0];
    } else {
      this.offer = this.appropriateOffers[0];
    }

    this.simpleOffers = this.offerService.getSimpleModelByOffers(this.appropriateOffers);
    this.simpleOffersByProvider = this.offerService.getLowestPriceOffersByProvider(this.offer, this.simpleOffers);
    const groupNamesSet = [];
    this.groupNames = [];
    this.simpleOffers.forEach(offer => {
      if (!groupNamesSet.includes(offer.preparedFareTypeInfo.fareTypesCategory.groupName)) {
        groupNamesSet.push(offer.preparedFareTypeInfo.fareTypesCategory.groupName);
        this.groupNames.push({
          groupName: offer.preparedFareTypeInfo.fareTypesCategory.groupName,
          backgroundColor: offer.preparedFareTypeInfo.fareTypesCategory.backgroundColor
        });
      }
    });
    this.destinationCount = this.offer.flights?.length;

    this.prepareCabinInfo();
    this.refreshFireTypeInfo();
  }

  toggleOptions() {
    this.showOptions = !this.showOptions;
  }

  onSelectOD(offer) {
    this.emitSelectOD.emit(offer);
  }

  prepareCabinInfo() {
    this.cabinClassList = [];
    this.simpleOffers.map(offer => {
      const cabinName = Array.isArray(offer.cabins[this.currentOD]) ? offer.cabins[this.currentOD][0] : offer.cabins[this.currentOD];
      const cabinIndex = this.cabinClassList.findIndex(cabinClass => cabinClass.cabinName === cabinName);
      const fare: CabinFare = {
        airlineNamesPerLeg: offer.fares[this.currentOD]?.airlineNamesPerLeg,
        price: offer.price,
        offerID: offer.offerID,
        prepareFareTypeInfo: offer.preparedFareTypeInfo,
        fareRulesCapacity: offer.fareRulesCapacity,
        fareBasisCode: offer.fareBasisCode,
        baggageAllowance: offer.baggageAllowance[this.currentOD],
        passengersData: offer.passengersData,
        owner: offer.owner
      };
      if (this.offer.owner === offer.owner) {
        if (cabinIndex === -1) {
          this.cabinClassList.push(
            {
              cabinName,
              fares: [fare],
            });
        } else if (this.cabinClassList[cabinIndex].fares.findIndex(addedFare => this.getFareInfoToCompare(addedFare) === this.getFareInfoToCompare(fare)) < 0) {
          this.cabinClassList[cabinIndex].fares.push(fare);
          }
        }
    });

    const currentOfferIndex = this.cabinClassList
      .findIndex( cabinClass => cabinClass.fares.find(fare => fare.offerID === this.offer.offerID));

    this.currentCabinClass = currentOfferIndex !== -1 ? this.cabinClassList[currentOfferIndex] : this.cabinClassList[0];
    this.fareList = currentOfferIndex !== -1 ? this.cabinClassList[currentOfferIndex].fares : this.cabinClassList[0].fares;
    this.fareList = this.fareList.filter(fare => fare.owner === this.offer.owner);

    if ( this.selectedOffer && this.currentOD > 0 ) {
      const currentCabinFare = this.fareList.find(fare => {
        return fare.fareBasisCode === this.selectedOffer.flights[this.currentOD - 1].segments[0].detail.classOfService.fare.basisCode;
      });

      this.currentCabinFare = currentCabinFare ?? this.fareList[0];
    } else {
      this.currentCabinFare = currentOfferIndex !== -1
        ? this.fareList.find(fare => fare.offerID === this.offer.offerID)
        : this.fareList[0];
    }
  }

  setSelectedOffer(offerId: string, updateOffers = false) {
    this.offer = this.appropriateOffers.find(offer => offer.offerID === offerId);
    this.refreshFireTypeInfo();
    if (updateOffers) {
      this.flightInfos = this.offerService.getFlightInfo(this.offer);
      this.prepareCabinInfo();
    }
  }

  refreshFireTypeInfo() {
    const commonFareType = this.helpers.extractFareType(this.offer);
    this.fareTypeInfo = this.helpers.prepareFareTypesInfo(commonFareType, Dictionary.FareTypeCategories);
    this.filteredGroupNames = this.groupNames
      .filter(group => group.groupName !== this.fareTypeInfo?.fareTypesCategory.groupName);
    this.fareRulesCapacity = this.offerService.getFareRules(this.offer, this.currentOD, true);
  }

  onSelectClass(cabinClass: CabinClass) {
    this.fareList = cabinClass.fares;
    this.offer = this.appropriateOffers.find(offer => offer.offerID === cabinClass.fares[0].offerID);
    this.flightInfos = this.offerService.getFlightInfo(this.offer);
    this.currentCabinClass = cabinClass;
    this.currentCabinFare = cabinClass.fares[0];
    this.refreshFireTypeInfo();
  }

  onSelectFare(fare: CabinFare) {
    this.offer = this.appropriateOffers.find(offer => offer.offerID === fare.offerID);
    this.flightInfos = this.offerService.getFlightInfo(this.offer);
    this.currentCabinFare = fare;
    this.refreshFireTypeInfo();
    this.fareRulesCapacity = fare.fareRulesCapacity;
  }

  getFareInfoToCompare(fare: CabinFare) {
    let fareInfoToCompare: any = {};
    if (fare.airlineNamesPerLeg) {
      fareInfoToCompare.airlineNamesPerLeg = fare.airlineNamesPerLeg;
    }
    if (fare.baggageAllowance) {
      fareInfoToCompare.baggageAllowance = fare.baggageAllowance;
    }
    if (fare.fareRulesCapacity) {
      const fareRules = [];
      fare.fareRulesCapacity.map(frc => {
        if (frc.penalties) {
          delete frc.penalties;
        }
        fareRules.push(frc);
      });
      fareInfoToCompare.fareRulesCapacity = fareRules;
    }
    return JSON.stringify(fareInfoToCompare);
  }
}
