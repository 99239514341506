<span class="d-inline-block" *ngIf="!!icon && !icon.origin_destination_reference && !showPopup && (icon === 'contact_support' || ((icon?.item | getMaterialIconsPipe) !== 'contact_support' && !showPopup))">
  <a class="icon-link text-primary"
     [ngbPopover]="singlePopContent"
     [triggers]="'hover'"
     placement="top"
     container="body"
     popoverClass="fare-info-popover">
    <i class="material-icons">
    {{icon?.item | getMaterialIconsPipe}}
  </i>
  </a>
</span>

<span class="d-inline-block" *ngIf="showPopup && (incognitoArr.length || fareRulesData.length)">
  <a class="icon-link text-primary"
     [ngbPopover]="popContent"
     [triggers]="'hover'"
     placement="top"
     container="body"
     popoverClass="fare-info-popover">
    <i class="material-icons">
    {{'contact_support'| getMaterialIconsPipe}}
  </i>
  </a>
</span>

<ng-template #popContent>
  <div class="list-item-wp">
    <div class="list-item" *ngFor="let desc of incognitoArr" [innerHTML]="desc.text | replace: '\n':'<br\/>'"></div>
  </div>

  <div *ngIf="fareRulesData.length" [ngClass]="{'mt-2':incognitoArr.length}">
    <span class="font-weight-bold">Fare Rules</span>
    <ul class="ml-0 pl-0 fare-rules-list" style="margin: 0">
      <li *ngFor="let rule of fareRulesData">
        <span class="fare-name">{{ rule.amount ? rule.amount + rule.currency : ' - ' }}</span>
        <span class="mx-2 font-weight-bold">{{ rule.type }}</span>
        <span>{{ rule.description }}</span>
      </li>
    </ul>
  </div>
</ng-template>

<ng-template #singlePopContent>
  <div class="list-item" [innerHTML]="icon.text | replace: '\n':'<br\/>'"></div>
</ng-template>


