<div class="input-dropdown-wp input-dropdown-corporate-wp">
  <app-input-dropdown
    [placeholder]="placeholder"
    [leftLoader]="leftLoader"
    [listData]="corporateSelectData"
    [target]="'Corporate'"
    [noFocus]="noInputFocus"
    [useNewDesign]="useNewDesign"
    (emitSearchString)="onEmitCorporateSearchString($event)"
    (emitSelectedItem)="onSelectProfilesCompany($event)"
  >
  </app-input-dropdown>
</div>
