import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

@Injectable()
export class OrderSearchService {

  constructor(private router: Router) {}

  buildUrl(urlValues) {
    let params = {};
    params['type'] = urlValues.searchType;
    params['travelers_adt'] = urlValues.travelers.adt;
    params['travelers_yad'] = urlValues.travelers.yad;
    params['travelers_chd'] = urlValues.travelers.chd;
    params['travelers_inf'] = urlValues.travelers.inf;
    params['cabin'] = urlValues.cabin;
    params['fare'] = urlValues.fare;
    params[`selected_table_cells`] = 'ALL';
    params['corporate_id'] = urlValues.corporate_id;
    let maxIdx = 0;

    urlValues.originDestinations.map((destination, idx) => {
      params[`origin_destinations_dep[${idx}]`] = destination.departure.airportCode.toString().toUpperCase();
      params[`origin_destinations_arr[${idx}]`] = destination.arrival.airportCode.toString().toUpperCase();
      params[`origin_destinations_date[${idx}]`] = destination.departure.date;

      const destinationsNumbers = [];
      destination.segments?.forEach(segment => {
        destinationsNumbers.push(segment.flightNumber);
      });
      params[`origin_destinations_flightnumbers[${idx}]`] = destinationsNumbers.join();

      maxIdx = idx;
    });

    params['idx'] = maxIdx.toString();

    if (urlValues.owner) {
      for (let i = 1; i <= 5; i++) {
        params[`selected_table_cells`] += `${urlValues.owner}-${i}${i !== 5 ? ',' : ''}`;
      }
    }

    params['selected_table_sort_value'] = 'price';

    if (urlValues?.searchAgain) {
      params['owner'] = urlValues.owner;
    }

    this.router.navigate(['/search'], {queryParams: params});
  }

}
