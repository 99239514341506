<app-input-dropdown
[placeholder]="travellersSearchPlaceholder"
[listData]="travellersSelectData"
[isDisabled]="isDisabled"
[isInvalid]='isInvalid'
[isValid]='isValid'
[inputValue]="travellerInputValue"
[noFocus]="noInputFocus"
(emitSearchString)="onEmitSelectedTraveller($event)"
(emitSelectedItem)="onSelectedTraveller($event)"
></app-input-dropdown>
