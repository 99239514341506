<div class="row pb-3 pt-4">
  <div *ngIf="!logs?.length" class="col alert-warning m-3 p-3 rounded">
    Order Log is empty
  </div>
  <div class="col-md-10" *ngIf="logs && logs.length">
    <div class="timeline" *ngFor="let log of logs; last as last" [ngClass]="{'line': !last || logs.length === 1}">
      <div class="entry">
        <div class="title">
          <div class="date"
               *ngIf="log.timestamp && (log.timestamp | dateFormat: true : 'HH:mm') | async as date"
               [ngStyle]="{'margin-left': date.length < 16 ? '13px' : ''}"
          >
            <app-date-with-popover
              [date]="log.timestamp">
            </app-date-with-popover>
          </div>
          <i class="material-symbols-outlined" [class]="log.status">
            {{log.status === 'ok' ? 'done' : log.status === 'error' ? 'close' : 'exclamation'}}
          </i>
        </div>
        <div class="info" [style.margin]="last ? '0px' : '0 0 2em'">
          <p *ngIf="log.title">{{log.title}}</p>
          <p *ngIf="log.requestID">
            Request id: {{ log.requestID }}
          </p>
          <p *ngIf="log.text && !log.text.toLowerCase().startsWith('new action on order')"
             [innerHTML]="log.text | replace: ';':'<br\/>'">
          </p>
          <p *ngIf="log.source">
            Source: {{ log.app ? log.app + ' (' + log.source + ')' : log.source }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-2" *ngIf="logs?.length > 1">
    <button class="btn sort-btn" (click)="toggleSort()">
      Date
      <i class="material-symbols-outlined" [@rotate]="isDefaultSort">
        arrow_upward
      </i>
    </button>
  </div>
</div>
