<app-input-dropdown
  [placeholder]="companyPlaceholder"
  [leftLoader]="leftLoader"
  [listData]="companySelectData"
  [target]="'Corporate-umbrella'"
  [noFocus]="noInputFocus"
  [useNewDesign]="useNewDesign"
  (emitSearchString)="onEmitCompanySearchString($event)"
  (emitSelectedItem)="onSelectUmbrellaCompany($event)"
>
  <img src="assets/img/umbrella/umbrella-logo.svg"
       class="hs-image-widget"
       [ngClass]="useNewDesign ? 'new' : 'old'"
       [class.is-not-auth]="!(isUmbrellaAuth$ | async)">
</app-input-dropdown>

