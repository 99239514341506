import {Component, Input, OnInit} from '@angular/core';
import {Disclocures, DisclosureDescription, DisclosureTableData} from "../../interfaces/disclosures";
import {DisclosureService} from "../../services/disclosure.service";
import {collapseAnimation, rotateAnimation} from "angular-animations";

@Component({
  selector: 'app-disclosure-table',
  templateUrl: './disclosure-table.component.html',
  styleUrls: ['./disclosure-table.component.scss'],
  animations: [
    rotateAnimation({duration: 500 }),
    collapseAnimation()
  ]
})
export class DisclosureTableComponent implements OnInit {

  @Input() disclosures: Disclocures[];
  @Input() flights: any[];
  @Input() isPdf: boolean;
  @Input() useNewDesign: boolean;

  constructor(public disclosureService: DisclosureService) { }

  originDestinationKeys: string[] = [];
  groupedDisclosuresByFlight: DisclosureTableData[] = [];
  isDisclosuresOpened = true;
  activeIndexesByFlight = {};

  ngOnInit(): void {
    this.flights.map((flight, idx) => {
      this.activeIndexesByFlight[idx] = 0;
      this.originDestinationKeys.push(flight.key);
      const route = {
        depAirportCode: flight.departure.airportCode,
        arrAirportCode: flight.arrival.airportCode,
        depAirportName: flight.departure.airportName,
        arrAirportName: flight.arrival.airportName,
        depTerminalName: flight.departure.terminalName,
        arrTerminalName: flight.arrival.terminalName,
      };
      let disclosuresByFlightKey: DisclosureDescription[] = [];

      disclosuresByFlightKey = this.disclosureService.filterDisclosuresByODR(this.disclosures, flight.key, this.flights.length > 1);

      this.groupedDisclosuresByFlight.push({
        route,
        disclosures: this.disclosureService.groupDisclosuresByCategory(disclosuresByFlightKey)
      });
    });
    if (this.flights.length > 1) {
      this.activeIndexesByFlight[this.flights.length] = 0;
      this.groupedDisclosuresByFlight.push({
        generalRoute: "GENERAL DISCLOSURES",
        disclosures: this.disclosureService.groupDisclosuresByCategory(this.getGeneralDisclosures())
      });
    }
  }

  getGeneralDisclosures() {
    const generalDisclosures: DisclosureDescription[] = [];

    for (let disclosure of this.disclosures) {
      if (disclosure.descriptions != null) {
        const tempGeneralDisclosures = disclosure.descriptions.filter(descr => {
          if (descr.text) {
            const flagSet = new Set<boolean>(this.originDestinationKeys.map(key => {
              if (!descr.originDestinationReference) {
                return false;
              } else {
                return descr.originDestinationReference?.includes(key);
              }
            }));

            return flagSet.size === 1 ? !Array.from(flagSet)[0] : false;
          }
        });
        generalDisclosures.push(...tempGeneralDisclosures);
      }
    }
    return generalDisclosures;
  }
}
