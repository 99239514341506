<div class="row pb-5" *ngIf="groupedDisclosuresByFlight[0].disclosures.size > 0 && useNewDesign">
  <div class="col-lg-12">
    <div class="d-flex align-items-center justify-content-between" [class.mb-4]="!isPdf">
      <div class="d-flex align-items-center gap-10">
        <span class="material-symbols-outlined blue-color" *ngIf="!isPdf">
          draft
        </span>
        <h3 class="mb-0 h3-label" [class.ml-5]="isPdf">Disclosures</h3>
      </div>
      <div class="expand-icon-wrapper" (click)="isDisclosuresOpened = !isDisclosuresOpened" *ngIf="!isPdf">
        <span class="material-symbols-outlined" [@rotate]="!isDisclosuresOpened">
          expand_less
        </span>
      </div>
    </div>
    <ng-container *ngFor="let disclosuresByRoute of groupedDisclosuresByFlight; let i = index; first as first">
      <div *ngIf="disclosuresByRoute.disclosures.size > 0" [@collapse]="!isDisclosuresOpened">
        <ng-container *ngIf="disclosuresByRoute.route">
          <div class="ml-5 mb-3 flight-route" [class.mt-4]="!first">
            <span [appTooltip]="{airportCode: disclosuresByRoute.route.depAirportCode, terminalName: disclosuresByRoute.route.depTerminalName}">
              {{ disclosuresByRoute.route.depAirportCode}}
            </span>
            {{isPdf ? '-' : '→'}}
            <span [appTooltip]="{airportCode: disclosuresByRoute.route.arrAirportCode, terminalName: disclosuresByRoute.route.arrTerminalName}">
              {{ disclosuresByRoute.route.arrAirportCode}}
            </span>
          </div>
        </ng-container>
        <ng-container *ngIf="disclosuresByRoute.generalRoute">
          <div class="ml-5 mb-3 mt-4 flight-route">{{ disclosuresByRoute.generalRoute}}</div>
        </ng-container>
        <div class="custom-tabs">
          <div class="tabs ml-5">
            <div class="tab"
                 *ngFor="let disclosuresByType of disclosuresByRoute.disclosures | keyvalue; let idx = index"
                 [class.active]="activeIndexesByFlight[i] === idx"
                 (click)="activeIndexesByFlight[i] = idx">
              <div class="d-flex gap-5 align-items-center">
                <ng-container *ngIf="!disclosuresByType.key?.toLowerCase().includes('carryon'); else carryOn">
                  <i class="material-symbols-outlined">
                    {{ disclosuresByType.key | getMaterialIconsPipe}}
                  </i>
                </ng-container>
                <ng-template #carryOn>
                  <img class="d-flex cursor-pointer" src="../../../../assets/img/illustrations/carryOnSilver.svg">
                </ng-template>
                <div class="fs-14">{{ disclosuresByType.key?.replace('_', ' ') | titlecase}}</div>
              </div>
            </div>
          </div>
          <div class="panel">
            <ng-container *ngFor="let disclosuresByType of disclosuresByRoute.disclosures | keyvalue; let idx = index">
              <ng-container *ngIf="idx === activeIndexesByFlight[i]">
                <div class="d-flex align-items-center gap-10" *ngFor="let discl of disclosuresByType.value">
                  <div>{{discl.text}}</div>
                  <a *ngIf="discl?.media?.link && !isPdf"
                     container="body"
                     href="{{ discl.media.link }}"
                     target="_blank"
                     class="more-info-link"
                  >
                    More info
                    <i class="material-icons fs-16"> open_in_new </i>
                  </a>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<div class="row" *ngIf="!useNewDesign">
  <div class="col">
    <h3 class="mb-2 mt-4 h3-label" *ngIf='groupedDisclosuresByFlight[0].disclosures.size > 0'>Disclosures:</h3>
    <div class="disclosures-item-wp">
      <ng-container *ngFor="let disclosuresByRoute of groupedDisclosuresByFlight; let i = index">
        <ng-container *ngIf="disclosuresByRoute.disclosures.size > 0">
          <h5 class="destination">
            <ng-container *ngIf="disclosuresByRoute.route">
              <span [appTooltip]="{airportCode: disclosuresByRoute.route.depAirportCode, terminalName: disclosuresByRoute.route.depTerminalName}">
                {{ disclosuresByRoute.route.depAirportCode}}
              </span>
                {{isPdf ? '-' : '→'}}
              <span [appTooltip]="{airportCode: disclosuresByRoute.route.arrAirportCode, terminalName: disclosuresByRoute.route.arrTerminalName}">
                {{ disclosuresByRoute.route.arrAirportCode}}
              </span>
            </ng-container>
            <ng-container *ngIf="disclosuresByRoute.generalRoute">
              <span>{{ disclosuresByRoute.generalRoute}}</span>
            </ng-container>
          </h5>
          <h3 *ngIf='isPdf && disclosuresByRoute.route'>{{ disclosuresByRoute.route.depAirportName }} - {{disclosuresByRoute.route.arrAirportName}}</h3>
          <div
            class="disclosure-table-row"
            *ngFor="let disclosuresByType of disclosuresByRoute.disclosures | keyvalue">
            <div class="disclosure-icon" [ngStyle]="{'display': isPdf ? 'none' : 'flex'}">
              <ng-container *ngIf="!disclosuresByType.key?.toLowerCase().includes('carryon'); else carryOn">
                <i class="material-symbols-outlined">
                  {{ disclosuresByType.key | getMaterialIconsPipe}}
                </i>
              </ng-container>
              <ng-template #carryOn>
                <img class="d-flex cursor-pointer" src="../../../../assets/img/illustrations/carryOnSilver.svg">
              </ng-template>
            </div>
            <div class="disclosure-type">{{ disclosuresByType.key?.replace('_', ' ') }}</div>
            <div class="disclosure-description">
              <div *ngFor="let discl of disclosuresByType.value">
                <div>{{discl.text}}</div>
                <a *ngIf="discl?.media?.link && !isPdf"
                  container="body"
                  href="{{ discl.media.link }}"
                  target="_blank"
                >
                    More info
                  <i class="material-icons" style="font-size: 17px"> open_in_new </i>
                </a>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
