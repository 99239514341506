<div class="row">
  <div class="col-12">
    <app-breadcrumbs [items]="breadcrumbs"></app-breadcrumbs>
  </div>
  <div class="col-12">
    <a class="btn btn-primary rounded float-right"
       title="Add preset"
       [routerLink]="['/presets/add']">
      <i class='fa fa-plus'></i>
    </a>

    <label class="btn btn-primary rounded float-right mr-3" title="Import">
      <i class="fa fa-cloud-upload-alt" aria-hidden="true"></i>
      <input type="file" accept="application/json" hidden (change)="onFileChanged($event)">
    </label>

    <button class="btn btn-primary rounded float-right mr-3"
            title="Export"
            (click)="exportPresets()"
            [disabled]="!hubAgenciesService.presets?.length">
      <i class="fa fa-download" aria-hidden="true"></i>
    </button>
  </div>
  <div class="col mt-5" *ngIf="hubAgenciesService.presets?.length">
    <div class="table-responsive">
      <table class="table table-default table-hover">
        <thead>
          <tr>
            <th>
              NAME
            </th>
            <th>
              AIRLINE
            </th>
            <th>
              PTCS
            </th>
            <th>
            </th>
          </tr>
        </thead>
        <tbody>
        <tr *ngFor="let preset of hubAgenciesService.presets | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize; let idx = index;"
            class="cursor-pointer"
            (click)="$event.stopPropagation(); editPreset(idx)">
          <td>{{preset.title}}</td>
          <td>{{preset.airline}}</td>
          <td>
            <div class="d-flex justify-content-center gap-5">
              <div class="d-flex gap-5" *ngFor="let defaultTravelerType of defaultTravelerTypes; last as last">
                <ng-container *ngIf="defaultTravelerType">
                  <span style="width: 38px" [innerHTML]="defaultTravelerType | getPresetTravelerType: preset.travelers"></span>
                  <span>{{!last ? ' | ' : ''}}</span>
                </ng-container>
              </div>
            </div>
          </td>
          <td>
            <div class="d-flex gap-10">
              <div class="preset-img"
                   [ngbPopover]="mandatoryInfoPopover"
                   [class.active]="preset.isMandatory"
                   popoverClass="help-popover"
                   popoverTitle="Mandatory"
                   [triggers]="'hover'"
                   placement="top"
                   (click)="$event.stopPropagation(); updatePreset(preset, true)">M</div>
              <div class="preset-img"
                   [class.active]="preset.isDefault"
                   [ngbPopover]="defaultInfoPopover"
                   popoverClass="help-popover"
                   popoverTitle="Default"
                   [triggers]="'hover'"
                   placement="top"
                   (click)="$event.stopPropagation(); updatePreset(preset, false)">D</div>
              <i class="material-symbols-outlined remove-icon"
                 title="Remove"
                 (click)="$event.stopPropagation(); deletePreset(idx)">
                delete
              </i>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <ngb-pagination *ngIf="hubAgenciesService.presets?.length > pageSize"
                    class="d-flex justify-content-end mt-4"
                    [collectionSize]="hubAgenciesService.presets.length"
                    [(page)]="page"
                    [pageSize]="pageSize"
                    [maxSize]="7"
                    [boundaryLinks]="true"
                    (pageChange)="onPageChange()"
    ></ngb-pagination>
  </div>
  <div class="col mt-5" *ngIf="!hubAgenciesService.presets?.length">
    No presets found
  </div>
</div>

<ng-template #defaultInfoPopover>
  <ul class="mb-0 pl-4">
    <li>Preselected for all searches</li>
    <li>Agents are be able to remove it</li>
  </ul>
</ng-template>

<ng-template #mandatoryInfoPopover>
  <ul class="mb-0 pl-4">
    <li>Preselected for all searches</li>
    <li>Agents are NOT be able to remove it</li>
  </ul>
</ng-template>
