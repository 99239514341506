<div class="d-flex align-items-center gap-8">
  <label class="checkbox custom-checkbox-1 mb-0">
    <input type="checkbox"
           [checked]="checked"
           (change)="onChange($event)"/>
    <div class="checkbox__checkmark"></div>
    <div class="checkbox__body fs-15">
      Notification seen
    </div>
  </label>

  <span class="material-icons help-icon"
        (mouseenter)="onMouseEnter()"
        (mouseleave)="onMouseLeave()"
        [ngbPopover]="helpPopover"
        placement="right"
        #popover="ngbPopover"
  >
      {{buttonName}}
    </span>
</div>


<ng-template #helpPopover>
  <div class="d-flex gap-3">
    <span>Mark as seen to hide warning and</span>
    <i class="material-symbols-outlined exclamation-icon problem">
      exclamation
    </i>
    <span>for this notification</span>
  </div>
</ng-template>
