<div class="modal-card card" ngbAutoFocus *ngIf="isModal">
  <div class="card-header">
    <div class="row align-items-center">
      <div class="col">
        <h3 class="card-header-title">{{isNewRecord ? 'Add corporate' : 'Edit corporate details'}}</h3>
      </div>
      <div class="col-auto">
        <i
          data-dismiss="modal"
          aria-hidden="true"
          (click)="emitDismiss.emit()"
          class="material-icons float-right ml-3 close-icon"
        >close</i>
      </div>
    </div>
  </div>
  <div class="card-body edit-corporate-details-modal">
    <ng-container [formGroup]="form">
      <div class="form-group form-group-sm" *ngIf="form.get('name') as name">
        <label><span class="text-primary"><b>*</b></span> Name</label>
        <input
          [validate]="name.dirty"
          formControlName="name"
          type="text"
          class="form-control"
          placeholder="AirGateway">
      </div>
      <div class="form-group form-group-sm" *ngIf="form.get('account_number') as accountNumber">
        <label><span class="text-primary"><b>*</b></span> Account ID</label>
        <input
          [validate]="accountNumber.dirty"
          formControlName="account_number"
          type="text"
          class="form-control"
          placeholder="ABC123">
      </div>

      <div class="form-group-sm">
        <ng-container [ngTemplateOutlet]="domainsWhiteListTemplate"></ng-container>
      </div>

      <ng-container [ngTemplateOutlet]="domainsDisplayTemplate"></ng-container>
    </ng-container>

    <ng-container [ngTemplateOutlet]="errorsTemplate"></ng-container>
  </div>
  <div class="card-footer text-right">
    <button
      type="button"
      class="btn btn-secondary mr-2"
      (click)="open(deleteModal, 'md'); emitClose.emit()">Delete
    </button>
    <button
      type="button"
      class="btn btn-primary"
      [disabled]="(invalidForm | async) || hasUnsavedDomains || !unsavedChanges"
      (click)="save()">Save
    </button>
  </div>
</div>


<div class="card mt-4" *ngIf="!isModal">
  <div class="card-body">
    <ng-container [formGroup]="form">
      <div class="form-row">
        <div class="form-group-sm col-sm-5 col-xl-3" *ngIf="form.get('name') as name">
          <label>Name</label>
          <input
            [validate]="name.dirty"
            formControlName="name"
            type="text"
            class="form-control"
            placeholder="AirGateway">
        </div>
      </div>
      <div class="form-row">
        <div class="form-group-sm col-sm-5 col-xl-3" *ngIf="form.get('account_number') as accountNumber">
          <label>Account ID</label>
          <input
            [validate]="accountNumber.dirty"
            formControlName="account_number"
            type="text"
            class="form-control"
            placeholder="ABC123">
        </div>
      </div>

      <div class="form-row">
        <div class="form-group-sm col-sm-5 col-xl-3">
          <ng-container [ngTemplateOutlet]="domainsWhiteListTemplate"></ng-container>
        </div>
      </div>

      <div class="form-row">
        <div class="col-md-6">
          <ng-container [ngTemplateOutlet]="domainsDisplayTemplate"></ng-container>
        </div>
      </div>

      <ng-container [ngTemplateOutlet]="errorsTemplate"></ng-container>
    </ng-container>
  </div>
  <div class="card-footer text-center" *ngIf="unsavedChanges && form.valid">
    <button
      type="button"
      class="btn btn-secondary mr-4"
      (click)="cancel()">Cancel
    </button>
    <button
      type="button"
      class="btn btn-primary"
      [disabled]="(invalidForm | async) || hasUnsavedDomains || !unsavedChanges"
      (click)="save()">Save
    </button>
  </div>
</div>

<ng-template #domainsWhiteListTemplate>
  <ng-container [formGroup]="form">
    <label>Email domains whitelist</label>
    <div class="input-group" formGroupName="domains">
      <ng-container *ngFor="let control of form.get('domains')['controls']; let idx = index; last as last">
        <ng-container [formGroupName]="idx" *ngIf="last">
          <input type="text" class="form-control" formControlName="domain" [validate]="control.dirty">
          <span class="input-group-btn">
                  <button class="btn btn-primary rounded ml-3"
                          title='Add email domain'
                          (click)="addDomain(control)"
                          [disabled]="!control.value.domain || control.invalid">
                    <i class='fa fa-plus'></i>
                  </button>
                </span>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
</ng-template>

<ng-template #domainsDisplayTemplate>
  <div class='emails-domain-container'>
    <ng-container *ngFor='let domain of corporate?.domains; let idx = index;'>
      <div class='domain rectangle'>
        {{domain}}
        <span class="click-close" (click)="removeDomain(idx)">
          <i class="material-icons">close</i>
        </span>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #errorsTemplate>
  <div *ngIf="(errors | json) != '{}'" class="alert-danger-provider p-3 mb-3 rounded">
    {{(errors.name || errors.account_number || errors.domains || errors.error?.summary) | capitalizeFirstLetter}}
  </div>
</ng-template>

<ng-template #deleteModal let-c="close" let-d="dismiss">
  <app-delete-corporate-modal
    [corporate]="corporate"
    [isRedirectionNeeded]="true"
    (emitDismiss)="d('closed')"
    (emitClose)="c('ok')">
  </app-delete-corporate-modal>
</ng-template>
