import { Injectable } from '@angular/core';
import { CanActivate, UrlTree, Router } from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class NotFoundRedirectGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(): boolean | UrlTree {
    // Directly return a UrlTree to '/search' with cleared query params
    return this.router.createUrlTree(['/search'], { queryParams: {} });
  }

}
