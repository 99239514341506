import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {HelpersService} from "../../services/helpers.service";
import {ActivatedRoute} from "@angular/router";
import {collapseAnimation, rotateAnimation} from "angular-animations";

@Component({
  selector: 'app-offer-price-view',
  templateUrl: './offer-price-view.component.html',
  styleUrls: ['./offer-price-view.component.scss'],
  animations: [
    rotateAnimation({duration: 500 }),
    collapseAnimation()
  ]
})
export class OfferPriceViewComponent implements OnInit, OnChanges {
  @Input() oldOffer: any;
  @Input() offer: any;
  @Input() fareRules: any[];
  @Input() travelers: any;
  @Input() seatsPerSegment: any;
  @Input() isDateChange: boolean;
  @Input() isShowFareRule: boolean;
  @Input() isShowDisclosureTable: boolean;
  @Input() useNewDesign: boolean;
  @Output() emitRemoveSeat = new EventEmitter();
  fareNames;
  oldFareNames;

  isFaresOpened = true;

  constructor(private helpers: HelpersService,
              private route: ActivatedRoute) { }

  ngOnInit() {
    this.prepareOldFareNames();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.offer) {
      this.fareNames = this.helpers.prepareFareInfo(this.offer, true, true);
    }
  }

  removeSeat(segmentSeat, seat) {
    this.emitRemoveSeat.emit([segmentSeat, seat]);
  }

  onSelectedOffer(selectedOfferData: { offerID: string, isOpenNewWindow: boolean }) {
    if (!selectedOfferData.isOpenNewWindow) {
      const selectedOffer = this.offer.otherOffers.find(offer => offer.offerID === selectedOfferData.offerID);
      const _offerURL = `/offer/${selectedOffer.offerID}`;

      const queryParams = this.route.snapshot.queryParams;
      const queryParamsString = new URLSearchParams(queryParams).toString();

      const fullUrl = queryParamsString ? `${_offerURL}?${queryParamsString}` : _offerURL;

      window.open(fullUrl, "_self");
    }
  }

  prepareOldFareNames() {
    if (this.oldOffer) {
      this.oldFareNames = this.helpers.prepareFareInfo(this.oldOffer, true, true);
    }
  }
}
