<div class="row align-items-center">
  <div class="col-6">
    <div class="filter-image">
      <app-airline-img
        [airlineID]="offer.owner || offer.bookingReference?.airlineID"
        [title]="offer.owner | getImageDescription: offer.flights"
      ></app-airline-img>
    </div>
    <div style="font-size: 1.1rem;">
      <ng-container *ngFor="let flight of offer.flights; last as last">
        <span [appTooltip]="{airportCode: flight.departure.airportCode, terminalName: flight.departure.terminalName}">
          {{flight.departure.airportCode}}
        </span>
        →
        <span [appTooltip]="{airportCode: flight.arrival.airportCode, terminalName: flight.arrival.terminalName}">
          {{flight.arrival.airportCode}}{{!last ? ',' : ''}}
        </span>
      </ng-container>
    </div>
    <div class="smaller-text">
      {{ flightType }} <span *ngIf="offerClassCodes"> | Class:<b>{{ offerClassCodes }} </b> </span>
    </div>
  </div>

  <div class="col-6">
    <div class="float-right">
      <div class="old-price" *ngIf="oldOffer">{{oldOffer.price.consumer.total | number :'1.2-2'}} {{oldOffer.price.consumer.currency}}</div>
      <app-show-pax-breakdown-info
        [consumerPrice]="offer.price.consumer"
        [passengers]="offer.passengers">
        <div class="bigger-price"
             [class.new-price-orange]="!!oldOffer">
          <span class="font-weight-bold">{{offer.price.consumer?.total | number :'1.2-2'}}</span> <span> {{offer.price.consumer.currency}} </span>
        </div>
      </app-show-pax-breakdown-info>
      <div class="smaller-price text-right">
        Taxes: {{offer.price.consumer?.tax?.total | number :'1.2-2'}} {{offer.price.consumer.currency}}</div>
    </div>
  </div>
</div>
