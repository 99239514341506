<div class="mt-4">
  <div class="row align-items-center mb-4">
    <div class="col" *ngIf="!discounts.length">
      No discounts yet
    </div>
    <div class="col">
      <button class="btn btn-primary rounded float-right"
              *ngIf="!showAddedEditedLoader"
              title="Add new discount"
              (click)="showManagerForm(); open(addOrEditDiscountModal, 'md')">
        <i class='fa fa-plus'></i>
      </button>
    </div>
  </div>

  <div class="table-responsive" *ngIf="discounts.length">
    <table class="table table-default table-hover mb-0">
      <thead>
      <tr>
        <th scope="col">Provider</th>
        <th scope="col">Corporate Discount Code</th>
        <th scope="col">Corporate Loyalty Program</th>
        <th scope="col"></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let discount of discounts | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize; let idx = index"
          [class.selected-to-edit]="idx === editIdx">
        <td>{{discount.provider}}</td>
        <td>{{discount.discountCode}}</td>
        <td>{{discount.loyaltyProgramNumber}}</td>
        <td>
          <div class="d-flex gap-10">
            <i class="material-symbols-outlined"
               title="Edit"
               (click)="startEditDiscount(idx, discount);
               open(addOrEditDiscountModal, 'md')">
              edit
            </i>

            <i class="material-symbols-outlined remove-icon"
               title="Remove"
               (click)="openRemoveModal(removeModal, discount, idx, '400px')">
              delete
            </i>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <ngb-pagination *ngIf="discounts.length > pageSize"
                  class="d-flex justify-content-end mt-4"
                  [collectionSize]="discounts.length"
                  [(page)]="page"
                  [pageSize]="pageSize"
                  [maxSize]="7"
                  [boundaryLinks]="true"
                  (pageChange)="onPageChange()"
  ></ngb-pagination>
</div>

<ng-template #addOrEditDiscountModal let-c="close" let-d="dismiss">
  <div class="modal-card card" ngbAutoFocus>
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="card-header-title">{{editIdx > -1 ? 'Edit' : 'Add'}} discount</h3>
        </div>
      </div>
    </div>
    <div class="card-body">
      <ng-container [formGroup]="discountForm">
        <div class="form-group form-group-sm">
          <label><span class="text-primary"><b>*</b></span> Provider</label>
          <app-select-airline
            class="select-airline"
            [placeholder]="'Provider'"
            [isProviders]="true"
            [formControlName]="formControlNames.provider"
            [validate]="validateManagerTriggered"
          ></app-select-airline>
        </div>
        <div class="form-group form-group-sm"
             *ngIf="discountForm.get(formControlNames.provider).value">
          <label>Corporate Discount Code</label>
          <input
            type="text"
            class="form-control"
            [validate]="validateManagerTriggered"
            [formControlName]="formControlNames.discountCode"
            placeholder="Corporate Discount Code"
          >
        </div>

        <div class="form-group form-group-sm"
             *ngIf="discountForm.get(formControlNames.provider).value && LOYALTY_PROGRAM_ACCOUNT_SUPPORTED_PROVIDERS.includes(discountForm.get(formControlNames.provider).value)">
          <label>Corporate Loyalty Program</label>
          <input
            type="text"
            class="form-control"
            [validate]="validateManagerTriggered"
            [formControlName]="formControlNames.loyaltyProgramNumber"
            placeholder="Corporate Loyalty Program"
          >
        </div>
      </ng-container>
    </div>
    <div class="card-footer">
      <div class="btn-wrapper">
        <button class="btn btn-primary float-right ml-3"
                (click)="editIdx > -1 ? finishEditDiscount(c) : addDiscount(c)"
                [disabled]="discountForm.invalid">
          {{editIdx > -1 ? 'Save' : 'Add'}}
        </button>
        <button class="btn btn-secondary float-right"
                (click)="cancelDiscountForm(); c('ok')"
                [disabled]="showRemovedLoader">
          Cancel
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #removeModal let-c="close" let-d="dismiss">
  <div class="modal-card card" ngbAutoFocus>
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="card-header-title">Remove discount</h3>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="text-justify">
        Are you sure you want to remove
        {{removeDiscountInfo.discount.discountCode && removeDiscountInfo.discount.loyaltyProgramNumber ?
        'Corporate Discount Code ' + removeDiscountInfo.discount.discountCode + ' and ' + removeDiscountInfo.discount.loyaltyProgramNumber + ' Corporate Loyalty Program' :
        removeDiscountInfo.discount.discountCode ? 'Corporate Discount Code ' + removeDiscountInfo.discount.discountCode : 'Corporate Loyalty Program ' + removeDiscountInfo.discount.loyaltyProgramNumber}}
        for {{ removeDiscountInfo.discount.provider }} provider ?
      </div>
    </div>
    <div class="card-footer">
      <app-loader *ngIf="showAddedEditedLoader"></app-loader>
      <div class="btn-wrapper">
        <button (click)="removeDiscount(c);" class="btn btn-primary float-right ml-3">Remove</button>
        <button (click)="d('closed')" class="btn btn-secondary float-right"
                [disabled]="showRemovedLoader">Cancel</button>
      </div>
    </div>
  </div>
</ng-template>
