<div class="container">
  <div class="row">
    <div class="col-md-12 not-found">
      <div class="not-found__icon">
        <i class="material-icons">sentiment_dissatisfied</i>
      </div>
      <div class="not-found__content">
        <span class="status">404</span>
        <p class="title">Page not found</p>
      </div>
    </div>
  </div>
</div>
