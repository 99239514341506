<div class="input-drop-down-wp">
  <div *ngIf="useNewDesign">
    <div class="input-group drop-down-input">
      <span class="input-group-prepend">
        <div class="input-group-text border-right-0 border-radius-sm"><i class="fa fa-search search-icon"></i></div>
      </span>
      <input #searchInput
             class="form-control py-2 pl-0 border-left-0 border"
             [class.with-umbrella-icon]="target === 'Corporate-umbrella'"
             id="searchInput"
             type="text"
             autocomplete="off"
             [class.is-valid]="isValid"
             [class.is-invalid]="isInvalid"
             [autocomplete]="autocomplete"
             [disabled]="isDisabled"
             [placeholder]="placeholder"
             (focus)="onFocusInput($event)"
             (blur)="onBlurInput($event)"
             (click)="toggleOptionList($event)"
             (keydown)="keyDownHandler($event)"
             [(ngModel)]="inputValue"
      />
      <ng-content></ng-content>
    </div>

    <div class="d-flex justify-content-center align-items-center mt-3" *ngIf="showInputLoader">
      <app-loader></app-loader>
    </div>
  </div>

  <div class="input-wp" *ngIf="!useNewDesign">
    <div class="input-loader-wp place-left" *ngIf="showInputLoader && leftLoader">
      <app-loader></app-loader>
    </div>
    <input
    #searchInput
    class="drop-down-input form-control"
    [class.with-umbrella-icon]="target === 'Corporate-umbrella'"
    type="text"
    [class.is-valid]="isValid"
    [class.is-invalid]="isInvalid"
    [autocomplete]="autocomplete"
    [disabled]="isDisabled"
    [placeholder]="placeholder"
    (focus)="onFocusInput($event)"
    (blur)="onBlurInput($event)"
    (click)="toggleOptionList($event)"
    (keydown)="keyDownHandler($event)"
    [(ngModel)]="inputValue">

    <i class="material-icons reset-company-btn" *ngIf="inputValue && target === 'Corporate'" (click)="onSelectItem(null); searchInput.focus()">
      close
    </i>

    <div class="input-loader-wp" *ngIf="showInputLoader && !leftLoader">
      <app-loader></app-loader>
    </div>
  </div>
  <ul class="drop-down-list" [ngClass]="useNewDesign ? 'new' : 'old'" *ngIf="showList">
    <li *ngIf="emptyResult" class="no-result">No results</li>
    <li *ngIf="!emptyResult" [ngClass]="{'focus-background': selectedIndex == -1}" (click)="onSelectItem(null)">None</li>
    <cdk-virtual-scroll-viewport [style.height.px]="listHeight" [itemSize]="itemListHeight" #virtualScroll>
      <ng-container *cdkVirtualFor="let item of listData; index as idx" (isSelected)="true">
        <li [ngClass]="{'focus-background': selectedIndex == idx}" class="drop-down-list-item" (click)="onSelectItem(item)">{{item.text}}</li>
      </ng-container>
    </cdk-virtual-scroll-viewport>
  </ul>
</div>
