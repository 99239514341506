<div class="row pb-5" *ngIf="useNewDesign">
  <div class="col-lg-12">
    <div class="d-flex align-items-center justify-content-between" [class.mb-4]="!isPDF">
      <div class="d-flex align-items-center gap-10">
        <span class="material-symbols-outlined blue-color" *ngIf="!isPDF">
          pending
        </span>
        <h3 class="mb-0 h3-label" [class.ml-5]="isPDF">Fare Rules</h3>
      </div>
      <div class="expand-icon-wrapper" (click)="isFareRulesOpened = !isFareRulesOpened" *ngIf="!isPDF">
        <span class="material-symbols-outlined" [@rotate]="!isFareRulesOpened">
          expand_less
        </span>
      </div>
    </div>
    <div class="custom-tabs" [@collapse]="!isFareRulesOpened">
      <div class="tabs ml-5">
        <div class="tab"
             [class.active]="activeODIndex === index"
             (click)="activeODIndex = index; activeTabIndex = 0;"
             *ngFor="let ruleItem of fareRules; index as index">
          <span [appTooltip]="{airportCode: ruleItem.route.depCode}">
            {{ruleItem.route.depCode}}
          </span>
          {{isPDF ? '-' : '→'}}
          <span [appTooltip]="{airportCode: ruleItem.route.arrCode}">
            {{ruleItem.route.arrCode}}
          </span>
        </div>
      </div>
      <div class="panel">
        <ng-container *ngFor="let ruleItem of fareRules; index as ruleIdx">
          <ng-container *ngIf="activeODIndex === ruleIdx">
            <ul class="nav nav-tabs card-header-tabs justify-content-center" *ngIf="activeODIndex === ruleIdx">
              <ng-container *ngFor="let type of ruleItem.rulesByType | keyvalue; index as typeIdx">
                <li class="nav-item">
                  <a class="nav-link"
                     (click)="activeTabIndex = typeIdx"
                     [class.active]="typeIdx === activeTabIndex">
                    {{type.key}}
                  </a>
                </li>
              </ng-container>
            </ul>
            <ng-container *ngFor="let type of ruleItem.rulesByType | keyvalue; index as typeIdx">
              <ng-container *ngIf="typeIdx === activeTabIndex">
                <div class="mt-5 d-flex flex-column gap-12">
                  <div class="d-flex align-items-center gap-20">
                    <div class="penalty-column penalty-column-header">Penalty</div>
                    <div class="description-column description-column-header">Description</div>
                  </div>
                  <div class="d-flex align-items-baseline gap-20" *ngFor="let rule of type.value">
                    <div class="penalty-column">
                      <div class="d-flex flex-column align-items-center" *ngIf="rule.amounts?.length">
                        <ng-container *ngFor="let amount of rule.amounts; last as last">
                          <span>
                            {{amount.amount}} {{amount.currency}}
                          </span>
                          <hr *ngIf="!last">
                        </ng-container>
                      </div>
                      <div class="text-center" *ngIf="!rule.amounts?.length">
                        -
                      </div>
                    </div>
                    <div class="description-column">
                      <span [innerHTML]="rule.description
                            | replace:';':'<br><br>'
                            | replace:'\n':'<br><br>'
                            | replace: '\\\\/': ' '">
                      </span>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<div class="row pb-5" *ngIf="fareRules?.length && !useNewDesign">
  <div class="col-lg-12">
    <div class="fare-rules-wp" [ngStyle]="{'overflow': 'hidden', 'max-height': !showAllFareRules && !isPDF ? '400px' : 'fit-content' }" [class.faded]="!showAllFareRules && !isPDF">
      <h3 class="mb-2">Fare rules:</h3>
      <div class="fare-rules-by-segment" [ngClass]="{'mt-4': index !== 0}" *ngFor="let ruleItem of fareRules; index as index">
        <h5>
          <span [appTooltip]="{airportCode: ruleItem.route.depCode}">
            {{ruleItem.route.depCode}}
          </span>
          {{isPDF ? '-' : '→'}}
          <span [appTooltip]="{airportCode: ruleItem.route.arrCode}">
            {{ruleItem.route.arrCode}}
          </span>
          <span class="fareBasisCode-wp" *ngIf="ruleItem?.route?.fareBasisCode">({{ruleItem.route.fareBasisCode}})</span>
        </h5>
        <ng-container *ngIf="groupByPassengerType">
          <div *ngFor="let passengerType of ruleItem.types">
            <span class="my-2">{{passengerType}}</span>
            <div class="rule-desc-wp">
              <div class="rule-desc-item" *ngFor="let ruleInfo of ruleItem.rules[passengerType]">
                <ng-container [ngTemplateOutlet]="rule"
                              [ngTemplateOutletContext]="{ruleInfo: ruleInfo}"
                ></ng-container>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="!groupByPassengerType">
          <div class="rule-desc-wp">
            <div class="rule-desc-item" *ngFor="let ruleInfo of ruleItem.rules">
              <ng-container [ngTemplateOutlet]="rule"
                            [ngTemplateOutletContext]="{ruleInfo: ruleInfo}"
              ></ng-container>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="col-lg-12" *ngIf="!isPDF">
    <div class="btn btn-secondary float-right mt-3 p-2"
         (click)="toggleFareRules()">
      Read {{!showAllFareRules ? 'more' : 'less'}}
    </div>
  </div>
</div>

<ng-template #rule let-ruleInfo="ruleInfo">
  <div class="amount" *ngIf="ruleInfo.amounts?.length">
    <div *ngFor="let amount of ruleInfo.amounts; last as last">
      <span>
        {{amount.amount}} {{amount.currency}}
      </span>
      <hr *ngIf="!last">
    </div>
  </div>
  <div class="amount" *ngIf="!ruleInfo.amounts?.length">
    <span> - </span>
  </div>
  <div class="type">{{ruleInfo.type || '-'}}</div>
  <p [innerHTML]="ruleInfo.description
               | replace:';':'<br><br>'
               | replace:'\n':'<br><br>'
               | replace: '\\\\/': ' '">
  </p>
</ng-template>

