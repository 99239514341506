<div class="table-responsive mt-3">
  <table class="table table-default services-table mb-0">
    <thead>
    <tr>
      <th class="name">Name</th>
      <th class="descr">Description</th>
      <th>Price</th>
      <th *ngIf="atLeastOneHaveComment(services)">Comment</th>
      <th width="100px"></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let serviceObj of services">
      <td class="name">{{serviceObj.name}}</td>
      <td class="text-left descr">
        <div *ngFor="let descr of serviceObj.descriptions">
          {{ descr }}
        </div>
      </td>
      <td>
        <ng-container
          *ngIf="serviceObj.price.consumer.total > 0">{{ serviceObj.price.consumer.total | number :'1.2-2' }}
          {{ serviceObj.price.consumer.currency }}
        </ng-container>
        <ng-container *ngIf="serviceObj.price.consumer.total == 0">Free</ng-container>
      </td>
      <td *ngIf="atLeastOneHaveComment(services)" class="comments">
        <ng-container *ngIf="showCommentInput(serviceObj, passengerRef)">
          <div class="d-flex flex-column gap-8">
            <ng-container *ngFor="let instruction of serviceObj.bookingInstructions.instructions; let idx = index">
              <input class="form-control"
                     [value]="getServiceValue(serviceObj, passengerRef, idx)"
                     [type]="instruction.type"
                     [placeholder]="instruction.text"
                     [pattern]="instruction.pattern"
                     (input)="handleCommentInput($event, serviceObj, passengerRef, instruction, idx)">
              <span
                class="error"
                *ngIf="validationErrors[serviceObj.serviceID + passengerRef + serviceObj.segmentReferences + idx]">
                  {{ validationErrors[serviceObj.serviceID + passengerRef + serviceObj.segmentReferences + idx] }}
                </span>
            </ng-container>
          </div>
        </ng-container>
      </td>
      <td>
        <ng-container *ngIf="serviceObj.ref != 'Seat'">
          <ng-container
            *ngIf="selectedServicesMapCopy[serviceObj.serviceID + passengerRef + serviceObj.segmentReferences]">
              <span
                (click)="toggleServiceSelection(serviceObj, passengerRef)"
                class="btn btn-sm btn-tonal-primary mw-74">Selected
              </span>
          </ng-container>

          <ng-container
            *ngIf="!selectedServicesMapCopy[serviceObj.serviceID + passengerRef + serviceObj.segmentReferences]">
              <span
                (click)="toggleServiceSelection(serviceObj, passengerRef)"
                class="btn btn-sm btn-outline-primary mw-74">Select
              </span>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="serviceObj.ref == 'Seat'">
          temporary disabled
        </ng-container>
      </td>
    </tr>
    </tbody>
  </table>
</div>
