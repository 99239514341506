<div class="container mt-3">
  <h1>AirGateway GmbH - Privacy Policy</h1>
  <div>
    <p>Last Updated on March 1, 2019</p>
    <h4>Introduction</h4>
    <p>This privacy policy will help you understand what information we collect and use at AirGateway
      GmbH, and the choices you have associated with that information. When we refer to
      “AirGateway,” “we,” “our,” or “us” in this policy, we are referring to AirGateway GmbH, and its
      subsidiaries and affiliates, which provides the Services to you. The “Services” refers to the
      applications, services, and websites (marketing and product) provided by AirGateway.
      AirGateway may, from time to time, introduce new products and services. To the extent that
      these new products and services affect this policy, we will notify you as described in Section 8
      below. This privacy policy covers the digital properties listed here. The use of information
      collected through our service shall be limited to the purpose of providing the services for our
      customers and as specified herein.</p>
    <p>If you have any questions about this privacy policy or our practices, please contact us. If you
      have an unresolved privacy or data use concern that we have not addressed satisfactorily,
      please contact us at info@airgateway.com.</p>
    <h4>Contents:</h4>
    <ol>
      <li>Information We Collect and Receive</li>
      <li>How We Use the Information We Collect and Receive</li>
      <li>Analytics, Cookies and Other Web Site Technologies</li>
      <li>Information Sharing</li>
      <li>Communicating</li>
      <li>Accessing Your Data</li>
      <li>Security</li>
      <li>Changes to this Statement/Contact Us</li>
    </ol>
    <h4>1. Information We Collect and Receive</h4>
    <p>We collect several different types of information to provide Services to you, including:</p>
    <p>Customer Account and Registration Data: This includes but may not be limited to information
      you provide to create your account with us and may include, first and last name, billing
      information, a password, and a valid email address.</p>
    <p>Service Data (including Session and Usage data):</p>
    <p>When you use our Services, we receive information generated through the use of the Service,
      either entered by you or others who use the Services with you or from the Service infrastructure
      itself. We may also collect usage and log data about how the services are accessed and used,
      including information about the device you are using the Services on, IP addresses, location
      information, language settings, what operating system you are using, unique device identifiers
      and other diagnostic data to help us support the Services.</p>
    <p>Third Party Data: We may receive information about you from other sources, including publicly
      available databases or third parties from whom we have purchased data, and combine this data
      with information we already have about you. This helps us to update, expand and analyze our
      records, identify new prospects for marketing, and provide products and services that may be of
      interest to you.</p>
    <p>Location Information: We collect your location-based information for the purpose of providing
      and supporting the service and security monitoring.</p>
    <p>Device Information: When you use our Services, we automatically collect information on the
      type of device you use, operating system version, and the device identifier (or "UDID").</p>
    <h4>2. How We Use the Information We Collect and Receive</h4>
    <p>AirGateway may access (which may include, with your consent, limited viewing or listening) and
      use the data we collect as necessary (a) to provide and maintain the Services; (b) to address
      and respond to service, security, and customer support issues; (c) to detect, prevent, or
      otherwise address fraud, security, unlawful, or technical issues; (d) as required by law; (e) to
      fulfill our contracts; (f) to improve and enhance the Services; (g) to provide analysis or valuable
      information back to our Customers and users.</p>
    <p>If you wish to cancel your account or for us to stop providing you services, or if we hold personal
      information about you and you want it to be removed from our database or inactivated, please
      contact us at info@airgateway.com.</p>
    <h4>3. Analytics, Cookies and Other Web Site Technologies</h4>
    <p>AirGateway is continuously improving our websites and products through the use of various
      third-party web analytics tools, which help us understand how visitors use our websites, desktop
      tools, and mobile applications, what they like and dislike, and where they may have problems.
      While we maintain ownership of this data, we do not share this type of data about individual
      users with third parties.</p>
    <p>Geolocation and Other Data:</p>
    <p>We may utilize precise Geolocation data in connection with our service. We also use information
      such as IP addresses to determine the general geographic locations areas of our visitors. The
      web beacons used in conjunction with these web analytics tools may gather data such as what
      browser or operating system a person uses, as well as, domain names, MIME types, and what
      content, products and services are reviewed or downloaded when visiting or registering for
      services at one of our websites or using one of our mobile applications.
      Google Analytics and Adobe Marketing Cloud:</p>
    <p>We use Google Analytics as described in “How Google uses data when you use our partners'
      sites or apps.” You can prevent your data from being used by Google Analytics on websites by
      installing the Google Analytics opt-out browser add-on. We also employ IP address masking, a
      technique used to truncate IP addresses collected by Google Analytics and store them in an
      abbreviated form to prevent them from being traced back to individual users. Portions of our
      website may also use Google Analytics for Display Advertisers including DoubleClick or
      Dynamic Remarketing which provide interest-based ads based on your visit to this or other
      websites. You can use Ads Settings to manage the Google ads you see and opt-out of
      interest-based ads. We might also use Adobe Marketing Cloud.</p>
    <p>Tracking Technologies:</p>
    <p>AirGateway and our partners use cookies and similar tracking technologies to track user traffic
      patterns and hold certain registration information. Tracking technologies also used are beacons,
      tags and scripts to collect and track information and to improve and analyze our service. If you
      wish to not have the information these technologies collect used for the purpose of serving you
      targeted ads, you may opt-out. The Help menu on the menu bar of most browsers will tell you
      how to prevent your browser from accepting new cookies, how to have the browser notify you
      when you receive a new cookie and how to disable cookies altogether. You can still review the
      website if you choose to set your browser to refuse all cookies; however, you must enable
      cookies to establish an account and to install the Services.</p>
    <p>We use these cookies or local storage to operate our websites.
      Some cookies are essential for the operation of AirGateway websites. If a user chooses to
      disable these cookies, the user might not be able to access all of the content and features.</p>
    <p>Preference Cookies or local storage <br>
      We may use these cookies to remember your preferences.
      When you register at the AirGateway website, we use cookies to store unique, randomly
      assigned user IDs that we generate for administrative purposes. These cookies might enable
      you to enter the AirGateway sites without having to log on each time.</p>
    <p>Security Cookies or local storage <br>
      These cookies are used for general security purposes and user authentication. We use security
      cookies to authenticate users, prevent fraudulent use of login credentials, and protect user data
      from access by unauthorized parties.</p>
    <p>Analytics <br>
      We use analytics software to allow us to better understand the functionality of our software. This
      software may record information such as how often you use the application, the events that
      occur within the application, aggregated usage and performance data.</p>
    <h4>4. Information Sharing</h4>
    <p>Ensuring your privacy is important to us. We do not share your personal information with third
      parties except as described in this privacy policy. We may share your personal information with
      (a) third party service providers; (b) business partners; (c) affiliated companies within our
      corporate structure and (d) as needed for legal purposes. Third party service providers have
      access to personal information only as needed to perform their functions and they must process
      the personal information in accordance with this Privacy Policy.</p>
    <p>We may disclose data for legal reasons include as part of a merger, sale of company assets,
      financing or acquisition of all or a portion of our business by another company where customer
      information will be one of the transferred assets. <br>
      As required by law to comply with a valid subpoena or other legal process; when we believe in
      good faith that disclosure is necessary to protect our rights, or to protect your safety (or the
      safety of others), to investigate fraud, or to respond to a government request.
      We may also disclose your personal information to any third party with your prior consent.</p>
    <h4>5. Communications</h4>
    <p>AirGateway may need to communicate with you for a variety of different reasons, including:</p>
    <p>Responding to your questions and requests. If you contact us with a problem or question, we
      will use your information to respond. <br>
      Sending you Service and administrative emails and messages. We may contact you to inform
      you about changes in our Services, our Service offerings, and important Service-related notices,
      such as billing, security and fraud notices. These emails and messages are considered a
      necessary part of the Services and you may not opt-out of them. <br>
      Sending emails about new products or other news about AirGateway that we think you’d like to
      hear about either from us or from our business partners. You can always opt out of these types
      of messages at any time by clicking the unsubscribe link at the bottom of each communication
      when available or by informing us at info@airgateway.com.
      Conducting surveys. We may use the information gathered in the surveys to enhance and
      personalize our products, services, and websites.</p>
    <h4>6. Accessing Your Data</h4>
    <p>Our customers can always review, update or change personal information from within their
      account. AirGateway will also, when you request, provide you with information about whether
      we hold, or process on behalf of a third party, any of your personal information. Please contact
      us at info@airgateway.com if you need assistance in reviewing your information. AirGateway
      will respond to your access request to review the information we have on file for you within a
      reasonable time.</p>
    <p>We may also collect information on behalf of our customers, to provide the services, and we
      may not have a direct relationship with the individuals whose personal data is processed. If you
      are a customer or end-user of one of our customers, please contact them (as the data
      controller) directly if: (i) you would no longer like to be contacted by them; or (ii) to access,
      correct, amend, or delete inaccurate data. If requested to remove data by our customer, we will
      respond within a reasonable timeframe.</p>
    <p>We may transfer personal information to companies that help us provide our service, and when
      we do, these transfers to subsequent third parties are covered by appropriate transfer
      agreements. We will retain personal data we process on behalf of our customer as needed to
      provide services to our customer. Also, we will retain this personal information as necessary to
      comply with our legal obligations, resolve disputes, and enforce our agreements.</p>
    <p>AirGateway is headquartered in the EU and maintains a global infrastructure. Information that
      we collect and maintain may be transferred to, or controlled and processed in, the EU or other
      countries around the world. When you provide us with information or use our websites and
      services, you consent to this transfer. We will protect the privacy and security of personal
      information we collect in accordance with this privacy policy, regardless of where it is processed
      or stored.</p>
    <h4>7. Security</h4>
    <p>AirGateway follows generally accepted standards to protect the personal information submitted
      to us, both during transmission and once it is received, however, no security measure is perfect.
      We recommend safeguarding your password, as it is one of the easiest ways you can manage
      the security of your own account – remember that if you lose control over your password, you
      may lose control over your personal information.</p>
    <h4>8. Changes to this Statement/Contact Us</h4>
    <p>We may update this Privacy Policy to reflect changes to our information practices. If we make
      any material changes we will provide notice on this website, and we may notify you by email
      (sent to the e-mail address specified in your account), prior to the change becoming effective.
      We encourage you to periodically review this page for the latest information on our privacy
      practices. If you continue to use the Services after those changes are in effect, you agree to the
      revised policy.</p>
    <p>If you have any other questions about this policy please contact AirGateway at
      <a href="mailto:info@airgateway.com">info@airgateway.com</a>.</p>
  </div>
</div>
