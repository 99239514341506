<div class="modal-card card">
  <div class="card-header">
    <div class="row align-items-center">
      <div class="col">
        <h3 class="card-header-title">Delete Corporate</h3>
      </div>
      <div class="col-auto">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          (click)="emitDismiss.emit()"
          aria-hidden="true">×
        </button>
      </div>
    </div>
  </div>
  <div class="card-body">
    <div class="alert alert-danger mb-0">This will delete selected corporate. Are you sure you want to proceed?</div>
  </div>
  <div class="card-footer text-right">
    <button
      type="button"
      class="btn btn-secondary mr-2"
      (click)="emitDismiss.emit()">Cancel
    </button>
    <button
      type="button"
      class="btn btn-primary"
      (click)="delete();">Delete
    </button>
  </div>
</div>
