<div class="wrapper">
  <div class="time-container">
    <label *ngIf="timeForm.value">:00</label>
    <input [formControl]="timeForm" (click)="openDropdown()">
    <button *ngIf="timeForm.value" (click)="clearTimePickerField()" class="small-button"><i class="material-icons">clear</i></button>
    <button (click)="openDropdown()" class="schedule-btn"><i class="material-icons">schedule</i></button>
  </div>
  <ng-container *ngIf="isShowDropdown">
    <div class="dropdown-container">
      <div #dropdownScroll class="dropdown-timepicker">
        <div
          [ngClass]="{'selected-hours': timeForm.value === hour.padStart(2, '0') }"
          (click)="setHours($event, index)"
          *ngFor="let hour of hours; index as index">
          {{ hour }}:00
        </div>
      </div>
    </div>
  </ng-container>
</div>
