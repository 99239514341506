<div class="row pb-5" *ngIf="otherServiceInformation?.length">
  <div class="col-lg-12">
    <div class="d-flex align-items-center justify-content-between mb-4">
      <div class="d-flex align-items-center gap-10">
        <span class="material-symbols-outlined blue-color" *ngIf="!isPDFExtract">
          article
        </span>
        <h3 class="mb-0 h3-label">OSI</h3>
      </div>
      <div class="expand-icon-wrapper" (click)="isOsiDetailsOpened = !isOsiDetailsOpened" *ngIf="!isPDFExtract">
        <span class="material-symbols-outlined" [@rotate]="!isOsiDetailsOpened">
          expand_less
        </span>
      </div>
    </div>
  </div>
  <div class="col-lg-12" [@collapse]="!isOsiDetailsOpened">
    <div class="table-responsive">
      <table class="mb-0 table table-default">
        <thead>
        <tr>
          <th>Name</th>
          <th>OSI Code</th>
          <th>Action Code</th>
          <th>Text</th>
          <th>Apply To</th>
        </tr>
        </thead>
        <tbody>
        <tr
          *ngFor="let osi of otherServiceInformation; let idx = index" [class.expanded]="passengersExpanded[idx]">
          <td>OSI {{idx + 1}}</td>
          <td>{{osi.osiCode || '-'}}</td>
          <td>{{osi.actionCode || '-'}}</td>
          <td>{{osi.text}}</td>
          <td>
            <div *ngIf="!osi.passengerRef; else passengersTemaplte">
              All
            </div>
            <ng-template #passengersTemaplte>
              <div class="d-flex align-items-center justify-content-end gap-8" *ngIf="osi.passengerRef">
                <div>
                  <ng-container *ngFor="let passRef of osi.passengerRef.split(' '); first as first">
                    <span *ngIf="!passengersExpanded[idx] && first">
                      {{passengers | getPassengerNameByRefPipe: passRef}}{{osi.passengerRef.split(' ').length > 1 && first ? ', ...' : ''}}
                    </span>
                    <div class="text-justify" *ngIf="passengersExpanded[idx]">
                      {{passengers | getPassengerNameByRefPipe: passRef}}
                    </div>
                  </ng-container>
                </div>
                <button class="btn btn-secondary align-self-baseline py-1"
                        *ngIf="osi.passengerRef.split(' ').length > 1"
                        (click)="passengersExpanded[idx] = !passengersExpanded[idx]">
                  <i class='fa fs-10' [ngClass]="!passengersExpanded[idx] ? 'fa-plus' : 'fa-minus'"></i>
                </button>
              </div>
            </ng-template>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
