<div class="common-fare-type-wp">
              <span class="common-fare-type badge-rectangle" *ngIf="mainFareType"
                    [ngStyle]="{ 'background-color': mainFareType.fareTypesCategory.backgroundColor }"
                    [ngbPopover]="mainFareType.fareTooltipText"
                    [triggers]="'hover'">
                {{mainFareType.fareTypesCategory.groupName}}
              </span>
  <ng-container *ngIf="fareTypeCategories">
                 <span class="common-fare-type badge-rectangle additional-type"
                       *ngFor="let category of fareTypeCategories"
                       [ngStyle]="{ 'background-color': category.backgroundColor }">
                {{category.groupName}}
                </span>
  </ng-container>

</div>
