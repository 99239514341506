import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isSelectedSeat'
})
export class IsSelectedSeatPipe implements PipeTransform {

  transform(column: string, seats: any[], row: number, segment: string): boolean {
    
    let isSelectedSeat = false;

    seats?.map(seat => {
      if (seat.location.column === column && seat.location.row === row && seat.segment === segment) {
        isSelectedSeat = true;
      }
    });
    
    return isSelectedSeat;
    
  }
}
