<div class="mt-3">
  <div class="row">
    <div class="col-12">
      <h1 class="mb-0">Manage Subagencies</h1>
    </div>
    <div class="col-12">
      <button class="btn btn-primary rounded float-right"
              title="Add subagency"
              (click)="addNewSubagency(addNewSubagencyModal)">
        <i class='fa fa-plus'></i>
      </button>
    </div>
  </div>
  <div class="mt-4" *ngIf="agencies.length">
    <div class="table-responsive">
      <table class="table table-default table-hover mb-0">
        <thead>
        <tr>
          <th scope="col">Subagency</th>
          <th scope="col">Managers</th>
          <th scope="col">Agents</th>
          <th scope="col"></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let agency of agencies" (click)="view(agency.id)" class="cursor-pointer">
          <td>{{agency.name}}</td>
          <td>{{getNumberOfRoles(agency, 'manager')}}</td>
          <td>{{getNumberOfRoles(agency, 'agent')}}</td>
          <td>
            <div class="d-flex align-items-center justify-content-end gap-10">
              <i class="material-symbols-outlined remove-icon"
                 title="Remove"
                 (click)="$event.stopPropagation(); deactivateAgency(agency.id)">
                delete
              </i>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="row" *ngIf='!agencies.length'>
    <div class="col">
      No agencies found
    </div>
  </div>
</div>

<ng-template #addNewSubagencyModal let-c="close" let-d="dismiss">
  <div class="modal-card card">
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="card-header-title">New Agency</h3>
        </div>
        <div class="col-auto">
          <i
            data-dismiss="modal"
            aria-hidden="true"
            (click)="d('closed')"
            class="material-icons float-right close-icon">
            close
          </i>
        </div>
      </div>
    </div>
    <div class="card-body row">
      <div class="form-group form-group-sm col-lg-12">
        <label class="font-weight-bold">Agency Name</label>
        <input
          [ngStyle]="{'border-color' : errors.agency ? '#dc3545' : null}"
          [(ngModel)]="model.name"
          class="form-control"
          type="text"
          placeholder="Agency name"
          required
        >
        <div class="errors" [hidden]="!errors.agency">{{errors.agency | capitalizeFirstLetter}}</div>
      </div>
      <div class="form-group form-group-sm col-lg-12">
        <label class="font-weight-bold">Manager Email</label>
        <input
          [ngStyle]="{'border-color' : errors.email ? '#dc3545' : null}"
          [(ngModel)]="model.email"
          class="form-control"
          type="text"
          placeholder="Email"
          required>
        <div class="errors" [hidden]="!errors.email">{{errors.email | capitalizeFirstLetter}}</div>
      </div>
      <div class="col-lg-12">
        <div *ngIf="errors.error?.summary" class="alert-danger-provider p-3 mb-3 rounded">{{errors.error.summary}}</div>
        <error-alert-block
          [error]="agencyError">
        </error-alert-block>
      </div>
    </div>
    <div class="card-footer text-right">
      <button
        type="button"
        class="btn btn-secondary mr-2"
        (click)="d('closed')">Cancel
      </button>
      <button
        type="button"
        class="btn btn-primary"
        (click)="save(c);"
        [disabled]='!(model.email && model.name)'>
        Add
      </button>
    </div>
  </div>
</ng-template>
