<div class='list'>
  <div class="check-row mb-2" [class.mt-3]="!isFirstRoute">
    <h3 class="text-center cursor-default mb-0" *ngIf="(connectionsSimpleModel[route] | keys).length">
      {{route.substr(0,3)}} → {{route.substr(3)}}
    </h3>
    <button class="btn btn-sm btn-secondary" (click)="selectAllCheckboxes(route)">
      Select all
    </button>
  </div>
  <div class='check-row'>
    <div class="d-flex gap-5">
      <label class="checkbox custom-checkbox-1" [for]="route + 'directFlights'">
        <input type="checkbox"
               [id]="route + 'directFlights'"
               [checked]="isDirectFlightsIncluded"
               (change)="directFlightsSelected($event.target.checked)"/>
        <div class="checkbox__checkmark"></div>
        <div class="checkbox__body">
          Direct flights
        </div>
      </label>
      <span class="only-button" (click)="directFlightsOnly()">only</span>
    </div>
  </div>
  <div class='connection-row' *ngFor="let connection of (connectionsSimpleModel[route] | keys); let i = index">
    <div class="d-flex gap-5">
      <label class="checkbox custom-checkbox-1"
             [for]="connection + route + connectionsSimpleModel[route][connection] + i">
        <input type="checkbox"
               [id]="connection + route + connectionsSimpleModel[route][connection] + i"
               [(ngModel)]="connectionsSimpleModel[route][connection]"
               (change)="emitConnectionsValueChanged.emit(route)"/>
        <div class="checkbox__checkmark"></div>
        <div class="checkbox__body">
          {{connection}}
        </div>
      </label>
      <span class="only-button" (click)="selectedOneConnection(connection, route)">only</span>
    </div>
    <div class="price-value" *ngIf='connectionPrices[route][connection]'>
      {{connectionPrices[route][connection].price | number :'1.2-2'}}
      {{connectionPrices[route][connection].currency}}
    </div>
  </div>
</div>
