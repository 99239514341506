import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {FlightInfo} from "../../interfaces/offer";

@Component({
  selector: 'app-flight-info',
  templateUrl: './flight-info.component.html',
  styleUrls: ['./flight-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlightInfoComponent {

  constructor() { }

  @Input() flightInfo: FlightInfo;
  @Input() showBaggage = true;
  @Input() showRow = true;
  @Input() isDisabled = false;
}
