<h4 class="ml-5">Tickets</h4>
<ng-container
  *ngIf="(tickets | ticketsByTravRef: passenger.travelerReference)?.length; else noTickets">
  <div class="table-responsive">
    <table class="mb-0 table table-default outer-table">
      <thead>
      <tr class="cursor-pointer" (click)="isOuterTableOpened = !isOuterTableOpened">
        <th>Name</th>
        <th>Number</th>
        <th>Type</th>
        <th># Booklets</th>
        <th>Issue Date</th>
        <th *ngIf="showCell.price">Price</th>
        <th style="width: 50px">
          <div class="d-flex justify-content-end" *ngIf="!isPdf">
            <i class="material-symbols-outlined fs-20" [@rotate]="!isOuterTableOpened">
              expand_less
            </i>
          </div>
        </th>
      </tr>
      </thead>
      <tbody [ngClass]="!isOuterTableOpened ? 'd-none' : ''">
      <ng-container *ngFor="let ticket of (tickets | ticketsByTravRef: passenger.travelerReference); let i = index">
        <tr>
          <td>{{ticket.typeCodeDefinition}}</td>
          <td>{{ ticket.ticketNumber | getTicketNumber }}</td>
          <td>{{ ticket.typeCode }}</td>
          <td>{{ ticket.numberOfBooklets }}</td>
          <td>{{ (ticket.dateOfIssue | dateFormat) | async }} {{ ticket.timeOfIssue }}</td>
          <td *ngIf="showCell.price">
            <ng-container *ngIf="ticket.price && ticket.price.consumer?.total > 0">
              <ng-container *ngIf="!isPdf">
                <app-show-pax-breakdown-info
                  [consumerPrice]="ticket.price.consumer"
                  [passengerType]="passenger.passengerType">
                  <span class="cursor-pointer">{{ticket.price.consumer.total}} {{ticket.price.consumer.currency}}</span>
                </app-show-pax-breakdown-info>
              </ng-container>
              <ng-container *ngIf="isPdf">
                {{ticket.price.consumer.total}} {{ticket.price.consumer.currency}}
              </ng-container>
            </ng-container>
            <ng-container *ngIf="ticket.linkedTo">
              <div class="d-flex">
                <i
                  [ngbPopover]="conjunctionTicket"
                  [triggers]="'hover'"
                  container="body"
                  placement="bottom"
                  popoverClass="conjunction-ticket-popover"
                  class="material-icons red-color">
                  info_outline
                </i>
              </div>
              <ng-template #conjunctionTicket>
                <div class="conjunction-ticket">
                  <div class="title"> Conjunction ticket </div>
                  <div class="ticket-number"><b>{{ticket.linkedTo | getTicketNumber}}</b></div>
                </div>
              </ng-template>
            </ng-container>
          </td>
          <td></td>
        </tr>
        <tr *ngIf="ticket.coupons?.length">
        <!-- ticket.coupons[0]?.segment - temporary condition -->
          <td [attr.colspan]="showCell.price ? 7 : 6" class="inner-table-td">
            <table class="mb-0 table table-responsive inner-table">
              <thead>
              <tr (click)="toggleInnerTable(i)">
                <th style="width: 160px;">
                  <div class="d-flex gap-30">
                    Coupons
                    <ng-container *ngIf="ticket | getCouponStatusCode as statusCode">
                      <i class="material-symbols-outlined exclamation-icon {{statusCode}}"
                         *ngIf="!showInnerTable[i] && statusCode">
                        exclamation
                      </i>
                    </ng-container>
                  </div>
                </th>
                <th style="width: 160px;">
                  <ng-container *ngIf="ticket.coupons[0]?.segment">
                    <span *ngIf="showInnerTable[i] || isPdf" [@fadeInOnEnter] [@fadeOutOnLeave]>Date</span>
                  </ng-container>
                </th>
                <th style="width: 160px;">
                  <ng-container *ngIf="ticket.coupons[0]?.segment">
                    <span *ngIf="showInnerTable[i] || isPdf" [@fadeInOnEnter] [@fadeOutOnLeave]>Time</span>
                  </ng-container>
                </th>
                <th style="width: 160px;">
                  <ng-container *ngIf="ticket.coupons[0]?.segment">
                    <span *ngIf="showInnerTable[i] || isPdf" [@fadeInOnEnter] [@fadeOutOnLeave]>Flight #</span>
                  </ng-container>
                </th>
                <th style="width: 200px;">
                  <ng-container *ngIf="ticket.coupons[0]?.segment">
                    <span *ngIf="showInnerTable[i] || isPdf" [@fadeInOnEnter] [@fadeOutOnLeave]>FareBasisCode</span>
                  </ng-container>
                </th>
                <th style="width: 180px;"><span *ngIf="showInnerTable[i] || isPdf" [@fadeInOnEnter] [@fadeOutOnLeave]>Status</span></th>
                <th>
                  <div class="d-flex justify-content-end" *ngIf="!isPdf">
                    <i class="material-symbols-outlined fs-20" [@rotate]="!showInnerTable[i]">
                      expand_less
                    </i>
                  </div>
                </th>
              </tr>
              </thead>
              <tbody>
              <ng-container *ngIf="showInnerTable[i] || isPdf">
                <tr *ngFor="let c of ticket.coupons" [class]="c.statusCode" [@slideInDownOnEnter]="!showInnerTable[i]">
                  <ng-container *ngIf="c.segment">
                    <td>{{c.segment.departure.airportCode}} {{isPdf ? '-' : '→'}} {{c.segment.arrival.airportCode}}</td>
                    <td>{{(c.segment.departure.date | dateFormat) | async}}</td>
                    <td>{{c.segment.departure.time}}</td>
                    <td>{{c.segment.marketingCarrier.airlineID}} {{c.segment.marketingCarrier.flightNumber}}</td>
                    <td>{{c.fareBasisCode || '-'}}</td>
                  </ng-container>
                  <ng-container *ngIf="!c.segment">
                    <td>{{c.number}}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </ng-container>
                  <td>
                    <span class="status" *ngIf="c.statusDefinition">{{c.statusDefinition}}</span>
                    <span class="status" *ngIf="!c.statusDefinition && c.status">{{c.status}}</span>
                    <span class="status" *ngIf="!c.statusDefinition && !c.status">Missing</span>
                  </td>
                  <td></td>
                </tr>
              </ng-container>
              </tbody>
            </table>
          </td>
        </tr>
      </ng-container>
      </tbody>
    </table>
  </div>
</ng-container>
<ng-template #noTickets>
  <p>Tickets are not provided</p>
</ng-template>
