import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {Dictionary} from '../../types/dictionary';
import {LocalStorageService} from '../../services/local-storage.service';
import {HelpersService} from '../../services/helpers.service';
import {ActivatedRoute, Router} from '@angular/router';
import {OfferService} from "../../services/offer.service";
import {
  collapseAnimation, rotateAnimation,
} from "angular-animations";


@Component({
  selector: 'app-offer-grid',
  templateUrl: './offer-grid.component.html',
  styleUrls: ['./offer-grid.component.scss'],
  animations: [
    collapseAnimation(),
    rotateAnimation({ degrees: -180, duration: 500 }),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OfferGridComponent implements OnInit, OnChanges {
  cabinTypes = Dictionary.CabinTypes;

  isTableVisible = true;

  model = {
    // grid checkboxes
    selectAll: false,
    providers: [],
    cabins: [],
    groups: [],

    offers: [],
    // grid options
    gridType: 'price',

    checkedBaggage: '',
    refundableFares: 'light',
    hideCodeshares: false,
    fareRules: ''
  };
  fareRules = {name: { change: 'Not Allowed', cancel: 'Not Allowed'}, value: { change: 'Disallowed(All)', cancel: 'Disallowed(All)' }};

  offersGrid: any = {};
  offersClasses = [];
  offersGridProviderOrder = [];

  dataForGridByClass = {};
  allCodes = [];

  sortOptions: any = {
    price: "Cabin",
    class: "Class",
  };

  baggageFilterValue = "Any";

  cabinClassPriority = {
    'FIRST': 0,
    'BUSINESS': 1,
    'PREMIUM ECONOMY': 2,
    'ECONOMY': 3
  };
  @Input() searchCabin = '';
  @Input() searchIsPNR: false;
  @Input() offers = [];
  @Input() options: any = {
    codeshares: true,
    mixClasses: true,
    groupByFlightCounter: true
  };
  @Input() showGrid = true;
  @Input() isNewSearch: boolean;
  @Input() isDoneSearch = false;
  @Input() isOfferFound = false;

  @Output() emitFiltersTouched = new EventEmitter();
  @Output() emitGridChange = new EventEmitter();

  MAX_VALUE_OF_NUMBER = Number.MAX_VALUE;
  lastOffersCount = 0;
  providerErrors: any = {};
  isErrorsExpanded = false;

  constructor(public ls: LocalStorageService,
    private router: Router,
    private route: ActivatedRoute,
    public helpers: HelpersService,
    public offerService: OfferService) {
  }


  ngOnInit() {
    this.buildGrid();
    this.applySelectedGroupFromUrl();
    this.getProvidersWithError();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.offers && changes.offers.previousValue) {
      this.buildGrid();
      if (this.model.selectAll) {
        this.toggleActiveAll();
      } else {
        this.onGridChange();
      }
    }
    if (!!changes.isNewSearch?.currentValue) {
      this.toggleActiveAll();
    }
  }

  onGridChange(forceEvent = false) {
    this.model.offers = this.filterBySelectedGroups();
    if (this.model.offers.length !== this.lastOffersCount || forceEvent) {
      this.emitGridChange.emit(this.model);
    }
    this.lastOffersCount = this.model.offers.length;
  }

  toggleTable() {
    this.isTableVisible = !this.isTableVisible;
  }

  toggleActiveAll(triggerEvent = true) {
    this.model.selectAll = true;
    this.model.cabins = [];
    this.model.providers = [];
    this.model.groups = [];

    this.selectAll();
    this.applySelectedGroupsToUrl();

    if (triggerEvent) {
      this.onGridChange(triggerEvent);
    }
  }

  selectProvider(provider, flag, event?) {
    this.model.selectAll = false;
    this.emitFiltersTouched.emit(true);
    this.offerService.changeOfferGrid();
    if (this.model.cabins.length && !(event.metaKey || event.ctrlKey)) {
      this.model.cabins = [];
      this.model.groups = [];
    }

    if (!(event.metaKey || event.ctrlKey)) {
      this.model.cabins = [];
      this.model.groups = [];
      this.model.providers = [];
    }

    // refactor this
    if (this.model.gridType === 'price') {
      Object.keys(this.offersGrid[provider]).map(cls => {

        const value = `${provider}-${this.helpers.getKeyByValue(this.cabinTypes, cls)}`;
        if (flag && this.model.groups.indexOf(value) === -1) {
          this.model.groups.push(value);
        }
        if (!flag && !this.model.cabins.length && this.model.groups.indexOf(value) > -1) {
          this.model.groups.splice(this.model.groups.indexOf(value), 1);
        }

        if (!flag && this.model.cabins.length && !(this.model.cabins.includes(value.split('-').pop())) && this.model.groups.indexOf(value) > -1) {
          this.model.groups.splice(this.model.groups.indexOf(value), 1);
        }

        if (flag && this.model.providers.indexOf(provider) === -1) {
          this.model.providers.push(provider);
        }
        const providerIndex = this.model.providers.indexOf(provider);
        if (!flag && providerIndex > -1) {
          this.model.providers.splice(providerIndex, 1);
        }
      });
    } else if (this.model.gridType === 'class') {
      Object.keys(this.dataForGridByClass[provider]).map(cls => {

        const value = `${provider}-${cls}`;
        if (flag && this.model.groups.indexOf(value) === -1) {
          this.model.groups.push(value);
        }
        if (!flag && !this.model.cabins.length && this.model.groups.indexOf(value) > -1) {
          this.model.groups.splice(this.model.groups.indexOf(value), 1);
        }
        if (!flag && this.model.cabins.length && !(this.model.cabins.includes(cls)) && this.model.groups.indexOf(value) > -1) {
          this.model.groups.splice(this.model.groups.indexOf(value), 1);
        }

        if (flag && this.model.providers.indexOf(provider) === -1) {
          this.model.providers.push(provider);
        }
        const providerIndex = this.model.providers.indexOf(provider);
        if (!flag && providerIndex > -1) {
          this.model.providers.splice(providerIndex, 1);
        }
      });
    }
    this.model.groups = [...this.model.groups];
    this.model.providers = [...this.model.providers];

    this.onGridChange();
    if (!this.model.offers.length) {
      this.toggleActiveAll();
    }
  }

  applySelectedGroupsToUrl() {
    let params = {...this.route.snapshot.queryParams};
    params[`selected_table_sort_value`] = this.model.gridType;
    this.router.navigate([], {queryParams: params});
  }

  selectOffers(event, provider, cabin, item = '') {
    let key = '';
    let cabinNum = '';
    this.emitFiltersTouched.emit(true);
    this.offerService.changeOfferGrid();
    if (this.model.gridType === 'price') {
      cabinNum = this.helpers.getKeyByValue(this.cabinTypes, cabin);
      key = `${provider}-${cabinNum}`;
    } else if (this.model.gridType === 'class') {
      cabinNum = cabin;
      key = `${provider}-${cabin}`;
    }

    const isChecked = this.model.groups.includes(key);

    if (isChecked) {
      if (this.model.selectAll) {
        this.model.providers = [];
        this.model.cabins = [];
        this.model.groups = [key];
        // event.target.checked = true;
      } else {
        this.helpers.removeItemFromArrayIfExist(this.model.groups, key);
        this.helpers.removeItemFromArrayIfExist(this.model.providers, provider);
        this.helpers.removeItemFromArrayIfExist(this.model.cabins, cabinNum);
      }
    } else {
      if (event.metaKey || event.ctrlKey) {
        this.model.groups.push(key);
      } else {
        this.model.providers = [];
        this.model.cabins = [];
        this.model.groups = [key];
      }
    }
    this.model.selectAll = false;
    this.model.groups = [...this.model.groups];

    this.onGridChange();
    if (!this.model.offers.length) {
      this.toggleActiveAll();
    }
  }

  selectAll() {
    let groups = [];
    let cabins = [];
    let providers = [];
    if (this.model.selectAll) {
      if (this.model.gridType === 'price') {
        this.offersClasses.map(cls => {
          this.offersGridProviderOrder.map(provider => {
            const key = `${provider}-${this.helpers.getKeyByValue(this.cabinTypes, cls)}`;
            const cabin = key.split('-')?.pop();
            if (!cabins.includes(cabin)) {
              cabins.push(cabin);
            }
            providers.push(provider);
            groups.push(key);
          });
        });
      } else if (this.model.gridType === 'class') {
        this.allCodes.map(cls => {
          this.offersGridProviderOrder.map(provider => {
            const key = `${provider}-${cls}`;
            const cabin = key.split('-')?.pop();
            if (!cabins.includes(cabin)) {
              cabins.push(cabin);
            }
            providers.push(provider);
            groups.push(key);
          });
        });
      }
    }
    this.model.groups = [...groups];
    this.model.cabins = [...cabins];
    this.model.providers = [...providers];
  }

  buildGrid() {
    let offersGridByClass = {};
    let allCodes = [];
    let offersGrid = {};
    let offersClasses = [...Dictionary.SuperClasses];
    let offersGridProviderOrder = [];
    let filterPriorityCabins = [];
    let cabinInArray = {};

    this.offers.map(offer => {
      let cabinClass = offer.flights[0].segments[0].detail.classOfService.cabinDesignator;
      let owner = offer.owner;
      const offerCodes = [];

      offer.flights.map(f => {
        f.segments.map(fs => {
          let code = fs.detail.classOfService.code;
          if (!offerCodes.includes(code)) {
            offerCodes.push(code);
          }
          let curCabinClass = fs.detail.classOfService.cabinDesignator;
          if (!cabinClass) {
            cabinClass = curCabinClass;
          } else {
            if (this.cabinClassPriority[cabinClass] > this.cabinClassPriority[curCabinClass]) {
              cabinClass = curCabinClass;
            }
          }
        });
      });

      if (!offersGridByClass[owner]) {
        offersGridByClass[owner] = {};
        offerCodes.map(code => {
          offersGridByClass[owner][code] = {
            count: 1,
            minPrice: offer.price.consumer.total,
            currency: '',
            numberOfStops: 0,
            offers: [offer],
          };
        });
      } else {
        offerCodes.map(code => {
          if (!!offersGridByClass[owner][code]) {
            offersGridByClass[owner][code].offers.push(offer);
            offersGridByClass[owner][code].count = offersGridByClass[owner][code].offers.length;
            offersGridByClass[owner][code].minPrice = Math.min(offersGridByClass[owner][code].minPrice, Math.floor(offer.price.consumer.total));
            offersGridByClass[owner][code].currency = offer.price.consumer.currency;
          } else {
            offersGridByClass[owner][code] = {
              count: 1,
              minPrice: offer.price.consumer.total,
              currency: '',
              numberOfStops: 0,
              offers: [offer],
            };
          }
        });
      }

      if (!cabinClass) {
        cabinClass = 'ECONOMY';
        offer.flights[0].segments[0].detail.classOfService.cabinDesignator = 'ECONOMY';
      }

      if (!cabinInArray[cabinClass]) {
        filterPriorityCabins.push(cabinClass);
        cabinInArray[cabinClass] = true;
      }

      if (offersClasses.indexOf(cabinClass) === -1) {
        offersClasses.push(cabinClass);
      }

      if (offersGridProviderOrder.indexOf(owner) === -1) {
        offersGridProviderOrder.push(owner);
      }
      if (!offersGrid[owner]) {
        offersGrid[owner] = {};

        offersClasses.map((offerClass) => {
          offersGrid[owner][offerClass] = {
            count: 0,
            minPrice: this.MAX_VALUE_OF_NUMBER,
            currency: '',
            numberOfStops: 0,
            offers: [],
          };
        });
      }

      if (!offersGrid[owner][cabinClass]) {
        offersGrid[owner][cabinClass] = {
          count: 0,
          minPrice: this.MAX_VALUE_OF_NUMBER,
          currency: '',
          numberOfStops: 0,
          offers: [],
        };
      }

      let cellInfo = Object.assign({}, offersGrid[owner][cabinClass]);
      cellInfo.count++;
      cellInfo.offers.push(offer);

      let setMajorOffer = false;
      if (cellInfo.minPrice === 0 || offer.price.consumer.total < cellInfo.minPrice) {
        setMajorOffer = true;
      }
      if (setMajorOffer) {
        cellInfo.minPrice = Math.min(offersGrid[owner][cabinClass].minPrice, Math.floor(offer.price.consumer.total));
        cellInfo.currency = offer.price.consumer.currency;
      }

      offersGrid[owner][cabinClass] = cellInfo;
    });

    filterPriorityCabins.sort((a, b) => this.cabinClassPriority[b] - this.cabinClassPriority[a]);

    Object.keys(offersGrid).map((owner) => {
      Object.keys(offersGrid[owner]).map((cabin) => {
        if (offersGrid[owner]) {
          let stops = offersGrid[owner][cabin].offers
            .map(item => Math.min(...item.flights
              .map(flight => flight.segments.length - 1)));
          offersGrid[owner][cabin].numberOfStops = Math.min(...stops);
        }
      });
    });

    Object.keys(offersGridByClass).map((owner) => {
      Object.keys(offersGridByClass[owner]).map((code) => {
        if (offersGridByClass[owner]) {
          allCodes = [...new Set([...allCodes, ...Object.keys(offersGridByClass[owner])])].sort();
          let stops = [];
          if (offersGrid[owner][code]) {
            stops = offersGrid[owner][code].offers
              .map(item => Math.min(...item.flights
                .map(flight => flight.segments.length - 1)));
            offersGrid[owner][code].numberOfStops = Math.min(...stops);
          }
        }
      });
    });

    this.offersGrid = offersGrid;
    this.offersClasses = offersClasses;

    this.dataForGridByClass = offersGridByClass;
    this.allCodes = allCodes;

    // sort
    this.offersGridProviderOrder = offersGridProviderOrder.sort((a, b) => {
      const minPriceA = this.offersGrid[a]?.[filterPriorityCabins[0]]?.minPrice;
      const minPriceB = this.offersGrid[b]?.[filterPriorityCabins[0]]?.minPrice;
      return minPriceA > minPriceB ? 1 : -1;
    });
  }

  filterBySelectedGroups() {
    let filteredOffers = [];

    if (this.model.gridType === 'price') {
      this.model.groups.map(pair => {
        const splitPair = pair.split('-');
        if (this.offersGrid[splitPair[0]] && this.cabinTypes[splitPair[1]] !== undefined) {
          const gridOffers = this.offersGrid[splitPair[0]][this.cabinTypes[splitPair[1]].toUpperCase()].offers;
          if (gridOffers.length) {
            filteredOffers = [...filteredOffers, ...gridOffers];
          }
        }
      });
    } else if (this.model.gridType === 'class') {
      this.model.groups.map(pair => {
        const splitPair = pair.split('-');
        if (this.dataForGridByClass[splitPair[0]] && this.dataForGridByClass[splitPair[0]][splitPair[1]] !== undefined) {
          const gridOffers = this.dataForGridByClass[splitPair[0]][splitPair[1]].offers;
          if (gridOffers.length) {
            filteredOffers = [...filteredOffers, ...gridOffers];
          }
        }
      });
    }

    return filteredOffers;
  }

  selectCabin(cabin, flag, event?) {
    this.model.selectAll = false;
    this.emitFiltersTouched.emit(true);
    this.offerService.changeOfferGrid();
    // this.searchObj.offerFareName = '';
    this.updateColumnListOfSelectedGridPairs(
      flag,
      cabin,
      event
    );
    this.onGridChange();
    if (!this.model.offers.length) {
      this.toggleActiveAll();
    }
  }

  setSortValue(gridSort: any) {
    this.model.groups = [];
    this.model.cabins = [];
    this.model.providers = [];

    this.model.gridType = gridSort;
    this.buildGrid();

    this.toggleActiveAll();
  }

  updateColumnListOfSelectedGridPairs(flag, cls, event) {
    if (this.model.providers.length && !(event.metaKey || event.ctrlKey)) {
      this.model.providers = [];
      this.model.groups = [];
    }

    if (!(event.metaKey || event.ctrlKey)) {
      this.model.cabins = [];
      this.model.groups = [];
      this.model.providers = [];
    }

    if (this.model.gridType === 'price') {
      Object.keys(this.offersGrid).map(provider => {
        const value = `${provider}-${this.helpers.getKeyByValue(this.cabinTypes, cls)}`;

        if (flag && this.model.groups.indexOf(value) === -1) {
          this.model.groups.push(value);
        }
        if (!flag && !this.model.providers.length && this.model.groups.indexOf(value) > -1) {
          this.model.groups.splice(this.model.groups.indexOf(value), 1);
        }
        if (!flag && this.model.providers.length && !(this.model.providers.includes(provider)) && this.model.groups.indexOf(value) > -1) {
          this.model.groups.splice(this.model.groups.indexOf(value), 1);
        }
        const cabinNum = this.helpers.getKeyByValue(this.cabinTypes, cls);
        if (flag && this.model.cabins.indexOf(cabinNum) === -1) {
          this.model.cabins.push(cabinNum);
        }
        const providerIndex = this.model.cabins.indexOf(cabinNum);
        if (!flag && providerIndex > -1) {
          this.model.cabins.splice(providerIndex, 1);
        }
      });
    } else if (this.model.gridType === 'class') {
      Object.keys(this.dataForGridByClass).map(provider => {
        const value = `${provider}-${cls}`;

        if (flag && this.model.groups.indexOf(value) === -1) {
          this.model.groups.push(value);
        }
        if (!flag && !this.model.providers.length && this.model.groups.indexOf(value) > -1) {
          this.model.groups.splice(this.model.groups.indexOf(value), 1);
        }
        if (!flag && this.model.providers.length && !(this.model.providers.includes(provider)) && this.model.groups.indexOf(value) > -1) {
          this.model.groups.splice(this.model.groups.indexOf(value), 1);
        }
        // const cabinNum = this.helpers.getKeyByValue(this.cabinTypes, cls);
        if (flag && this.model.cabins.indexOf(cls) === -1) {
          this.model.cabins.push(cls);
        }
        const providerIndex = this.model.cabins.indexOf(cls);
        if (!flag && providerIndex > -1) {
          this.model.cabins.splice(providerIndex, 1);
        }
      });
    }
    this.model.groups = [...this.model.groups];
    this.model.cabins = [...this.model.cabins];
  }

  applySelectedGroupFromUrl() {
    const params = this.route.snapshot.queryParams;
    let selectedTableSort = params['selected_table_sort_value'];
    let selectedFareRules = params['selected_fare_rules'];
    let selectedCheckedBaggage = params['selected_checked_baggage'];

    if (selectedTableSort) {
      this.model.gridType = selectedTableSort;
    }
    this.toggleActiveAll(false);

    if (selectedFareRules) {
      let parsedFareRules = JSON.parse(selectedFareRules);
      this.fareRules.name = parsedFareRules.name;
      this.fareRules.value = parsedFareRules.value;
      this.model.fareRules = this.fareRules.name.cancel + '|' + this.fareRules.name.change;
    }

    if (selectedCheckedBaggage) {
      this.baggageFilterValue = selectedCheckedBaggage.replace(/([A-Z])/g, ' $1').trim();
      this.model.checkedBaggage = selectedCheckedBaggage;
    }

    this.onGridChange();
  }

  public changeShowCodeshares() {
    this.onGridChange(true);
  }

  onSelectedFare(option, rules) {
    this.fareRules.name[rules] = option.name;
    this.fareRules.value[rules] = option.value;
    this.model.fareRules = this.fareRules.name.cancel + '|' + this.fareRules.name.change;
    this.applySelectedFareRulesToUrl();

    setTimeout(() => this.onGridChange(true));
  }

  applySelectedFareRulesToUrl() {
    let params = {...this.route.snapshot.queryParams};
    params[`selected_fare_rules`] = JSON.stringify(this.fareRules);
    this.router.navigate([], {queryParams: params});
  }

  onChangeBaggageFilter(value: string) {
    switch (value) {
      case "Included":
        this.baggageFilterValue = value;
        break;
      case "NotIncluded":
        this.baggageFilterValue = "Not Included";
        break;
      default:
        this.baggageFilterValue = "Any";
    }

    this.model.checkedBaggage = value;
    this.applySelectedCheckedBaggageToUrl(value);

    this.onGridChange(true);
  }

  applySelectedCheckedBaggageToUrl(value) {
    let params = {...this.route.snapshot.queryParams};
    params[`selected_checked_baggage`] = value;
    this.router.navigate([], {queryParams: params});
  }

  getProvidersWithError() {
    this.offerService.getProviderErrors().subscribe(providerErrors => {
      this.providerErrors = providerErrors;
    });
  }

  toggleErrors() {
    this.isErrorsExpanded = !this.isErrorsExpanded;
  }

  get changeRulesLabel() {
    if (this.fareRules.name.change !== 'Not Allowed') {
      return `Change allowance: ${this.fareRules.value.change}`;
    } else {
      return 'Change allowance: Any';
    }
  }

  get cancelRulesLabel() {
    if (this.fareRules.name.cancel !== 'Not Allowed') {
      return `Cancel allowance: ${this.fareRules.value.cancel}`;
    } else {
      return 'Cancel allowance: Any';
    }
  }
}
