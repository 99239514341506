import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getTotalServicePrice'
})
export class GetTotalServicePricePipe implements PipeTransform {

  transform(srv: any): string {
    if (!srv.price || !srv.price.consumer) {
      return 'FREE';
    }

    const totalPrice = srv.price.consumer.total || 0;
    const quantity = srv.quantity || 1;
    const currency = srv.price.consumer.currency;

    return totalPrice
      ? `${totalPrice} ${currency} * ${quantity} = ${(totalPrice * quantity).toFixed(2)} ${currency}`
      : 'FREE';
  }

}
