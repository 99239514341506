<div class="row" *ngIf="comment && !isEditing" [ngClass]="{'d-none': comment && isEditing}">
  <div class="col mt-3 p-3 comment-text">
    <div class="text-area-mask" [innerHTML]="comment"></div>
    <button class="btn-edit btn btn-outline-primary float-right" *ngIf="comment && !isEditing" (click)="updateExistingComment(textAreaElement)">Edit</button>
  </div>
</div>

<div class="row" [ngClass]="{'d-none': comment && !isEditing}">
  <div class="col mt-3 p-3">
    <textarea placeholder="Type your comment" name="" id="" rows="12" #textAreaElement autofocus></textarea>
    <button class="btn btn-tonal-primary mt-1 float-right" (click)="sendComment(textAreaElement)">Update</button>
    <button *ngIf="comment" class="btn btn-secondary mt-1 mr-2 float-right" (click)="cancel()">Cancel</button>
  </div>
</div>

