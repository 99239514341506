<div>
  <h4>Select Seats (optional)</h4>
  <ngb-tabset *ngIf="seatAvailability">
    <ngb-tab *ngFor="let disp of seatAvailability.seatDisplay; let i = index"
             title="{{ segments | getSegmentRouteByIDPipe:disp.segment:true }}">
      <ng-template ngbTabContent>
        <div class="seats-content-wp" *ngIf="s.seats[disp.segment]; else noSeats">
          <div class="passengers-wp" *ngIf="passengers && passengers.length">
            <div class="passenger"
                 *ngFor="let passenger of passengers; let i = index;"
                 (click)="changeSelectedPassenger(passenger)"
                 [ngClass]="{'selected-passenger': passenger.travelerReference === selectedPassenger}">
              <div class="seat">
                <ng-container *ngFor="let key of (s.selectedSeats[disp.segment] | keys)">
                  <span class="selected-seat"
                        *ngIf="s.selectedSeats[disp.segment][key] === passenger.travelerReference">
                    {{key}}
                  </span>
                </ng-container>
              </div>
              <div class="full-name">
                ({{passenger.passengerType}}) {{passenger.data.name}} {{passenger.data.surname}}
              </div>
            </div>

            <div class="disclosures mt-5"
                 *ngIf="segmentDisclosures[disp.segment].length || generalDisclosures.length">
              <hr>
              <div class="overflow-hidden"
                   [ngStyle]="{'max-height': !allDisclosuresAreShown ? '120px' : 'fit-content' }"
                   #disclosures>
                <ul *ngFor="let discl of segmentDisclosures[disp.segment]">
                  <li>
                    <div class="d-flex align-items-center gap-10">
                      {{discl.text}}
                      <a *ngIf="discl?.media?.link"
                         container="body"
                         href="{{ discl.media.link }}"
                         target="_blank"
                      >
                        More info
                        <i class="material-icons fs-17"> open_in_new </i>
                      </a>
                    </div>
                  </li>
                </ul>
                <ul *ngFor="let discl of generalDisclosures">
                  <li>
                    <div class="d-flex align-items-center gap-10">
                      {{discl.text}}
                      <a *ngIf="discl?.media?.link"
                         container="body"
                         href="{{ discl.media.link }}"
                         target="_blank"
                      >
                        More info
                        <i class="material-icons fs-17"> open_in_new </i>
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="d-flex align-items-center gap-10 h-0"
                   [ngClass]="showExpandIcon ? 'mt-4' : 'mt-3'"
                   [@fadeIn]="allDisclosuresAreShown"
                   [@fadeInUp]="!allDisclosuresAreShown">
                <ng-container *ngIf="showExpandIcon">
                  <hr class="w-50">
                  <div class="expand-icon-wrapper" (click)="toggleReadDisclosures()">
                  <span class="material-symbols-outlined" [@rotate]="allDisclosuresAreShown">
                    expand_more
                  </span>
                  </div>
                  <hr class="w-50">
                </ng-container>
                <ng-container *ngIf="!showExpandIcon">
                  <hr class="w-100">
                </ng-container>
              </div>
            </div>

            <div class="seats-list mt-5">
              <div class="d-flex flex-column">
                <ng-container *ngFor="let range of priceRanges; let index = index">
                  <div class="d-flex align-items-center gap-5">
                    <div class="seat" [class]="'price-' + index">
                      <div></div>
                    </div>
                    {{ range.min }} <ng-container *ngIf="range.min !== range.max"> - {{ range.max }}</ng-container> {{ currency }}
                  </div>
                </ng-container>
              </div>
              <div class="mt-4 d-flex align-items-center gap-30">
                <div class="d-flex align-items-center gap-5">
                  <div class="seat selected">
                    <div></div>
                  </div>
                  Selected
                </div>
                <div class="d-flex align-items-center gap-5">
                  <div class="seat disabled">
                    <div></div>
                  </div>
                  Unavailable
                </div>
              </div>
            </div>
          </div>

        <div class="mt-3 table-seats-wp">
          <div class="table-seats">
            <table class="">
              <thead>
              <tr>
                <th></th>
                <th style="width: 60px"></th>
                <th *ngFor="let char of segmentMatrix[disp.segment].columns | split">
                  {{ char === '-' ? '' : char}}
                </th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr
                  *ngFor="let i of s.createRange(segmentMatrix[disp.segment].rows.last - segmentMatrix[disp.segment].rows.first + 1)"
                  [ngClass]="{'emergency-row' :exitsInfo[disp.segment]?.includes(i + segmentMatrix[disp.segment].rows.first)}"
              >
                <td>{{ i + segmentMatrix[disp.segment].rows.first }}</td>
                 <td>
                   <div *ngIf="(exitsInfo | keyvalue).length ? exitsInfo[disp.segment]?.includes(i + segmentMatrix[disp.segment].rows.first) : false;" >
                     <ng-container [ngTemplateOutlet]="exitIcon"></ng-container>
                   </div>
                 </td>
                <td
                    *ngFor="let char of segmentMatrix[disp.segment].columns | split"
                    [class.disable-click]="seats && seats.length && !(char | isSelectedSeat: seats : (i + segmentMatrix[disp.segment].rows.first) : disp.segment)
                    && (disp.segment | disableSelectSeatBySegment : seats) && s.seats[disp.segment][i + segmentMatrix[disp.segment].rows.first + char]?.isAllowedForPax"
                    (click)="s.seats[disp.segment][i + segmentMatrix[disp.segment].rows.first + char] !== undefined ? selectSeat(disp.segment, i, char) : ''"
                >
                  <div
                      *ngIf="char | isSeatExists: (i + segmentMatrix[disp.segment].rows.first) : disp.segment : s.originalSeatDisplay"
                      [ngbPopover]="popover"
                      popoverTitle="Seat Information"
                      triggers="mouseenter:mouseleave"
                      container="body"
                      [disablePopover]="!s.seats[disp.segment][i + segmentMatrix[disp.segment].rows.first + char]?.isAllowedForPax"
                      [class.disabled]="!s.seats[disp.segment][i + segmentMatrix[disp.segment].rows.first + char]?.isAllowedForPax"
                      [class.selected]="(s.selectedSeats[disp.segment][i + segmentMatrix[disp.segment].rows.first + char]) ||
                                        (char | isSelectedSeat: seats : (i + segmentMatrix[disp.segment].rows.first) : disp.segment)"
                      [class]="'price-' + (s.seats[disp.segment][i + segmentMatrix[disp.segment].rows.first + char]?.price.consumer.total | getPriceGroupIndexForSeat: priceRanges)"
                      class="seat"
                      container="body"
                  >
                    <div></div>
                  </div>
                  <ng-template #popover>
                    <ul class="list-group">
                      <ng-container *ngFor="let characteristic of (s.seats[disp.segment][i + segmentMatrix[disp.segment].rows.first + char]?.characteristics || [])">
                        <li class="list-group-item" *ngIf="characteristic.definition || characteristic.code">
                          <span *ngIf="characteristic.code">{{ characteristic.code }} - </span>
                          <span>{{ characteristic.definition }}</span>
                        </li>
                      </ng-container>
                    </ul>
                    <h4 style='color: #12263f; margin-top: 7px' *ngIf='s.seats && s.seats[disp.segment][i + segmentMatrix[disp.segment].rows.first + char]?.disclosures?.length'>Disclosures</h4>
                    <ul class="list-group">
                      <li class="list-group-item"
                          *ngFor="let disclosure of (s.seats[disp.segment][i + segmentMatrix[disp.segment].rows.first + char]?.disclosures || [])">
                        {{ disclosure.text }}
                      </li>
                    </ul>
                    <div class="font-weight-bold pt-2">
                      Price: {{ s.seats[disp.segment][i + segmentMatrix[disp.segment].rows.first + char]?.price.consumer.total | number :'1.2-2'}}
                      {{ s.seats[disp.segment][i + segmentMatrix[disp.segment].rows.first + char]?.price.consumer.currency}}
                    </div>
                  </ng-template>
                </td>
               <td>
                 <div *ngIf="(exitsInfo | keyvalue).length ? exitsInfo[disp.segment]?.includes(i + segmentMatrix[disp.segment].rows.first) : false" class="exit-right">
                   <ng-container [ngTemplateOutlet]="exitIcon"></ng-container>
                 </div>
               </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        </div>
        <ng-template #noSeats>
          <div class="alert alert-warning mt-4 mb-0 rounded">No seat selection available for this segment</div>
        </ng-template>
      </ng-template>
    </ngb-tab>
  </ngb-tabset>
</div>

<ng-template #exitIcon>
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Capa_1" x="0px" y="0px" width="38px" height="38px" viewBox="0 0 38 38" style="enable-background:new 0 0 39 39;" xml:space="preserve">
    <g>
      <g transform="translate(0, 0) scale(0.13, 0.14)" fill="#CD0005" fill-opacity="0.75" stroke="none">
        <path d="M73.306,153.002h-40.5v-16.279h39.055v-12.67H32.807v-16.594h40.5V94.269H19.308c0,24.012,0,48.121,0,72.134h53.995    v-13.401H73.306z"/>
        <polygon points="114.762,139.296 133.205,166.396 148.454,166.396 148.454,165.985 123.312,129.921 147.728,94.889     147.728,94.266 132.795,94.266 114.762,121.164 96.726,94.266 81.783,94.266 81.783,94.889 106.103,129.921 81.064,165.985     81.064,166.396 96.315,166.396   "/>
        <rect x="156.632" y="94.266" width="13.5" height="72.131"/>
        <path d="M204.872,166.396h13.603v-59.765h22.87V94.266c-20.916,0-38.533,0-59.347,0v12.366h22.87v59.765H204.872z"/>
        <path d="M257.152,69.248H3.504C1.566,69.248,0,70.819,0,72.752v115.156c0,1.934,1.566,3.504,3.504,3.504h253.65    c1.938,0,3.505-1.57,3.505-3.504V72.752C260.656,70.819,259.092,69.248,257.152,69.248z M253.647,184.403H7.009V76.256h246.642    v108.147H253.647z"/>
      </g>
    </g>
  </svg>
</ng-template>

