<div class="multi-select-wp">

  <div class='select-query' (click)="showList($event)">
    <input type='text' [id]="'select-field' + index" class='select-field' placeholder="AA 1234" [value]="queryValue" (input)="onChange($event.target.value)">
    <div class="select-arrow">
      <i class="material-icons arrow-icon" [@rotate]="!isShowList">keyboard_arrow_up</i>
    </div>
  </div>

  <div class="list-of-items-wp" *ngIf="isShowList">
     <div class="list-item"
          [ngClass]="{'is_selected': (item.id ? item.id : item) | isExistItemInArray:value}"
          *ngFor="let item of filteredData"
          (click)="toggleItem(item.id ? item.id : item, $event)">
       {{item.id ? (isShowFlightNumbers ? (item.text | formatFlightNumber) : item.text) : (isShowFlightNumbers ? (item | formatFlightNumber) : item)}}
     </div>
  </div>

  <ul class="selected-items-wp" #selectedContainer *ngIf='value.length > 0'>
    <li class="selected-choice-wp"
        *ngFor="let item of value"
        [ngClass]="{'no-exist': !(item | isExistItemInArray:filteredData:'id')}">
      <div class="selected-choice-body">
        {{item.id ? (isShowFlightNumbers ? (item.id | formatFlightNumber) : item.id) : (isShowFlightNumbers ? (item | formatFlightNumber) : item)}}
      </div>
      <div class="selected-choice-cancel"
           (click)="removeItem(item.id ? item.id : item, $event)">
        ×
      </div>
    </li>
  </ul>
</div>
