<div *ngIf="!isModal">
  <div class="mb-4">
    <app-breadcrumbs [items]="breadcrumbs"></app-breadcrumbs>
  </div>

  <h1 class="profile-header d-flex align-items-center gap-20">
    {{form.get('name').value}} {{form.get('surname').value}}
    <i class="material-symbols-outlined remove-icon"
       title="Remove"
       (click)="open(deleteModal)">
      delete
    </i>
  </h1>

  <div class="sentry-mask row">
    <div class="col-lg-12">

      <div [formGroup]="form">
        <ngb-tabset #tabs="ngbTabset" [destroyOnHide]="false">
          <ngb-tab id="account" title="Account">
            <ng-template ngbTabContent>
              <div class="card mt-4" *ngIf="selectedProfile === null">
                <div class="card-body">
                  <div class="form-row">
                    <div class="form-group-sm col-sm-3" (click)="editTravelerViaFormControl(formControlNames.name)">
                      <ng-container [ngTemplateOutlet]="nameControl"></ng-container>
                    </div>

                    <div class="form-group-sm col-sm-3" (click)="editTravelerViaFormControl(formControlNames.surname)">
                      <ng-container [ngTemplateOutlet]="surnameControl"></ng-container>
                    </div>
                  </div>

                  <div class="form-row">
                    <div class="form-group-sm col-lg-3 col-md-5" (click)="editTravelerViaFormControl(formControlNames.email)">
                      <ng-container [ngTemplateOutlet]="emailControl"></ng-container>
                    </div>
                  </div>

                  <div class="form-row">
                    <div class="form-group form-group-sm col-lg-2 col-md-4" (click)="editTravelerViaFormControl(formControlNames.role)">
                      <label><span class="text-primary"><b *ngIf="isEdit">*</b></span> Role</label>
                      <select
                        class="form-control"
                        [formControlName]="formControlNames.role"
                        [validate]="form.get('role').dirty || validateTriggered"
                        [class.border-color-primary]="editableControlName === formControlNames.role"
                      >
                        <option value="manager">Manager</option>
                        <option value="traveler">Traveler</option>
                      </select>
                    </div>
                  </div>

                  <div class="form-row" *ngIf="form.get('role').value == 'manager'">
                    <div class="form-group-sm col-lg-6" (click)="editTravelerViaFormControl(formControlNames.has_travel_profile)">
                      <label class="checkbox custom-checkbox-1 mb-0" for="enable_travel_profile">
                        <input type="checkbox" id="enable_travel_profile" [formControlName]="formControlNames.has_travel_profile"/>
                        <div class="checkbox__checkmark"></div>
                        <div class="checkbox__body">Enable traveler profile</div>
                      </label>
                    </div>
                  </div>
                </div>

                <ng-container *ngTemplateOutlet="cardFooter"></ng-container>
              </div>
            </ng-template>
          </ngb-tab>
          <ngb-tab id="traveler-profile" title="Traveler Profile" *ngIf="form.get('has_travel_profile').value">
            <ng-template ngbTabContent>
              <div class="card mt-4" *ngIf="selectedProfile === null">
                <div class="card-body">
                  <div class="form-row">
                    <div class="form-group-sm col-sm-3" (click)="editTravelerViaFormControl(formControlNames.name)">
                      <ng-container [ngTemplateOutlet]="nameControl"></ng-container>
                    </div>

                    <div class="form-group-sm col-sm-3" (click)="editTravelerViaFormControl(formControlNames.surname)">

                    </div>
                  </div>

                  <div class="form-row">
                    <div class="form-group-sm col-lg-3 col-md-5" (click)="editTravelerViaFormControl(formControlNames.email)">
                      <ng-container [ngTemplateOutlet]="emailControl"></ng-container>
                    </div>
                  </div>

                  <div class="form-row">
                    <div class="col-lg-9">
                      <div class="form-row">
                        <div class="form-group-sm col-lg-2 col-md-4" (click)="editTravelerViaFormControl(formControlNames.title)">
                          <label><span class="text-primary"><b *ngIf="isEdit">*</b></span> Title</label>
                          <select
                            class="form-control"
                            [formControlName]="formControlNames.title"
                            [validate]="form.get('title').dirty || validateTriggered"
                            [class.border-color-primary]="editableControlName === formControlNames.title"
                          >
                            <option value="" hidden>Select title ...</option>
                            <option *ngFor="let title of titles"
                                    [value]="title"
                            >{{title}}
                            </option>
                          </select>
                        </div>
                        <div class="form-group-sm col-lg-2 col-md-4" (click)="editTravelerViaFormControl(formControlNames.gender)">
                          <label><span class="text-primary"><b *ngIf="isEdit">*</b></span> Gender</label>
                          <select
                            class="form-control"
                            [formControlName]="formControlNames.gender"
                            [validate]="form.get('gender').dirty || validateTriggered"
                            [class.border-color-primary]="editableControlName === formControlNames.gender"
                          >
                            <option value="" disabled hidden>Select gender ...</option>
                            <option *ngFor="let gender of genders"
                                    [value]="gender"
                            >{{gender}}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="form-row">
                    <div class="col-lg-9">
                      <div class="form-row">
                        <div class="form-group-sm col-lg-3 col-md-5"
                             (click)="editTravelerViaFormControl(formControlNames.birthdate)"
                             *ngIf="form.get('birthdate') as birthdate">
                          <label><span class="text-primary"><b *ngIf="isEdit">*</b></span>  Date of birth</label>
                          <div class='datepicker-field'>
                            <input
                              appDateInput
                              autocomplete="false"
                              [formControlName]="formControlNames.birthdate"
                              [validate]="birthdate.dirty || validateTriggered"
                              [minMaxDateOfBirth]="minMaxDateOfBirth"
                              ngbDatepicker
                              [minDate]="minMaxDateOfBirth.minDate"
                              [maxDate]="minMaxDateOfBirth.maxDate"
                              #d="ngbDatepicker"
                              (click)="d.close()"
                              (ngModelChange)="onBirthdateChanged($event)"
                              class="form-control"
                              placeholder="DD-MM-YY"
                              [class.border-color-primary]="editableControlName === formControlNames.birthdate"
                            />
                            <button (click)="d.toggle()">
                              <i class="material-icons" style='color: white'>
                                event
                              </i>
                            </button>
                          </div>
                        </div>
                        <div class="form-group-sm col-lg-3 col-md-5"
                             (click)="editTravelerViaFormControl(formControlNames.phone_number)"
                             *ngIf="form.get('phone_number') as phoneNumber">
                          <label>Phone</label>
                          <input
                            (countryChange)="onCountryChange($event)"
                            (intlTelInputObject)="onTelInputObject($event)"
                            [ng2TelInputOptions]="helpers.defaultTelInputOptions"
                            class="form-control"
                            [class.border-color-primary]="editableControlName === formControlNames.phone_number"
                            [formControlName]="formControlNames.phone_number"
                            [validate]="phoneNumber.dirty || validateTriggered"
                            ng2TelInput>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="form-row">
                    <div class="form-group-sm col-lg-2 col-md-3" (click)="editTravelerViaFormControl(formControlNames.address_country_code)">
                      <label>Country</label>
                      <select
                        class="form-control"
                        [formControlName]="formControlNames.address_country_code"
                        [validate]="form.get('address_country_code').dirty || validateTriggered"
                        [class.border-color-primary]="editableControlName === formControlNames.address_country_code"
                      >
                        <option value="" hidden>Select country ...</option>
                        <option *ngFor="let c of (countries | pairs)" [value]="c[0]">{{ c[1] }}
                        </option>
                      </select>
                    </div>

                    <div class="form-group-sm col-lg-2 col-md-3" (click)="editTravelerViaFormControl(formControlNames.address_city_name)">
                      <label>City</label>
                      <input
                        type="text"
                        [formControlName]="formControlNames.address_city_name"
                        [validate]="form.get('address_city_name').dirty || validateTriggered"
                        class="form-control"
                        placeholder="Madrid"
                        [class.border-color-primary]="editableControlName === formControlNames.address_city_name"
                      >
                    </div>

                    <div class="form-group-sm col-lg-2 col-md-3" (click)="editTravelerViaFormControl(formControlNames.address_postal_code)">
                      <label>Postal Code</label>
                      <input
                        type="text"
                        [formControlName]="formControlNames.address_postal_code"
                        [validate]="form.get('address_postal_code').dirty || validateTriggered"
                        class="form-control"
                        placeholder="28001"
                        [class.border-color-primary]="editableControlName === formControlNames.address_postal_code"
                      >
                    </div>

                    <div class="form-group-sm col-lg-2 col-md-3" (click)="editTravelerViaFormControl(formControlNames.address_street)">
                      <label>Street</label>
                      <input
                        type="text"
                        [formControlName]="formControlNames.address_street"
                        [validate]="form.get('address_street').dirty || validateTriggered"
                        class="form-control"
                        placeholder="Gran Via"
                        [class.border-color-primary]="editableControlName === formControlNames.address_street"
                      >
                    </div>
                  </div>
                </div>

                <ng-container *ngTemplateOutlet="cardFooter"></ng-container>
              </div>
            </ng-template>
          </ngb-tab>
          <ngb-tab id="documents" title="Documents" *ngIf="form.get('has_travel_profile').value">
            <ng-template ngbTabContent>
              <div class="card mt-4">
                <div class="card-body">
                  <ng-container formGroupName="documents">
                    <div *ngFor="let control of form.get('documents')['controls']; let idx = index; first as first" [formGroupName]="idx">
                      <div class="form-row mb-3" [class.mt-4]="!first">
                        <div class="d-flex align-items-center gap-20 col-12">
                          <h3 class="mb-0">
                            {{documentTypes[control.get(formControlNames.document_type).value]}}
                          </h3>
                          <i class="material-symbols-outlined remove-icon fs-18"
                             title="Remove"
                             (click)="removeDocument(idx)">
                            delete
                          </i>
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="form-group-sm col-lg-2 col-md-4" (click)="editTravelerViaFormControl(formControlNames.document_id)">
                          <label><span class="text-primary"><b *ngIf="isEdit">*</b></span> Document ID
                          </label>
                          <input
                            type="text"
                            [formControlName]="formControlNames.document_id"
                            [validate]="control.get(formControlNames.document_id).dirty || validateTriggered"
                            class="form-control"
                            placeholder="XXXXXXX"
                          >
                        </div>

                        <div class="form-group-sm col-lg-2 col-md-4"
                             (click)="editTravelerViaFormControl(formControlNames.document_expiration_date)"
                             *ngIf="control.get(formControlNames.document_expiration_date) as expirationDate">
                          <label>Expiration Date</label>
                          <div class='datepicker-field'>
                            <input
                              appDateInput
                              autocomplete="false"
                              [formControlName]="formControlNames.document_expiration_date"
                              [validate]="expirationDate.dirty || validateTriggered"
                              ngbDatepicker
                              #d="ngbDatepicker"
                              (click)="d.close()"
                              (ngModelChange)="onExpirationDateChanged($event, control)"
                              class="form-control"
                              placeholder="DD-MM-YY"
                            />
                            <button (click)="d.toggle()">
                              <i class="material-icons" style='color: white'>
                                event
                              </i>
                            </button>
                          </div>
                        </div>
                      </div>

                      <div class="form-row">
                        <div class="form-group-sm col-lg-2 col-md-3" (click)="editTravelerViaFormControl(formControlNames.document_fiscal_name)">
                          <label>Fiscal Name</label>
                          <input
                            type="text"
                            [formControlName]="formControlNames.document_fiscal_name"
                            [validate]="control.get(formControlNames.document_fiscal_name).dirty || validateTriggered"
                            class="form-control"
                            placeholder="XXXXXXX"
                          >
                        </div>

                        <div class="form-group-sm col-lg-2 col-md-3" (click)="editTravelerViaFormControl(formControlNames.document_issuing_country_code)">
                          <label>Issuing Country Code</label>
                          <ng-select2
                            class="ng-select2-form-control"
                            [validate]="control.get(formControlNames.document_issuing_country_code).dirty || validateTriggered"
                            [formControlName]="formControlNames.document_issuing_country_code"
                            [placeholder]="'Select Issuing Country Code...'"
                            (click)="helpers.setFocusToSelect2SearchField()"
                          >
                            <option value=""></option>
                            <option *ngFor="let c of (countries | pairs)" [value]="c[0]">{{ c[1] }}
                          </ng-select2>
                        </div>

                        <div class="form-group-sm col-lg-2 col-md-3" (click)="editTravelerViaFormControl(formControlNames.document_citizenship_country_code)">
                          <label>Citizenship Country Code</label>
                          <ng-select2
                            class="ng-select2-form-control"
                            [validate]="control.get(formControlNames.document_citizenship_country_code).dirty || validateTriggered"
                            [formControlName]="formControlNames.document_citizenship_country_code"
                            [placeholder]="'Select Citizenship Country Code...'"
                            (click)="helpers.setFocusToSelect2SearchField()"
                          >
                            <option value=""></option>
                            <option *ngFor="let c of (countries | pairs)" [value]="c[0]">{{ c[1] }}
                          </ng-select2>
                        </div>

                        <div class="form-group-sm col-lg-2 col-md-3" (click)="editTravelerViaFormControl(formControlNames.document_residence_country_code)">
                          <label>Residence Country Code</label>
                          <ng-select2
                            class="ng-select2-form-control"
                            [validate]="control.get(formControlNames.document_residence_country_code).dirty || validateTriggered"
                            [formControlName]="formControlNames.document_residence_country_code"
                            [placeholder]="'Select Residence Country Code...'"
                            (click)="helpers.setFocusToSelect2SearchField()"
                          >
                            <option value=""></option>
                            <option *ngFor="let c of (countries | pairs)" [value]="c[0]">{{ c[1] }}
                          </ng-select2>
                        </div>
                      </div>
                    </div>
                  </ng-container>

                  <ng-container *ngTemplateOutlet="documentsDropdown"></ng-container>
                </div>
                <ng-container *ngTemplateOutlet="cardFooter"></ng-container>
              </div>
            </ng-template>
          </ngb-tab>
          <ngb-tab id="frequent-flyer-numbers" title="Frequent Flyer Numbers" *ngIf="form.get('has_travel_profile').value">
            <ng-template ngbTabContent>
              <div class="card mt-4">
                <div class="card-body">
                  <app-frequent-flyer-number
                    [flyerNumbers]="fields"
                    [airlines]="airlines"
                    [showAddedEditedLoader]="showAddedEditedLoaderInFreqentlyNumberForm"
                    [showRemovedLoader]="showRemovedLoaderInFreqentlyNumberForm"
                    (emitAddFrequentNumber)="addNewFrequentFlyerNumber($event)"
                    (emitRemoveFrequentNumber)="deleteFrequentFlyerNumber($event)"
                    (emitEditFrequentNumber)="editFrequentFlyerNumber($event)"
                  ></app-frequent-flyer-number>
                </div>

                <ng-container *ngTemplateOutlet="cardFooter"></ng-container>

              </div>
            </ng-template>
          </ngb-tab>
        </ngb-tabset>

    </div>
  </div>
</div>
</div>


<ng-template #cardFooter>
  <div class="card-footer d-flex justify-content-center gap-12" *ngIf="unsavedChanges || !isEdit">

    <button *ngIf="unsavedChanges"
            class="btn btn-tonal-primary"
      (click)="cancel()">Cancel
    </button>

    <button *ngIf="!isEdit" (click)="editTraveler()" class="btn btn-primary">Edit</button>

    <button *ngIf="unsavedChanges"
            class="btn btn-primary"
            [ngbPopover]="form.invalid && errors && (errors | json) != '{}' ? missingInfo : ''"
            popoverClass="pax-data-popover-wp"
            [autoClose]="'outside'"
            placement="top"
            container="body"
            (click)="save(true)">Save
    </button>

  </div>
</ng-template>

<ng-template #deleteModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h3 class="modal-title">Delete Traveler</h3>
    <button type="button" class="close" aria-label="Close" (click)="d('closed');">
      <span aria-hidden="true">
        <i class="material-icons close-icon float-right ml-3">close</i>
      </span>
    </button>
  </div>
  <div class="modal-body">
    <div class="alert alert-danger">This will delete selected {{profile.role}}. Are you sure you want to proceed?
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-secondary"
      (click)="d('closed')">Cancel
    </button>
    <button
      type="button"
      class="btn btn-primary"
      (click)="deleteProfile();c('ok')">Delete
    </button>
  </div>
</ng-template>

<div class="modal-card card" ngbAutoFocus *ngIf="isModal">
  <div class="card-header">
    <div class="row align-items-center">
      <div class="col">
        <h3 class="card-header-title">New {{form.get('role').value | capitalizeFirstLetter}}</h3>
      </div>
    </div>
  </div>
  <div class="card-body modal-card-body-height">
    <ng-container [formGroup]="form">
      <h3>Account</h3>
      <div class="form-row">
        <div class="form-group-sm col-sm-3">
          <ng-container [ngTemplateOutlet]="nameControl"></ng-container>
        </div>

        <div class="form-group-sm col-sm-3">
          <ng-container [ngTemplateOutlet]="surnameControl"></ng-container>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group-sm col-lg-3 col-md-5">
          <ng-container [ngTemplateOutlet]="emailControl"></ng-container>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group form-group-sm col-lg-2 col-md-4">
          <label><span class="text-primary"><b>*</b></span> Role</label>
          <select
            class="form-control"
            [formControlName]="formControlNames.role"
            [validate]="form.get('role').dirty || validateTriggered"
          >
            <option value="manager">Manager</option>
            <option value="traveler">Traveler</option>
          </select>
        </div>
      </div>

      <div class="form-row" *ngIf="form.get('role').value == 'manager'">
        <div class="form-group form-group-sm col-md-6">
          <div class="custom-control custom-switch mt-2">
            <input type="checkbox" class="custom-control-input" id="has_travel_profile" [formControlName]="formControlNames.has_travel_profile">
            <label class="custom-control-label" for="has_travel_profile">Traveler profile</label>
          </div>
        </div>
      </div>

      <ng-container *ngIf="selectedProfile === null && form.get('has_travel_profile').value">
        <div class="form-row">
          <div class="form-group col-lg-2 col-md-4">
            <label><span class="text-primary"><b>*</b></span> Title</label>
            <select
              class="form-control"
              [formControlName]="formControlNames.title"
              [validate]="form.get('title').dirty || validateTriggered"
            >
              <option value="" hidden>Select title ...</option>
              <option *ngFor="let title of titles"
                      [value]="title"
              >{{title}}
              </option>
            </select>
          </div>
          <div class="form-group col-lg-2 col-md-4">
            <label><span class="text-primary"><b>*</b></span> Gender</label>
            <select
              class="form-control"
              [formControlName]="formControlNames.gender"
              [validate]="form.get('gender').dirty || validateTriggered"
            >
              <option value="" disabled hidden>Select gender ...</option>
              <option *ngFor="let gender of genders"
                      [value]="gender"
              >{{gender}}
              </option>
            </select>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-lg-3 col-md-5"
               *ngIf="form.get('birthdate') as birthdate">
            <label><span class="text-primary"><b>*</b></span>  Date of birth</label>
            <div class='datepicker-field'>
              <input
                appDateInput
                autocomplete="false"
                [formControlName]="formControlNames.birthdate"
                [validate]="birthdate.dirty || validateTriggered"
                [minMaxDateOfBirth]="minMaxDateOfBirth"
                ngbDatepicker
                [minDate]="minMaxDateOfBirth.minDate"
                [maxDate]="minMaxDateOfBirth.maxDate"
                #d="ngbDatepicker"
                (click)="d.close()"
                (ngModelChange)="onBirthdateChanged($event)"
                class="form-control"
                placeholder="DD-MM-YY"
              />
              <button (click)="d.toggle()">
                <i class="material-icons" style='color: white'>
                  event
                </i>
              </button>
            </div>
          </div>
          <div class="form-group col-lg-3 col-md-5"
               *ngIf="form.get('phone_number') as phoneNumber">
            <label>Phone</label>
            <input
              (countryChange)="onCountryChange($event)"
              (intlTelInputObject)="onTelInputObject($event)"
              [ng2TelInputOptions]="helpers.defaultTelInputOptions"
              class="form-control"
              [formControlName]="formControlNames.phone_number"
              [validate]="phoneNumber.dirty || validateTriggered"
              ng2TelInput>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-lg-2 col-md-3">
            <label>Country</label>
            <select
              class="form-control"
              [formControlName]="formControlNames.address_country_code"
              [validate]="form.get('address_country_code').dirty || validateTriggered"
            >
              <option value="" hidden>Select country ...</option>
              <option *ngFor="let c of (countries | pairs)" [value]="c[0]">{{ c[1] }}
              </option>
            </select>
          </div>

          <div class="form-group col-lg-2 col-md-3">
            <label>City</label>
            <input
              type="text"
              [formControlName]="formControlNames.address_city_name"
              [validate]="form.get('address_city_name').dirty || validateTriggered"
              class="form-control"
              placeholder="Madrid"
            >
          </div>

          <div class="form-group col-lg-2 col-md-3">
            <label>Postal Code</label>
            <input
              type="text"
              [formControlName]="formControlNames.address_postal_code"
              [validate]="form.get('address_postal_code').dirty || validateTriggered"
              class="form-control"
              placeholder="28001"
            >
          </div>

          <div class="form-group col-lg-2 col-md-3">
            <label>Street</label>
            <input
              type="text"
              [formControlName]="formControlNames.address_street"
              [validate]="form.get('address_street').dirty || validateTriggered"
              class="form-control"
              placeholder="Gran Via"
            >
          </div>
        </div>

        <ng-container formGroupName="documents">
          <h3>Documents</h3>
          <div *ngFor="let control of form.get('documents')['controls']; let idx = index; first as first" [formGroupName]="idx">
            <div class="form-row mb-3" [class.mt-4]="!first">
              <div class="d-flex align-items-center gap-20 col-12">
                <h4 class="mb-0 gray-color">
                  {{documentTypes[control.get(formControlNames.document_type).value]}}
                </h4>
                <i class="material-symbols-outlined remove-icon fs-18"
                   title="Remove"
                   (click)="removeDocument(idx)">
                  delete
                </i>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-lg-2 col-md-4">
                <label><span class="text-primary"><b>*</b></span> Document ID</label>
                <input
                  type="text"
                  [formControlName]="formControlNames.document_id"
                  [validate]="control.get(formControlNames.document_id).dirty || validateTriggered"
                  class="form-control"
                  placeholder="XXXXXXX"
                >
              </div>

              <div class="form-group col-md-3"
                   *ngIf="control.get(formControlNames.document_expiration_date) as expirationDate"
              >
                <label>Expiration Date</label>
                <div class='datepicker-field'>
                  <input
                    appDateInput
                    autocomplete="false"
                    [formControlName]="formControlNames.document_expiration_date"
                    [validate]="expirationDate.dirty || validateTriggered"
                    ngbDatepicker
                    #d="ngbDatepicker"
                    (click)="d.close()"
                    (ngModelChange)="onExpirationDateChanged($event, control)"
                    class="form-control"
                    placeholder="DD-MM-YY"
                  />
                  <button (click)="d.toggle()">
                    <i class="material-icons" style='color: white'>
                      event
                    </i>
                  </button>
                </div>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-md-3">
                <label>Fiscal Name</label>
                <input
                  type="text"
                  [formControlName]="formControlNames.document_fiscal_name"
                  [validate]="control.get(formControlNames.document_fiscal_name).dirty || validateTriggered"
                  class="form-control"
                  placeholder="XXXXXXX"
                >
              </div>

              <div class="form-group col-md-3"
              >
                <label>Issuing Country Code</label>
                <ng-select2
                  class="ng-select2-form-control"
                  [validate]="control.get(formControlNames.document_issuing_country_code).dirty || validateTriggered"
                  [formControlName]="formControlNames.document_issuing_country_code"
                  [placeholder]="'Select Issuing Country Code...'"
                  (click)="helpers.setFocusToSelect2SearchField()"
                >
                  <option value=""></option>
                  <option *ngFor="let c of (countries | pairs)" [value]="c[0]">{{ c[1] }}
                </ng-select2>
              </div>
              <div class="form-group col-md-3">
                <label>Citizenship Country Code</label>
                <ng-select2
                  class="ng-select2-form-control"
                  [validate]="control.get(formControlNames.document_citizenship_country_code).dirty || validateTriggered"
                  [formControlName]="formControlNames.document_citizenship_country_code"
                  [placeholder]="'Select Citizenship Country Code...'"
                  (click)="helpers.setFocusToSelect2SearchField()"
                >
                  <option value=""></option>
                  <option *ngFor="let c of (countries | pairs)" [value]="c[0]">{{ c[1] }}
                </ng-select2>
              </div>

              <div class="form-group col-md-3">
                <label>Residence Country Code</label>
                <ng-select2
                  class="ng-select2-form-control"
                  [validate]="control.get(formControlNames.document_residence_country_code).dirty || validateTriggered"
                  [formControlName]="formControlNames.document_residence_country_code"
                  [placeholder]="'Select Residence Country Code...'"
                  (click)="helpers.setFocusToSelect2SearchField()"
                >
                  <option value=""></option>
                  <option *ngFor="let c of (countries | pairs)" [value]="c[0]">{{ c[1] }}
                </ng-select2>
              </div>
            </div>

          </div>
        </ng-container>

        <div class="form-row">
          <div class="form-group col-md-6">
            <ng-container *ngTemplateOutlet="documentsDropdown"></ng-container>
          </div>
        </div>

        <app-frequent-flyer-number
          [flyerNumbers]="fields"
          [airlines]="airlines"
          [showAddedEditedLoader]="showAddedEditedLoaderInFreqentlyNumberForm"
          [showRemovedLoader]="showRemovedLoaderInFreqentlyNumberForm"
          [isCardView]="false"
          [validateTriggered]="validateTriggered"
          (emitAddFrequentNumber)="addNewFrequentFlyerNumber($event)"
          (emitRemoveFrequentNumber)="deleteFrequentFlyerNumber($event)"
          (emitEditFrequentNumber)="editFrequentFlyerNumber($event)"
        ></app-frequent-flyer-number>
      </ng-container>
    </ng-container>
  </div>
  <div class="card-footer">
    <div class="btn-wrapper">
      <button class="btn btn-primary float-right ml-3"
              [ngbPopover]="form.invalid && errors && (errors | json) != '{}' ? missingInfo : ''"
              popoverClass="pax-data-popover-wp"
              [autoClose]="'outside'"
              placement="top"
              container="body"
              (click)="save()">
        Add
      </button>
      <button class="btn btn-secondary float-right" (click)="cancel()">
        Cancel
      </button>
    </div>
  </div>
</div>

<ng-template #nameControl>
  <ng-container [formGroup]="form">
    <label><span class="text-primary"><b *ngIf="isEdit">*</b></span> Name / First name</label>
    <input
      type="text"
      [formControlName]="formControlNames.name"
      [validate]="form.get('name').dirty || validateTriggered"
      [value]="form.get('name').value"
      class="form-control"
      placeholder="Alex"
      [class.border-color-primary]="editableControlName === formControlNames.name"
    >
  </ng-container>
</ng-template>

<ng-template #surnameControl>
  <ng-container [formGroup]="form">
    <label><span class="text-primary"><b *ngIf="isEdit">*</b></span> Surname / Last name</label>
    <input
      type="text"
      [formControlName]="formControlNames.surname"
      [value]="form.get('surname').value"
      [validate]="form.get('surname').dirty || validateTriggered"
      class="form-control"
      placeholder="Crow"
      [class.border-color-primary]="editableControlName === formControlNames.surname"
    >
  </ng-container>
</ng-template>

<ng-template #emailControl>
  <ng-container [formGroup]="form">
    <label>Email</label>
    <input
      type="text"
      [formControlName]="formControlNames.email"
      [value]="form.get('email').value"
      [validate]="form.get('email').dirty || validateTriggered"
      [tooltipErrorText]="serverErrors.email"
      class="form-control"
      placeholder="web@example.com"
      [class.border-color-primary]="editableControlName === formControlNames.email"
    >
  </ng-container>
</ng-template>


<ng-template #documentsDropdown>
  <div ngbDropdown [class.mt-4]="form.get('documents')['controls']?.length">
    <button class="d-flex btn btn-outline-primary" ngbDropdownToggle>
      Add document
    </button>
    <div ngbDropdownMenu>
      <div ngbDropdownItem *ngFor="let type of (documentTypes | pairs)" (click)="addDocument(type[0]); editTraveler()">{{ type[1] }}</div>
    </div>
  </div>
</ng-template>

<ng-template #missingInfo>
  <h3 class="popover-header">Missing info in:</h3>
  <ng-container *ngFor="let error of errors | keyvalue | sortPassengerControlNames; first as first">
    <ul>
      <li *ngIf="first">
        {{error.key | camelCaseToTitleCase}}
      </li>
    </ul>
  </ng-container>
</ng-template>
