import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
  HostListener, Output, EventEmitter
} from '@angular/core';
import { CorporateDiscountCodesService } from '../../shared/services/corporate-discount-codes.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HelpersService } from "../../shared/services/helpers.service";
import { VirtualScrollerComponent } from "ngx-virtual-scroller";
import { CommonFareType, FareTypesCategory, PrepareFareTypeInfo } from "../../shared/interfaces/fare-types";
import {OfferService} from "../../shared/services/offer.service";
import {FlightInfo, SimpleOffer} from "../../shared/interfaces/offer";
import {NDCApiService} from "../../shared/services/ndc-api.service";
import {SentryService} from "../../shared/services/sentry.service";
import {Dictionary} from "../../shared/types/dictionary";


@Component({
  selector: 'app-offer-builder',
  templateUrl: './offer-builder.component.html',
  styleUrls: ['./offer-builder.component.scss']
})
export class OfferBuilderComponent implements OnInit, OnChanges {
  @Input() allOffers = [];
  @Input() urlData;
  @Input() searchTypeOption;
  @Input() originDestinations = [];
  @Input() isReshop = false;
  @Input() autoSelectFlightIndex?: number[];
  @Input() allOptions: string[];
  @Input() allowToRemoveOD = true;

  @Output() emitOfferForReshop = new EventEmitter();
  @ViewChild(VirtualScrollerComponent)
  private virtualScroller: VirtualScrollerComponent;

  desireOffer;
  currentOD = 0;
  showOptions = false;
  availableOffers = [];
  showAvailableOffers = [];
  pageSize = 10;
  sortStrategy = 'price_asc';
  simpleOffersModel: SimpleOffer[] = [];
  groupedOffersByProvider = [];
  allGroupedOffers = [];
  selectedLegs = {};
  fareTypeInfo: PrepareFareTypeInfo | null = null;
  fareTypesCategories: FareTypesCategory[] = Dictionary.FareTypeCategories;
  sortStrategyOptions = {
    'price_asc': 'Lowest Price',
    'departure_time_asc': 'Earliest departure',
    'departure_time_desc': 'Latest departure',
    'arrival_asc': 'Earliest arrival',
    'arrival_desc': 'Latest arrival',
    'shorter_time': 'Shorter time',
  };
  groupNames: FareTypesCategory[] = [];
  filteredGroupNames: FareTypesCategory[] = [];
  selectedOffer;
  fareRulesCapacity: any;
  desireOfferFlightInfo: FlightInfo[];
  private ngUnsubscribe$: Subject<void> = new Subject<void>();
  selectedCorporate;
  hasRepricingStarted = false;
  simpleOffersByProvider = [];
  offerExpired = false;
  showLoader = false;
  offerPriceResponseDataByProvider: any = {};

  @HostListener("window:scroll", ["$event"])
  onWindowScroll() {
    let pos = (document.documentElement.scrollTop || document.body.scrollTop) + document.documentElement.offsetHeight;
    let max = document.documentElement.scrollHeight;
    if (pos > (max - 15) && this.availableOffers.length && this.pageSize < this.availableOffers.length)  {
      this.refreshAvailableOffers('scroll');
    }
  }
  constructor(
    private helpers: HelpersService,
    private offerService: OfferService,
    private corporateDiscountCodesService: CorporateDiscountCodesService,
    public service: NDCApiService,
    public sentryService: SentryService
  ) { }

  ngOnInit() {
    if (this.autoSelectFlightIndex?.includes(0)) {
      this.onSelectOD(this.allOffers[0]);
    }
    this.removeODIfGridChanged();
    this.getSelectedCorporate();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.sortAllOffers();
  }

  getSelectedCorporate() {
    let corporate = null;
    this.corporateDiscountCodesService.selectedCorporate$
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((currentCorporate) => corporate = currentCorporate);

    this.selectedCorporate = corporate;
  }

  toggleOptions(show?: boolean) {
    this.showOptions = show || !this.showOptions;

    if (this.showOptions && this.offerService.belongsToUpsellList(this.desireOffer.owner)) {
      if (this.offerPriceResponseDataByProvider[this.desireOffer.owner]?.groupedOffers?.length) {
        this.groupedOffersByProvider = this.offerPriceResponseDataByProvider[this.desireOffer.owner].groupedOffers;
      } else {
        this.sendOfferPrice();
      }
    }
  }

  closeOptions() {
    this.showOptions = false;
  }

  sendOfferPrice() {
    this.showLoader = true;
    let body = {
      offerID: this.desireOffer.offerID,
      type: 'UpsellOptions'
    };
    this.service.sendOfferPrice(body)
      .then((response: any) => {
        this.offerPriceResponseDataByProvider[this.desireOffer.owner] = {};
        this.showLoader = false;
        if (response && response.otherOffers) {
          let otherOffers = response.otherOffers;
          if (otherOffers?.length) {
            if (this.desireOffer.owner !== '1G') {
              otherOffers.unshift(this.desireOffer);
            }
            this.simpleOffersModel = this.offerService.getSimpleModelByOffers(otherOffers);
            this.allGroupedOffers = this.allGroupedOffers.concat(otherOffers);
            this.offerPriceResponseDataByProvider[this.desireOffer.owner].groupedOffers = this.groupedOffersByProvider = otherOffers;
          }
        }
      }).catch((err) => {
      this.showLoader = false;
      this.sentryService.setAdditionalData(this.service.lastSessionID, this.service.lastRequestID, body, err.status, err);
      if (this.helpers.isCriticalError(err)) {
        throw err;
      }
    });
  }

  onSelectOD(offer, $element = null) {
    this.selectedOffer = offer;
    this.desireOffer = {...offer};
    this.desireOfferFlightInfo = this.offerService.getFlightInfo(this.desireOffer);
    this.selectedLegs[this.currentOD] = this.offerService.getODInfo(offer, this.currentOD);
    if (this.currentOD < this.desireOffer.flights.length - 1) {
      this.currentOD++;
      this.sortAllOffers();
    } else {
      this.currentOD++;
      this.setSimpleModelOffers(false);
      const groupNamesSet = [];
      this.groupNames = [];
      this.simpleOffersModel.forEach(offer => {
        if (!groupNamesSet.includes(offer.preparedFareTypeInfo.fareTypesCategory.groupName)) {
          groupNamesSet.push(offer.preparedFareTypeInfo.fareTypesCategory.groupName);
          this.groupNames.push({
            groupName: offer.preparedFareTypeInfo.fareTypesCategory.groupName,
            backgroundColor: offer.preparedFareTypeInfo.fareTypesCategory.backgroundColor
          });
        }
      });
      this.toggleOptions(true);
    }
    if ($element) {
      $element.scrollIntoView({behavior: "smooth"});
    }
    const commonFareType: CommonFareType | null = this.helpers.extractFareType(this.desireOffer);
    this.fareTypeInfo = this.helpers.prepareFareTypesInfo(commonFareType, this.fareTypesCategories);
    this.filteredGroupNames = [];
    this.filteredGroupNames = this.groupNames
      .filter(group => group.groupName !== this.fareTypeInfo?.fareTypesCategory.groupName);

    if (this.desireOffer && this.currentOD === this.desireOffer.flights.length) {
      this.fareRulesCapacity = this.desireOffer.flights[0].segments[0].detail.classOfService.fareRules;
    }

    if (this.autoSelectFlightIndex?.includes(this.currentOD)) {
      this.onSelectOD(this.selectedOffer);
    }
  }

  windowToTop(event) {
    window.scroll({
      behavior: 'smooth',
      top: 0
    });
  }

  setSimpleModelOffers(isFilteredByCabinClass: boolean) {
    if (!this.allGroupedOffers.length) {
      this.allGroupedOffers = this.offerService.getOffersWithSameOD(this.desireOffer, this.allOffers, this.currentOD);
    }
    this.groupedOffersByProvider = this.offerService.filterOffersByProvider(this.desireOffer, this.allGroupedOffers);
    this.simpleOffersByProvider = this.offerService.getLowestPriceOffersByProvider(this.desireOffer, this.allGroupedOffers);

    if (isFilteredByCabinClass) {
      const selectedOfferCabins = this.offerService.extractFlightCabinDesignators(this.selectedOffer);
      this.groupedOffersByProvider = this.groupedOffersByProvider.filter( offer => {
        return selectedOfferCabins === this.offerService.extractFlightCabinDesignators(offer);
      });
    }
    this.simpleOffersModel = this.offerService.getSimpleModelByOffers(this.groupedOffersByProvider);
  }

  refreshAvailableOffers(target: string = null) {
    /** set count of offers for show */
    this.pageSize = target === 'scroll' ? this.pageSize + 10 : 10;

    const uniqueLegs = [];
    this.availableOffers = this.allOffers.filter(offer => {
      const selectedKeys = Object.keys(this.selectedLegs);
      if (selectedKeys.length) {
        const matchValues = [];
        selectedKeys.map( key => {
          const legInfo = this.offerService.getODInfo(offer, key);
          matchValues.push(this.selectedLegs[key] === legInfo);
        });
        const uniqueValues = Array.from(new Set(matchValues));
        if (uniqueValues.length === 1 && uniqueValues[0]) {
          const newSegment = this.offerService.getODInfo(offer, this.currentOD);
          if (!uniqueLegs.includes(newSegment)) {
            uniqueLegs.push(newSegment);
            return true;
          } else {
            return false;
          }
        }
      } else {
        const firstLegInfo = this.offerService.getODInfo(offer, this.currentOD);
        if (uniqueLegs.includes(firstLegInfo)) {
          return false;
        } else {
          uniqueLegs.push(firstLegInfo);
          return true;
        }
      }
    })

    this.showAvailableOffers = this.availableOffers.slice(0, this.pageSize);
  }

  removeOD(i) {
    Object.keys(this.selectedLegs).map(key => {
      if (key >= i) {
        delete this.selectedLegs[key];
      }
    });
    this.currentOD = i;
    if (i === 0) { this.desireOffer = undefined; }
    this.sortAllOffers();
    this.simpleOffersModel = this.allGroupedOffers = [];
    this.offerPriceResponseDataByProvider = {};
    this.closeOptions();
  }

  removeODIfGridChanged() {
    this.offerService.getOfferGridChanges().subscribe(gridChanged => {
      if (gridChanged && this.allowToRemoveOD && Object.keys(this.selectedLegs).length) {
        this.removeOD(0);
      }
    });
  }

  setSelectedOffer({ offerID, isOpenNewWindow }, updateOffers = false) {
    const offer = this.allGroupedOffers.find(offer => offer.offerID === offerID);
    if (!!offer) {
      this.desireOfferFlightInfo = this.offerService.getFlightInfo(offer);
      this.desireOffer = offer;
      this.selectedOffer = offer;
      const commonFareType: CommonFareType | null = this.helpers.extractFareType(this.desireOffer);
      this.fareTypeInfo = this.helpers.prepareFareTypesInfo(commonFareType, this.fareTypesCategories);
      this.filteredGroupNames = this.groupNames
        .filter(group => group.groupName !== this.fareTypeInfo?.fareTypesCategory?.groupName);
    }

    if (this.desireOffer && this.currentOD === this.desireOffer.flights.length) {
      this.fareRulesCapacity = this.desireOffer.flights[0].segments[0].detail.classOfService.fareRules;
    }
    if (!this.isReshop && isOpenNewWindow) {
      const _offerURL = this.helpers.setOfferUrl(this.desireOffer, this.urlData, this.selectedCorporate);

      window.open(_offerURL, "_blank");
    }
    if (this.isReshop && !isOpenNewWindow) {
      this.emitOfferForReshop.emit(offer);
    }

    if (updateOffers) {
      // this.setSimpleModelOffers(false);
      this.groupedOffersByProvider = this.offerService.filterOffersByProvider(this.desireOffer, this.allGroupedOffers);
      this.simpleOffersModel = this.offerService.getSimpleModelByOffers(this.groupedOffersByProvider);
      this.toggleOptions(true);
    }
  }

  changeSortStrategy(event) {
    setTimeout(() => this.sortAllOffers());
  }

  sortAllOffers() {
    const result = this.allOffers.sort((a, b) => {
      switch (this.sortStrategy) {
        case 'price_asc':
          return a.price.consumer.total > b.price.consumer.total ? 1 : -1;
        case 'price_desc':
          return a.price.consumer.total > b.price.consumer.total ? -1 : 1;
        case 'shorter_time':
          return this.helpers.getDurationInMinutes(a, this.currentOD) > this.helpers.getDurationInMinutes(b, this.currentOD) ? 1 : -1;
        case 'longer_time':
          return this.helpers.getDurationInMinutes(a, this.currentOD) > this.helpers.getDurationInMinutes(b, this.currentOD) ? -1 : 1;
        case 'departure_time_asc':
        case 'departure_time_desc':
          if (!a.flights[this.currentOD] || !b.flights[this.currentOD]) {
            return -1;
          }
          const timeA = a.flights[this.currentOD].segments[0].originDestination.departure.time;
          const timeB = b.flights[this.currentOD].segments[0].originDestination.departure.time;
          switch (this.sortStrategy) {
            case 'departure_time_asc':
              return this.timeToNumber(timeA) > this.timeToNumber(timeB) ? 1 : -1;
            case 'departure_time_desc':
              return this.timeToNumber(timeA) > this.timeToNumber(timeB) ? -1 : 1;
          }
        case 'arrival_asc':
        case 'arrival_desc':
          if (!a.flights[this.currentOD] || !b.flights[this.currentOD]) {
            return -1;
          }
          const segmentsA = a.flights[this.currentOD].segments;
          const segmentsB = b.flights[this.currentOD].segments;
          const timeAA = segmentsA[segmentsA.length - 1].originDestination.arrival.time;
          const timeBB = segmentsB[segmentsB.length - 1].originDestination.arrival.time;
          switch (this.sortStrategy) {
            case 'arrival_asc':
              return this.timeToNumber(timeAA) > this.timeToNumber(timeBB) ? 1 : -1;
            case 'arrival_desc':
              return this.timeToNumber(timeAA) > this.timeToNumber(timeBB) ? -1 : 1;
          }
      }
    });
    this.allOffers = [...result];
    this.refreshAvailableOffers();
  }

  private timeToNumber(time: string): number {
    const timeArr = time.split(':');
    return parseInt(timeArr[0]) * 60 + parseInt(timeArr[1]);
  }

  trackByFn(index, item) {
    return item.offerID;
  }

  onSelectedOffer() {
    if (this.isReshop) {
      this.emitOfferForReshop.emit(this.selectedOffer);
    } else {
      const _offerURL = `/offer/${this.selectedOffer.owner}/${this.selectedOffer.offerID}/${this.urlData.travelers}/${this.urlData.od}`;

      window.open(_offerURL, "_blank");
    }
  }

  onOfferExpired(expired) {
    this.offerExpired = expired;
  }

  get consumerPrice() {
    return this.desireOffer.price.consumer;
  }

  get oldTotalPrice() {
    return this.consumerPrice.total + this.consumerPrice.discount.value;
  }

  get isDiscountExists() {
    return !!this.desireOffer.price.consumer.discount?.value;
  }

  get discountPercentage() {
    const discount = ((this.oldTotalPrice - this.consumerPrice.total) / this.oldTotalPrice) * 100;
    return Math.round(discount);
  }
}
