<div class="modal-card card">
  <div class="card-header">
    <div class="row align-items-center">
      <div class="col">
        <h3 class="card-header-title" *ngIf="step === 1 || step === 4">Select Services</h3>
        <h3 class="card-header-title" *ngIf="step === 2">Confirm selected services</h3>
        <h3 class="card-header-title" *ngIf="step === 3">Payment info</h3>
      </div>
      <div class="col-auto">
        <i
          data-dismiss="modal"
          (click)="emitDismiss.emit(true)"
          class="material-icons float-right ml-3 close-icon"
        >close</i>
      </div>
    </div>
  </div>
  <div class="card-body modal-card-body-height" #cardBodyContainer>

    <ng-container *ngIf="step === 1">
      <div class="row" *ngIf="showServiceListLoader">
        <div class="col mt-3">
          <div class="loader-wrapper">
            <app-loader></app-loader>
          </div>
        </div>
      </div>
      <div *ngIf="responseWarnings.length" class="row">
        <div class="col alert-warning m-3 p-3 rounded">
          <ul>
            <li *ngFor="let item of responseWarnings">{{item}}</li>
          </ul>
        </div>
      </div>
      <div *ngIf="serviceListError" class="row">
        <div class="col">
          <error-alert-block
            [error]="serviceListError"
          ></error-alert-block>
        </div>
      </div>
      <ngb-tabset #tabs="ngbTabset" *ngIf="!showServiceListLoader && !serviceListError.message">
        <ngb-tab
          *ngFor="let p of order.passengers | sortPassengers; let ii = index"
          id="{{ p.travelerReference }}"
          title="{{'(' + p.passengerType + ') ' + p.data.name + ' ' + p.data.surname }}">
          <ng-template ngbTabContent>
            <div class="py-3" *ngIf="!servicesPerTraveler[p.travelerReference]">
              No services available
            </div>
            <div class="pl-2" *ngIf="servicesPerTraveler[p.travelerReference]">
              <ngb-tabset #tabs="ngbTabset">
                <ngb-tab *ngIf="servicesPerTraveler[p.travelerReference]?.entireTrip?.length > 0" title="Entire Trip">
                  <ng-template ngbTabContent>
                    <ng-container
                      ngbTabContent
                      [ngTemplateOutlet]="serviceTable"
                      [ngTemplateOutletContext]="{
                        services: servicesPerTraveler[p.travelerReference]?.entireTrip,
                        passengerRef: p.travelerReference
                      }"
                    >
                    </ng-container>
                  </ng-template>
                </ngb-tab>
                <ngb-tab *ngIf="(servicesPerTraveler[p.travelerReference]?.perLeg | keyvalue)?.length > 0" title="Per Leg">
                  <ng-template  ngbTabContent>
                    <ngb-tabset #tabs="ngbTabset">
                      <ngb-tab
                        *ngFor="let route of (servicesPerTraveler[p.travelerReference]?.perLeg | keyvalue) | sortFlightsByRoutes: order.flights"
                        title="{{ route.key.replace('-', ' → ') }}"
                      >
                        <ng-template ngbTabContent>
                          <ng-container
                            [ngTemplateOutlet]="serviceTable"
                            [ngTemplateOutletContext]="{
                              services: route.value,
                              passengerRef: p.travelerReference
                            }"
                          >
                          </ng-container>
                        </ng-template>
                      </ngb-tab>
                    </ngb-tabset>
                  </ng-template>
                </ngb-tab>
                <ngb-tab title="Per Segment" *ngIf="(servicesPerTraveler[p.travelerReference]?.perSegment | keyvalue)?.length > 0">
                  <ng-template  ngbTabContent>
                    <ngb-tabset #tabs="ngbTabset">
                      <ngb-tab
                        *ngFor="let route of (servicesPerTraveler[p.travelerReference]?.perSegment | keyvalue) | sortFlightsByRoutes: segments: false"
                        title="{{ route.key.replace('-', ' → ') }}"
                      >
                        <ng-template ngbTabContent>
                          <ng-container
                            [ngTemplateOutlet]="serviceTable"
                            [ngTemplateOutletContext]="{
                              services: route.value,
                              passengerRef: p.travelerReference
                            }"
                          >
                          </ng-container>
                        </ng-template>
                      </ngb-tab>
                    </ngb-tabset>
                  </ng-template>
                </ngb-tab>
              </ngb-tabset>
            </div>
          </ng-template>
        </ngb-tab>
      </ngb-tabset>
    </ng-container>

    <ng-container *ngIf="step === 2 && selectedServices.length">
      <error-alert-block
        [error]="orderChangeError"
      ></error-alert-block>
      <div class="mb-2">New services:</div>
      <div class="table-responsive">
        <table class="table table-default mb-0">
          <thead>
          <tr>
            <th>Service</th>
            <th>Quantity</th>
            <th>Price</th>
            <th>Passenger</th>
            <th>Flight Segment</th>
            <th>Comment</th>
            <th *ngIf="isActiveOrder()">Action</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let srv of selectedServices">
            <td>{{ srv.name }}</td>
            <td>
              <ng-container *ngIf="srv.ref == 'ExcessBaggage'">
                <input
                  class="form-control"
                  type="number"
                  [min]="srv.minimumQuantity"
                  [max]="srv.maximumQuantity"
                  [(ngModel)]="srv.quantity"/>
              </ng-container>

              <ng-container *ngIf="srv.ref != 'ExcessBaggage'">
                {{ srv.quantity || 1 }}
              </ng-container>
            </td>
            <td>{{ srv | getTotalServicePrice }}</td>
            <td>{{ order.passengers | getPassengerNameByRefPipe:srv.travelerReferences }}</td>
            <td class="whitespace-pre-line">{{ order.flights | getSegmentRouteByIDPipe:srv.segmentReferences }}</td>
            <td>{{ srv.text }}</td>
            <td *ngIf="isActiveOrder()">
              <i class="material-symbols-outlined remove-icon fs-20"
                 title="Remove"
                 (click)="onRemoveService(srv, srv.travelerReferences)">
                delete
              </i>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </ng-container>
    <ng-container *ngIf="step === 3">
      <div class="payment-form-wp">
        <ng-container *ngIf="servicesTotalPrice">
          <div
            class="payment-info__price"
            [class.payment-info__price_primary]="servicesTotalPrice > 0"
            [class.payment-info__price_danger]="servicesTotalPrice < 0"
          >
            <div class="mb-4 changed-price total-amount">
              <span>To be paid:</span> <b>{{ servicesTotalPrice | number :'1.2-2'}}</b> {{ order.price.consumer.currency }}
            </div>
          </div>
        </ng-container>
        <app-payment-form
          [pingProceed]="pingProceed"
          (emitFormData)="onEmitFormData($event)"
          [paymentError]="orderChangeError"
          [paymentWarnings]="orderChangeWarnings"
          [allowedPaymentMethods]="order.allowedPaymentMethods"
          [cardSurchargesMap]="order.cardSurchargesMap"
          [totalPrice]='servicesTotalPrice'
          [logs]="order.logs"
        >
        </app-payment-form>
      </div>
    </ng-container>

    <ng-container *ngIf="step === 4">
      <div class="mb-3" *ngIf="orderChangeWarnings.length">
        <app-show-warnings [warnings]="orderChangeWarnings"></app-show-warnings>
      </div>
      <div class="alert alert-success mb-0">
        Service item has been successfully ordered.
      </div>
    </ng-container>
  </div>

  <div class="card-footer text-right">
    <ng-container *ngIf="step === 1">
      <button
        type="button"
        class="btn btn-secondary mr-2"
        (click)="emitDismiss.emit(true);">Cancel
      </button>
      <button
        type="button"
        class="btn btn-primary"
        [disabled]="!selectedServicesCopy.length"
        (click)="showSelectedServices()">Next
      </button>
    </ng-container>

    <ng-container *ngIf="step === 2">
      <div class="btn mr-2" *ngIf="paymentLoader">
        <app-loader></app-loader>
      </div>
      <button
        type="button"
        class="btn btn-secondary mr-2"
        [disabled]="paymentLoader"
        (click)="setStep(1);">Back
      </button>
      <button
        *ngIf="order.status !== ORDER_STATUS.PENDING && !orderSkipPaymentForSeats"
        type="button"
        class="btn btn-primary"
        (click)="goToPayment()">Payment
      </button>
      <button
        *ngIf="order.status === ORDER_STATUS.PENDING || orderSkipPaymentForSeats"
        type="button"
        class="btn btn-primary"
        [disabled]="paymentLoader"
        (click)="orderChange()">Proceed
      </button>
    </ng-container>

    <ng-container *ngIf="step === 3">
      <div class="btn mr-2" *ngIf="paymentLoader">
        <app-loader></app-loader>
      </div>
      <button
        type="button"
        class="btn btn-secondary mr-2"
        [disabled]="paymentLoader"
        (click)="backToSelectedServices()">Back
      </button>
      <button
        type="button"
        class="btn btn-primary"
        [disabled]="paymentLoader"
        (click)="getFormDataIfValid()">Proceed
      </button>
    </ng-container>

    <ng-container *ngIf="step === 4">
      <button
        type="button"
        class="btn btn-primary mr-2"
        (click)="emitDismiss.emit(true);">OK
      </button>
    </ng-container>
  </div>
</div>

<ng-template #serviceTable let-services="services" let-passengerRef="passengerRef">
  <app-services-table [services]="services"
                      [passengerRef]="passengerRef"
                      [selectedServicesCopy]="selectedServicesCopy"
                      [selectedServicesMapCopy]="selectedServicesMapCopy"
                      [validationErrors]="validationErrors"
                      (emitUpdatedValues)="onEmitUpdatedValues($event)">
  </app-services-table>
</ng-template>

