import {AbstractControl, ValidatorFn} from "@angular/forms";
import {validate} from '@validatecl/rut';
export function DocumentIdValidator(documentType: string = ''): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const documentIdValue = control.value;
    if (documentType === 'RUT') {
      const isValidRut = validate(documentIdValue);

      if (!isValidRut) {
        return { invalidRut: true };
      }
    } else {
      const lettersAndNumberPattern = /^[A-Za-z0-9]*$/;

      if (!lettersAndNumberPattern.test(documentIdValue)) {
        return { pattern: true };
      }
    }

    return null;
  };
}
