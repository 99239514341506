<div class="breadcrumb">
  <ng-container *ngFor="let item of items; last as last">
    <a
      *ngIf="item.url && item.label"
      (click)="go(item.url, item.queryParams)"
      [class.active]="last && !item.hideActive"
    >{{item.label}}
    </a>
    <a
      *ngIf="!item.url && item.label"
      (click)="item.backToPrevious ? location.back() : $event.stopPropagation();"
      [class.active]="last && !item.hideActive"
    >{{item.label}}
    </a>
  </ng-container>
</div>
