import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Corporate} from "../../shared/types/corporate";
import {Observable} from "rxjs/internal/Observable";
import {map, takeUntil} from "rxjs/operators";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {MetaResponse, RESPONSE_OK} from "../../shared/types/system";
import {Subject} from "rxjs";
import {HubCorporateService} from "../../shared/services/hub-corporate.service";
import {NotificationService} from "../../shared/services/notification.service";
import {HelpersService} from "../../shared/services/helpers.service";

@Component({
  selector: 'app-add-or-edit-corporate',
  templateUrl: './add-or-edit-corporate.component.html',
  styleUrls: ['./add-or-edit-corporate.component.scss']
})
export class AddOrEditCorporateComponent implements OnInit {

  @Input() corporate: Corporate = new Corporate();
  @Input() isNewRecord = false;
  @Input() isModal = true;
  @Output() emitClose = new EventEmitter();
  @Output() emitDismiss = new EventEmitter();
  @Output() emitSaveCorporate = new EventEmitter<boolean>();

  private ngUnsubscribe$: Subject<void> = new Subject<void>();

  form: FormGroup;
  invalidForm: Observable<boolean>;
  initialFormValue;
  initialDomains;
  unsavedChanges = false;
  domainPattern = /[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+/;
  errors: any = {};


  constructor(private fb: FormBuilder,
              public modalService: NgbModal,
              private hubCorporateService: HubCorporateService,
              private _notificationSvc: NotificationService,
              private helpers: HelpersService
  ) { }

  ngOnInit(): void {
    this.buildForm();
    this.setExistingDomainsToForm();
    this.invalidForm = this.form.statusChanges
      .pipe(
        map((status: string) => status === 'INVALID'),
      );
    this.initialDomains = [...(this.corporate?.domains || [])];
    this.detectUnsavedChanges();
  }

  private buildForm() {
    this.form = this.fb.group({
      name: [this.corporate?.name || '', Validators.required],
      account_number: [this.corporate?.account_number || '', Validators.required],
      domains: this.fb.array([this.buildDomain()])
    });
  }

  setExistingDomainsToForm() {
    if (this.corporate?.domains?.length) {
      let formArray = this.form.get('domains') as FormArray;
      this.corporate.domains.map((domain: string, idx: number) => {
        const control = this.buildDomain();
        formArray.push(control);
        formArray.at(idx).get('domain').setValue(domain);
      });
    }
  }

  buildDomain() {
    return this.fb.group({
      domain: ['', Validators.pattern(this.domainPattern)]
    });
  }

  addDomain(addedControl: FormControl) {
    const control = this.buildDomain();
    (this.form.get('domains') as FormArray).push(control);
    this.corporate.domains.push(addedControl.value.domain);
  }

  removeDomain(index: number) {
    const arrayControl = this.form.get('domains') as FormArray;
    arrayControl.removeAt(index);
    this.corporate.domains.splice(index, 1);
  }

  detectUnsavedChanges() {
    this.initialFormValue = this.form.value;
    this.form.valueChanges.subscribe((currentValue) => {
      this.unsavedChanges = JSON.stringify(currentValue) !== JSON.stringify(this.initialFormValue);
    });
  }

  open(content, size: any = 'lg') {
    this.modalService.open(content, {
      size
    })
      .result.then((result) => {
    }, (reason) => {
    });
  }

  save() {
    this.populateModel();
    this.errors = {};
    if (this.isNewRecord) {
      this.createCorporate();
    } else {
      this.updateCorporate();
    }
  }

  populateModel() {
    const data = this.form.value;
    const applyObject = {
      name: data.name,
      account_number: data.account_number,
      active: data.active,
      domains: this.corporate.domains
    };

    this.corporate = Object.assign(this.corporate, applyObject);
  }

  createCorporate() {
    this.hubCorporateService.create(this.corporate)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((response: any) => {
        let res = response.body;
        if (res.status === RESPONSE_OK) {
          this.corporate.id = (<MetaResponse>res).meta;
          this.helpers.go(`/corporates/${this.corporate.id}`);
          this.successSave('Corporate has been successfully added');
          this.unsavedChanges = false;
          this.emitClose.emit();
        } else {
          this.errors = res;
        }
      });
  }

  updateCorporate() {
    this.hubCorporateService.update(this.corporate)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((response: any) => {
        let res = response.body;
        if (res.status === RESPONSE_OK) {
          this.successSave('Corporate details has been successfully saved');
          this.unsavedChanges = false;
          this.emitSaveCorporate.emit(true);
          this.emitClose.emit();
          this.form.markAsPristine();
          this.detectUnsavedChanges();
        } else {
          this.errors = res.error;
        }
      });
  }

  cancel() {
    this.corporate.domains = [...this.initialDomains];
    this.setExistingDomainsToForm();
    this.form.reset(this.initialFormValue);
    this.form.markAsPristine();
    this.unsavedChanges = false;
  }

  successSave(notificationMessage) {
    this._notificationSvc.clearNotifications();
    this._notificationSvc.success('SUCCESS', notificationMessage, 0);
  }

  get hasUnsavedDomains() {
    const arrayControl = this.form.get('domains') as FormArray;
    const lastIdx = arrayControl.controls.length - 1;
    let item = arrayControl.at(lastIdx);

    return !!item.value.domain;
  }
}
