<div class="leg-wp">
  <div class="flight-header">
    <div class="header-logo">
      <div class="img-item mr-4" *ngFor="let sOfferByProvider of simpleOffersByProvider;">
        <div [ngStyle]="{'cursor': simpleOffersByProvider.length > 1 ? 'pointer' : ''}"
          [class.selected-provider]="simpleOffersByProvider.length > 1 && offer.owner === sOfferByProvider.owner"
          [class.unselected-provider]="simpleOffersByProvider.length > 1 && offer.owner !== sOfferByProvider.owner"
          (click)="setSelectedOffer(sOfferByProvider.offerID, true)">
          <app-airline-img [class.highlight-img]="simpleOffersByProvider.length > 1 "
            [airlineID]="sOfferByProvider.owner"
            [title]="sOfferByProvider.owner | getImageDescription: sOfferByProvider.flights"></app-airline-img>
        </div>
      </div>
    </div>
    <div class="header-price">
      <div [class.mr-3]="currentOD === destinationCount - 1">
        <app-fare-type class="fare-types" [fareTypeCategories]="filteredGroupNames" [mainFareType]="fareTypeInfo">
        </app-fare-type>
      </div>
      <ng-container *ngIf="currentOD === destinationCount - 1">
        <span class="price-range">
          {{offer.price?.consumer?.total | number :'1.2-2'}}
        </span>
        <span class="price-currency">{{ offer.price?.consumer?.currency}}</span>
      </ng-container>
    </div>
  </div>
  <div class="flight-info-wp">
    <app-flight-info [flightInfo]="flightInfos[currentOD]" [showBaggage]="false" [showRow]="showRow">
    </app-flight-info>
  </div>

  <div class="flight-footer">
    <!-- Cabin classes dropdown menu -->
    <div class="multi-select-container">
      <div class="d-flex gap-20">
        <div ngbDropdown class="multi-select" #classesDropdown="ngbDropdown">
          <div class="dropdown-label">Cabin</div>
          <ng-container *ngIf="cabinClassList.length > 1; else oneCabinClass">
            <button ngbDropdownToggle class="btn btn-outline multi-select-field">
              <span class="cabin-name">
                {{currentCabinClass.cabinName}}
              </span>
            </button>
          </ng-container>
          <ng-template #oneCabinClass>
            <button class="btn btn-outline one-item-container">
              <span class="cabin-name">
                {{currentCabinClass.cabinName}}
              </span>
            </button>
          </ng-template>
          <div ngbDropdownMenu class="multi-select-menu">
            <button [ngClass]="{'bottom-line' : !lastItem}"
              *ngFor="let cabinClass of cabinClassList; i as index, last as lastItem" ngbDropdownItem
              class="multi-select-item" (click)="onSelectClass(cabinClass); classesDropdown.close()">
              <span class="cabin-name">
                {{cabinClass.cabinName}}
              </span>
              <hr>
            </button>
          </div>
        </div>

        <!-- Cabin fares dropdown menu -->
        <div ngbDropdown class="multi-select" #faresDropdown="ngbDropdown">
          <div class="dropdown-label">Fare</div>
          <ng-container *ngIf="fareList.length > 1; else oneCabinFare">
            <button ngbDropdownToggle class="btn btn-outline multi-select-field">
              <div class="badge option-fare-info" *ngIf="currentCabinFare.prepareFareTypeInfo"
                [ngStyle]="{ 'background-color': currentCabinFare.prepareFareTypeInfo.fareTypesCategory.backgroundColor }">
                {{ currentCabinFare.prepareFareTypeInfo.fareTypesCategory.groupName }}
              </div>
              <div class="airline-info-group">
                <span class="airline-name">
                  {{ currentCabinFare.airlineNamesPerLeg }}
                </span>
              </div>
            </button>
          </ng-container>
          <ng-template #oneCabinFare>
            <button class="btn btn-outline one-item-container">
              <div class="badge option-fare-info" *ngIf="currentCabinFare.prepareFareTypeInfo"
                [ngStyle]="{ 'background-color': currentCabinFare.prepareFareTypeInfo.fareTypesCategory.backgroundColor }">
                {{ currentCabinFare.prepareFareTypeInfo.fareTypesCategory.groupName }}
              </div>
              <div class="airline-info-group">
                <span class="airline-name">
                  {{ currentCabinFare.airlineNamesPerLeg }}
                </span>
              </div>
              <div style="flex: 1;"></div>
            </button>
          </ng-template>
          <div ngbDropdownMenu class="multi-select-menu">
            <button [ngClass]="{'bottom-line' : !lastItem}" class="multi-select-item"
              *ngFor="let fare of fareList; last as lastItem" ngbDropdownItem
              (click)="onSelectFare(fare); faresDropdown.close()">
              <div class="badge option-fare-info" *ngIf="fare.prepareFareTypeInfo"
                [ngStyle]="{ 'background-color': fare.prepareFareTypeInfo.fareTypesCategory.backgroundColor }">
                {{ fare.prepareFareTypeInfo.fareTypesCategory.groupName }}
              </div>
              <div class="airline-info-group">
                <span class="airline-name">
                  {{ fare.airlineNamesPerLeg }}
                </span>
              </div>
              <ng-container *ngIf="fare.baggageAllowance">
                <app-baggage-allowance-info class="baggage-info" [baggageAllowance]="fare.baggageAllowance"
                  [passengers]="fare.passengersData" [iconSize]="32">
                </app-baggage-allowance-info>
              </ng-container>
              <ng-container *ngIf="fare.fareRulesCapacity?.length">
                <app-fare-rules-capacity-info
                  [mode]="{simple: true}"
                  [fareRulesCapacity]="fare.fareRulesCapacity">
                </app-fare-rules-capacity-info>
              </ng-container>
            </button>
          </div>
        </div>
      </div>

      <div class="d-flex gap-20">
        <ng-container *ngIf="fareRulesCapacity?.length">
          <app-fare-rules-capacity-info [mode]="{perLeg: true}" [fareRulesCapacity]="fareRulesCapacity">
          </app-fare-rules-capacity-info>
        </ng-container>
        <ng-container *ngIf="currentCabinFare?.baggageAllowance">
          <app-baggage-allowance-info [baggageAllowance]="currentCabinFare.baggageAllowance"
            [passengers]="currentCabinFare.passengersData"></app-baggage-allowance-info>
        </ng-container>
      </div>
    </div>
    <button class="btn btn-primary select-button" (click)="onSelectOD(offer)">
      Select
    </button>
  </div>
</div>
