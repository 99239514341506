<app-input-dropdown
  [placeholder]="searchPlaceholder"
  [listData]="listData"
  [isDisabled]="isDisabled"
  [inputValue]="inputValue"
  [noFocus]="noInputFocus"
  [isValid]="isValid"
  [isInvalid]="isInvalid"
  (emitSearchString)="onEmitSelectedProfile($event)"
  (emitSelectedItem)="onSelectedItem($event)"
></app-input-dropdown>
