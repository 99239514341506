<div class="row" *ngIf="!templates">
  <div class="col m-3 p-3 pt-0 remark-text">
    <div *ngIf="!isEditing" class="text-area-mask" [innerHTML]="remark.result || 'Type your remark'"></div>
    <textarea  *ngIf="isEditing"
               [(ngModel)]="textAreaValue"
               placeholder="Type your remark"
               rows="12"
               #textAreaElement
               autofocus>

    </textarea>
    <button class="btn-edit btn btn-primary float-right" *ngIf="!isEditing" (click)="updateExistingComment()">Edit</button>
    <button *ngIf="isEditing" class="btn btn-primary mt-1 float-right" (click)="sendRemark()">Update</button>
  </div>
</div>

<div class="add-remark-wp" *ngIf="templates">
  <app-remarks-template *ngIf="orderInfo.status === 'Pending' || remark.result.length ; else remarkStub"
    [remark]="remark"
    [templates]="templates"
    [orderInfo] = "orderInfo"
    [isButtonVisible]="isButtonVisible"
    [mandatoryRemarks]="mandatoryRemarks"
    [isRemarksAfterOrderCreation]="isRemarksAfterOrderCreation"
    [validateTriggered]="validateTriggered"
    (emitTemplateSelected)='selectedTemplate($event)'
    (emitUpdatedRemark)="updatedRemark($event)"
  >
  </app-remarks-template>
  <ng-template #remarkStub>
    <div class='remarks-stumb'>Remarks template has to be filled before ticketing order!</div>
  </ng-template>
  <div class="d-flex justify-content-center flex-row-reverse" *ngIf="orderInfo.status === 'Pending'">
    <button *ngIf="isButtonVisible.save" class="btn btn-tonal-primary ml-3" (click)="sendRemark()">Save</button>
    <button *ngIf="isButtonVisible.edit" class="btn btn-outline-primary" (click)="enableForm()">Edit</button>
    <button *ngIf="isButtonVisible.cancel"
            class="btn btn-secondary"
            (click)="cancelChanges()"
            [disabled]="isCancelButtonDisabled"
    >
      Cancel
    </button>
<!--    <button *ngIf="isButtonVisible.reset"-->
<!--            class="btn btn-secondary"-->
<!--            (click)="openReset(resetModal)"-->
<!--            [disabled]="isResetDisabled"-->
<!--    >-->
<!--      Reset-->
<!--    </button>-->
  </div>
</div>

<ng-template #resetModal let-c="close" let-d="dismiss">
  <div class="modal-row">This action will delete all saved remarks.<br>Are you sure?</div>
  <div class='modal-row'>
    <button style="display: none"></button>
    <button class="btn btn-secondary mt-1" (click)=" d('close')">Close</button>
    <button class="btn btn-primary mt-1" (click)="resetForm(); c('closed')">Proceed</button>
  </div>
</ng-template>


