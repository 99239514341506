import {ErrorHandler, Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {LocalStorageService} from '../services/local-storage.service';
import * as Sentry from "@sentry/browser";
import {SentryService} from '../services/sentry.service';
import { NDCApiService } from "../services/ndc-api.service";


class CustomHttpError extends Error {
  readonly operationsWithoutOwner = ['OrderList', 'ProviderList'];

  constructor(msg: string, status: string, response: string, operation: string, owner: string) {
    super(msg);

    const ownerPart = owner && !this.operationsWithoutOwner.includes(operation) && !operation.includes('/')
      ? ` [${owner}]`
      : '';
    this.name = `[BOOKINGPAD]${ownerPart} [${operation}] HttpError ${status}: ${response || this.message}`;

    Object.setPrototypeOf(this, CustomHttpError.prototype);
  }
}

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  lastError = '';
  customHttpError = {};

  constructor(
    private ls: LocalStorageService,
    private sentryService: SentryService,
    private ndcApiService: NDCApiService
  ) {}

  handleError(error) {
    const errorObj = this.extractErrorObject(error);
    const operation = this.getOperationFromError(errorObj);

    if (errorObj.name === 'HttpErrorResponse') {
      this.customHttpError = new CustomHttpError(
        JSON.stringify({
          httpCode: errorObj.status,
          headers: errorObj.headers,
          response: errorObj.error,
          httpError: errorObj.message,
        }),
        errorObj.status,
        errorObj.error?.detail || '',
        operation,
        this.sentryService.owner
      );
    }

    if (this.lastError !== error.stack) {
      error.stack = (this.ls.sessionID ? '[SessionID: ' + this.ls.sessionID + '] ' + "\n" : '') + error.stack;
      error.name = '[BOOKINGPAD] ' + error.name;
      this.lastError = error.stack;
      if (environment.name !== 'dev') {
        const errToReport = this.getErrorToReport(error);
        this.captureErrorWithSentry(errToReport);
      }
    }

    this.resetLastErrorAfterTimeout();

    // IMPORTANT: Rethrow the error otherwise it gets swallowed
    if (Object.keys(this.customHttpError).length === 0) {
      throw error;
    }
  }

  private extractErrorObject(error: any): any {
    return Object.values(error)[0] && Object.values(error)[0]['name'] ? Object.values(error)[0] : error || {};
  }

  private getOperationFromError(errorObj: any): string {
    let operation = errorObj.url?.split(`${environment.ndcApiEndpoint}`).pop().split('?').shift();
    return operation?.replace(/^v\d+\.\d+\//, '') || '';
  }

  private getErrorToReport(error: any): any {
    return this.customHttpError instanceof CustomHttpError ? this.customHttpError : error.originalError || error.error || error;
  }

  private resetLastErrorAfterTimeout(): void {
    setTimeout(() => {
      this.lastError = '';
    }, 1000);
  }

  private setSentryScope(scope: any): void {
    // Set tags
    scope.setTag('consumer', this.ls.consumer || 'unknown');
    scope.setTag('agency', this.ls.agency || 'unknown');

    if (this.sentryService.owner) {
      scope.setTag('provider', this.sentryService.owner);
    }

    // Set request_id and session_id in context
    const requestContext = {
      request_id: this.ndcApiService.lastRequestID || 'unknown',
      session_id: this.ndcApiService.lastSessionID || 'unknown'
    };
    scope.setContext('request_info', requestContext);

    // Set user information
    scope.setUser({ username: this.ls.email || 'unknown' });

    // Set extra data
    scope.setExtra('CUSTOM DATA', JSON.stringify(this.sentryService.structuredContext));
    scope.setExtra('INDEXEDDB DATA', JSON.stringify(this.sentryService.lastIndexedDbDataToBeAdded));
  }

  private captureErrorWithSentry(err: any): void {
    Sentry.captureException(err, scope => {
      this.setSentryScope(scope);
      return scope;
    });
  }
}
