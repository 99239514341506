<ng-container *ngIf="offersClasses.length && showGrid">
  <div class="codeshares-wp filters-container mt-1" *ngIf="isOfferFound">
    <div class="codeshares-wp">
      <ng-content></ng-content>

      <div class="custom-dropdown baggage-dropdown" ngbDropdown #baggageDropdown="ngbDropdown">
        <button class="dropdown-button" ngbDropdownToggle>
          Checked Baggage<ng-container *ngIf="baggageFilterValue">: {{ baggageFilterValue }}</ng-container>
        </button>
        <div ngbDropdownMenu>
          <a ngbDropdownItem (click) = "onChangeBaggageFilter(''); baggageDropdown.close()">Any</a>
          <a ngbDropdownItem (click) = "onChangeBaggageFilter('Included'); baggageDropdown.close()">Included</a>
          <a ngbDropdownItem (click) = "onChangeBaggageFilter('NotIncluded'); baggageDropdown.close()">Not Included</a>
        </div>
      </div>
      <app-fare-rules-filter [label] = "changeRulesLabel" [selectedOption]="fareRules.value.change" (onOptionSelected) = "onSelectedFare($event, 'change')"></app-fare-rules-filter>
      <app-fare-rules-filter [label] = "cancelRulesLabel" [selectedOption]="fareRules.value.cancel" (onOptionSelected) = "onSelectedFare($event, 'cancel')"></app-fare-rules-filter>
    </div>
    <div class="codeshares-wp">
      <label class="checkbox custom-checkbox-1 mb-0" for="codeshares" *ngIf="options.codeshares">
        <input type="checkbox" id="codeshares" [(ngModel)]="model.hideCodeshares" (change)="changeShowCodeshares()"/>
        <div class="checkbox__checkmark"></div>
        <div class="checkbox__body">Hide Codeshares</div>
      </label>
    </div>
  </div>
  <div class="offers-grid mt-5" *ngIf="isOfferFound">
    <div class="d-flex justify-content-between mb-3">
      <div class="d-flex align-items-center">
        <h3 class="mb-0">Overview table</h3>
        <button class="btn toggle-btn" (click)="toggleTable()">{{isTableVisible ? 'Hide' : 'Show'}}</button>
      </div>
      <div class="d-flex align-items-center gap-30" [@collapse]="!isTableVisible">
        <div class="form-inline gap-10">
          <div>Group by: </div>
          <div class="switcher btn-group" role="group" aria-label="Button switcher">
            <button
              *ngFor="let option of sortOptions | pairs; let index = index;"
              type="button"
              class="btn py-2"
              [ngClass]="sortOptions[model.gridType] === option[1] ? 'active' : 'default'"
              (click)="setSortValue(option[0]);"
            >
              {{ option[1] }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class='table-container' [@collapse]="!isTableVisible">
      <div class="table-wrapper mb-3">
        <table class="table main-overview-table table-bordered text-center mb-0"
               [style.table-layout]="model.gridType === 'price' ? 'fixed' : ''"
               *ngIf="offers.length">
          <thead>
          <tr class="light-bg fw-500">
            <td class="empty-td"></td>
            <td *ngFor="let cls of (model.gridType === 'class' ? allCodes : offersClasses);"
                [ngClass]="{'selected': (model.cabins | containItemInArray:(model.gridType === 'price') ? (cabinTypes | getKeyByValueFormObj:cls) : cls) && !model.selectAll}"
                hover-class="priority-bg"
                (click)="checkbox.checked = !checkbox.checked; selectCabin(cls, checkbox.checked, $event)">
              <label class="checkbox custom-checkbox-1 mb-0 pl-0" [for]="cls" (click)="$event.preventDefault()">
                <input type="checkbox"
                       [id]="cls"
                       [checked]="(model.cabins | containItemInArray:(model.gridType === 'price') ? (cabinTypes | getKeyByValueFormObj:cls) : cls) && !model.selectAll"
                       #checkbox/>
                <div class="checkbox__checkmark d-none"></div>
                <div class="checkbox__body">{{ cls ? cls : '(no class)' }}</div>
              </label>
            </td>
          </tr>
          </thead>
          <tbody>

          <ng-container
            *ngTemplateOutlet="model.gridType === 'price' ? groupByCabinGrid : groupByClassGrid">
          </ng-container>

          <ng-template #groupByCabinGrid>
            <ng-container *ngFor="let provider of offersGridProviderOrder;">
              <tr *ngIf="!!offersGrid[provider]">
                <td class="light-bg fw-500"
                    [ngClass]="{'selected': (model.providers | containItemInArray:provider) && !model.selectAll}"
                    hover-class="priority-bg"
                    (click)="checkbox.checked = !checkbox.checked; selectProvider(provider, checkbox.checked, $event)">
                  <label class="checkbox custom-checkbox-1 mb-0 pl-0" [for]="provider" (click)="$event.preventDefault()">
                    <input type="checkbox" [id]="provider" [checked]="(model.providers | containItemInArray:provider) && !model.selectAll" #checkbox/>
                    <div class="checkbox__checkmark d-none"></div>
                    <div class="checkbox__body">{{ provider | getProviderName }}</div>
                  </label>
                </td>
                <td *ngFor="let cls of offersClasses;"
                    [ngbPopover]="offersGrid[provider] && !!offersGrid[provider][cls] && offersGrid[provider][cls].count ? offersAndItinerariesPopover : null"
                    popoverClass="offers-and-itineraries-popover group-by-cabin"
                    [triggers]="'hover'"
                    placement="right"
                    container="body"
                    openDelay="300"
                    [hover-class]="!offersGrid[provider] || !offersGrid[provider][cls] || !offersGrid[provider][cls].count ? 'empty-cell' : 'priority-bg'"
                    [ngClass]="{
                    'selected': !model.providers.length && !model.cabins.length &&
                    (model.groups | containItemInArray:provider+'-'+(cabinTypes | getKeyByValueFormObj:cls)),
                    'secondary-bg': !model.selectAll && (model.groups | containItemInArray:provider+'-'+(cabinTypes | getKeyByValueFormObj:cls))
                    }"
                    (click)="!offersGrid[provider] || !offersGrid[provider][cls] || !offersGrid[provider][cls].count ? null : selectOffers($event, provider, cls)">
                  <label class="checkbox custom-checkbox-1 mb-0 pl-0" [for]="provider + cls" (click)="$event.preventDefault()">
                    <input type="checkbox" [id]="provider + cls" [checked]="(model.groups | containItemInArray:provider+'-'+(cabinTypes | getKeyByValueFormObj:cls)) && !model.selectAll"/>
                    <div class="checkbox__checkmark d-none"></div>
                    <div class="checkbox__body">
                      <div class="d-flex flex-column align-items-center" *ngIf="offersGrid[provider] && !!offersGrid[provider][cls] && offersGrid[provider][cls].count">
                        <div class="d-flex justify-content-center gap-5" style="min-width: 75px">
                          <span class="fw-500 price">
                            {{ (offersGrid[provider] ?
                            (offersGrid[provider][cls].minPrice !== MAX_VALUE_OF_NUMBER ?
                              offersGrid[provider][cls].minPrice : 0) : 0) }}
                          </span>
                          <span class="currency">
                            {{offersGrid[provider][cls].currency}}
                          </span>
                        </div>
                      </div>

                      <div *ngIf="(!offersGrid[provider] || !offersGrid[provider][cls] || !offersGrid[provider][cls].count)">
                        -
                      </div>
                    </div>
                  </label>
                  <ng-template #offersAndItinerariesPopover>
                    <ng-container
                      [ngTemplateOutlet]="offersAndItinerariesTemplate"
                      [ngTemplateOutletContext]="{offersGridObj: offersGrid[provider][cls]}">
                    </ng-container>
                  </ng-template>
                </td>
              </tr>
            </ng-container>
          </ng-template>
          <ng-template #groupByClassGrid>
            <ng-container *ngFor="let provider of offersGridProviderOrder;">
              <tr *ngIf="!!dataForGridByClass[provider]">
                <td class="light-bg fw-500"
                    [ngClass]="{'selected': (model.providers | containItemInArray:provider) && !model.selectAll}"
                    hover-class="priority-bg"
                    (click)="checkbox.checked = !checkbox.checked; selectProvider(provider, checkbox.checked, $event)">
                  <label class="checkbox custom-checkbox-1 mb-0 pl-0" [for]="provider" (click)="$event.preventDefault()">
                    <input type="checkbox" [id]="provider" [checked]="(model.providers | containItemInArray:provider) && !model.selectAll" #checkbox/>
                    <div class="checkbox__checkmark d-none"></div>
                    <div class="checkbox__body">{{ provider | getProviderName }}</div>
                  </label>
                </td>
                <td *ngFor="let cls of allCodes;"
                    class="wider"
                    [ngbPopover]="dataForGridByClass[provider] && dataForGridByClass[provider][cls] && dataForGridByClass[provider][cls].count ? offersAndItinerariesPopover : null"
                    popoverClass="offers-and-itineraries-popover group-by-class"
                    [triggers]="'hover'"
                    placement="right"
                    container="body"
                    openDelay="300"
                    [hover-class]="!dataForGridByClass[provider] || !dataForGridByClass[provider][cls] || !dataForGridByClass[provider][cls].count ? 'empty-cell' : 'priority-bg'"
                    [ngClass]="{
                    'selected': !model.cabins.length && !model.providers.length && (model.groups | containItemInArray:provider+'-' + cls),
                    'secondary-bg': !model.selectAll && ((model.groups | containItemInArray:provider+'-' + cls) || ((model.providers | containItemInArray: provider) && !dataForGridByClass[provider][cls]))
                    }"
                    (click)="(!dataForGridByClass[provider] || !dataForGridByClass[provider][cls] || !dataForGridByClass[provider][cls].count) ? null : selectOffers($event, provider, cls)">
                  <label class="checkbox custom-checkbox-1 mb-0 pl-0" [for]="provider + cls" (click)="$event.preventDefault()">
                    <input type="checkbox" [id]="provider + cls" [checked]="(model.groups | containItemInArray:provider+'-' + cls) && !model.selectAll"/>
                    <div class="checkbox__checkmark d-none"></div>
                    <div class="checkbox__body">
                      <div class="d-flex flex-column align-items-center" *ngIf="dataForGridByClass[provider] && dataForGridByClass[provider][cls] && dataForGridByClass[provider][cls].count">
                        <div class="d-flex gap-5 justify-content-center" style="min-width: 75px">
                          <span class="fw-500 price">
                            {{ (dataForGridByClass[provider] ?
                            (dataForGridByClass[provider][cls].minPrice !== MAX_VALUE_OF_NUMBER ?
                              dataForGridByClass[provider][cls].minPrice : 0) : 0) }}
                          </span>
                          <span class="currency">
                            {{dataForGridByClass[provider][cls].currency || ls.settings?.currency}}
                          </span>
                        </div>
                      </div>

                      <div *ngIf="(!dataForGridByClass[provider] || !dataForGridByClass[provider][cls] || !dataForGridByClass[provider][cls].count)">
                        -
                      </div>
                    </div>
                  </label>
                  <ng-template #offersAndItinerariesPopover>
                    <ng-container
                      [ngTemplateOutlet]="offersAndItinerariesTemplate"
                      [ngTemplateOutletContext]="{offersGridObj: dataForGridByClass[provider][cls]}">
                    </ng-container>
                  </ng-template>
                </td>
              </tr>
            </ng-container>
          </ng-template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <ng-container *ngIf="(providerErrors | json) != '{}'">
    <div class="d-flex gap-10 cursor-pointer" (click)="toggleErrors()">
      <div class="expand-icon-wrapper">
        <span class="material-symbols-outlined" [@rotate]="isErrorsExpanded">
          expand_more
        </span>
      </div>
      <ng-container *ngIf="!isErrorsExpanded">
        <div class="d-flex flex-wrap gap-10">
          <div class="d-flex flex-wrap align-self-end gap-5 border-bottom-red">
            <ng-container *ngFor="let provider of providerErrors | keyvalue">
              <ng-container *ngIf="provider.key !== 'noResults'">
                <span class="gray-color">{{ provider.key }}</span>
              </ng-container>
            </ng-container>
          </div>
          <div class="d-flex flex-wrap align-self-end gap-y-10" *ngIf="providerErrors?.noResults?.length">
            <ng-container *ngFor="let provider of providerErrors?.noResults; last as last">
              <span class="border-bottom-light-gray light-gray-color" [class.pr-2]="!last">{{provider}}</span>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
    <ng-container *ngIf="isErrorsExpanded">
      <ul class="list-unstyled mb-0 mt-3">
        <ng-container *ngFor="let provider of providerErrors | keyvalue">
          <ng-container *ngIf="provider.key !== 'noResults'">
            <li>
              <div class="d-flex align-items-baseline gap-5">
                <span class="material-icons fs-8 red-color"> circle </span>
                <span> {{provider.key}}: </span>
                <span class="d-flex flex-wrap gap-x-5">
                  <span class="gray-color" *ngFor="let error of providerErrors[provider.key]; last as last">
                    {{ error }}{{ !last ? ';' : ''}}
                  </span>
                </span>
              </div>
            </li>
          </ng-container>
        </ng-container>
        <li *ngIf="providerErrors?.noResults?.length">
          <div class="d-flex align-items-baseline gap-5">
            <span class="material-icons fs-8 gray-color"> circle </span>
            <span class="d-flex flex-wrap gap-x-5">
              <span class="light-gray-color" *ngFor="let provider of providerErrors?.noResults; last as last">
                {{provider}}{{last ? ':' : ''}}
              </span>
              <span class="gray-color">Empty/No results</span>
            </span>
          </div>
        </li>
      </ul>
    </ng-container>
  </ng-container>
  <div class="my-5" *ngIf="isOfferFound && !offers.length">No offers found for selected filter</div>
</ng-container>

<ng-template #itinerariesSvg>
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.34383 2.27187C5.70633 1.90938 6.29383 1.90938 6.65633 2.27187L10.7282 6.34438C11.0907 6.70688 11.0907 7.29375 10.7282 7.65562L6.65633 11.7281C6.29383 12.0906 5.70696 12.0906 5.34509 11.7281L1.27196 7.65625C1.18575 7.57022 1.11735 7.46802 1.07069 7.35552C1.02402 7.24302 1 7.12242 1 7.00063C1 6.87883 1.02402 6.75823 1.07069 6.64573C1.11735 6.53323 1.18575 6.43104 1.27196 6.345L5.34383 2.27187Z" fill="#9747ff"/>
  </svg>
</ng-template>

<ng-template #offersSvg>
  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11" fill="none">
    <path d="M5 10.5C4.30833 10.5 3.65833 10.3687 3.05 10.106C2.44167 9.84333 1.9125 9.48717 1.4625 9.0375C1.0125 8.5875 0.656333 8.05833 0.394 7.45C0.131667 6.84167 0.000333333 6.19167 0 5.5C0 4.80833 0.131333 4.15833 0.394 3.55C0.656667 2.94167 1.01283 2.4125 1.4625 1.9625C1.9125 1.5125 2.44167 1.15633 3.05 0.894C3.65833 0.631667 4.30833 0.500333 5 0.5C5.69167 0.5 6.34167 0.631333 6.95 0.894C7.55833 1.15667 8.0875 1.51283 8.5375 1.9625C8.9875 2.4125 9.34383 2.94167 9.6065 3.55C9.86917 4.15833 10.0003 4.80833 10 5.5C10 6.19167 9.86867 6.84167 9.606 7.45C9.34333 8.05833 8.98717 8.5875 8.5375 9.0375C8.0875 9.4875 7.55833 9.84383 6.95 10.1065C6.34167 10.3692 5.69167 10.5003 5 10.5Z" fill="#4aaea8"/>
  </svg>
</ng-template>

<ng-template #offersAndItinerariesTemplate let-offersGridObj="offersGridObj">
  <div class="d-flex flex-column gap-10">
    <div class="d-flex align-items-center gap-5">
      <span class="w-39">Itins.</span>
      <ng-container [ngTemplateOutlet]="itinerariesSvg"></ng-container>
      <span>{{(offersGridObj.offers | groupedByFlights).length}}</span>
    </div>
    <div class="d-flex align-items-center gap-5">
      <span class="w-40">Offers</span>
      <ng-container [ngTemplateOutlet]="offersSvg"></ng-container>
      <span>{{offersGridObj.count}}</span>
    </div>
  </div>
</ng-template>
