import {Injectable} from '@angular/core';

@Injectable()
export class SeatMapService {

  seats = {};
  allSeats = {};
  selectedSeats = {};
  segmentMatrix = {};
  originalSeatDisplay = [];

  seatAvailabilityError: any = {};
  showSeatAvailabilityLoader = false;
  private sa: any;

  constructor() {
  }

  selectSeat(segment: string, rowIdx: number, char: string, passenger: string = '') {
    let segmentCount = segment + '_count';

    let seatCode = this.getSeatCode(segment, rowIdx, char);

    let isExistPassenger;
    Object.keys(this.selectedSeats[segment]).map(key => {
      if (this.selectedSeats[segment][key] === passenger) {
        isExistPassenger = key;
      }
    });

    if (!Object.keys(this.selectedSeats[segment]).includes(seatCode)) {
      if (isExistPassenger) {
        delete this.selectedSeats[segment][isExistPassenger];
        this.selectedSeats[segmentCount]--;
      }
      this.selectedSeats[segment][seatCode] = passenger;
      this.selectedSeats[segmentCount]++;
    } else {
      delete this.selectedSeats[segment][seatCode];
      this.selectedSeats[segmentCount]--;
    }
  }

  createRange(number) {
    let items: number[] = [];
    for (let i = 0; i < number; i++) {
      items.push(i);
    }
    return items;
  }

  getSeatCode(segment, rowIdx, char) {
    return (rowIdx + this.segmentMatrix[segment].rows.first) + char;
  }

  prepareSeatAvailability(response: any, filterSegments: boolean = false, seatsSegments: any[] = []): void {
    this.originalSeatDisplay = response.seatDisplay ? JSON.parse(JSON.stringify(response.seatDisplay)) : [];
    if (filterSegments) {
      response.seatDisplay = this.prepareSeatDisplay(response)
        .filter(seat => seatsSegments.some(seatSegment => seatSegment.segmentID === seat.segment));
    } else {
      response.seatDisplay = this.prepareSeatDisplay(response);
    }
    this.prepareSeatsPerSegment(response);
    this.prepareSelectedSeatsReceiver(response);
  }

  prepareSeatDisplay(seatAvailability): any[] {
    let seatDisplayBySegment = {};
    seatAvailability.seatDisplay.map(seatDisp => {
      let segment = seatDisp.segment;
      if (seatDisplayBySegment[segment]) {
        if (seatDisp.columns.length > seatDisplayBySegment[segment].columns.length) {
          seatDisplayBySegment[segment].columns = seatDisp.columns;
        }
        if (seatDisp.rows.first < seatDisplayBySegment[segment].rows.first) {
          seatDisplayBySegment[segment].rows.first = seatDisp.rows.first;
        }
        if (seatDisp.rows.last > seatDisplayBySegment[segment].rows.last) {
          seatDisplayBySegment[segment].rows.last = seatDisp.rows.last;
        }
      } else {
        seatDisplayBySegment[segment] = seatDisp;
      }
    });

    return [...Object.values(seatDisplayBySegment)];
  }

  prepareSeatsPerSegment(seatAvailability, selectedPassenger = ''): void {
    let seats = {};
    if (!seatAvailability) {
      this.seats = seats;
      return;
    }

    let seatList = [...seatAvailability.seatList];
    if (selectedPassenger) {
      seatList = seatAvailability.seatList.filter(item => {
        return item.passengerRefs === selectedPassenger;
      });
    }

    seatList.map((item) => {
      if (!seats[item.segment]) {
        seats[item.segment] = {};
      }
      let code = item.location.row.toString() + item.location.column.toString();
      seats[item.segment][code] = item;
      seats[item.segment][code]['isAllowedForPax'] = seats[item.segment][code].passengerRefs === selectedPassenger;
    });
    if (!selectedPassenger) {
      this.allSeats = seats;
    }
    this.seats = seats;
  }

  prepareSelectedSeatsReceiver(seatAvailability) {
    let selectedSeats = {},
      segmentMatrix = {};

    seatAvailability.seatList.map((item) => {
      if (selectedSeats[item.segment] === undefined) {
        selectedSeats[item.segment] = {};
        selectedSeats[item.segment + '_count'] = 0;
      }
    });
    seatAvailability.seatDisplay.map((item) => {
      segmentMatrix[item.segment] = JSON.parse(JSON.stringify(item));
    });

    this.selectedSeats = selectedSeats;
    this.segmentMatrix = segmentMatrix;
  }

  getTotalPrice() {
    let totalPrice = 0.0;
    Object.entries(this.selectedSeats).map(seg => {
      Object.entries(seg[1]).map(seatCode => {
        totalPrice += this.allSeats[seg[0]][seatCode[0]].price.consumer.total
      });
    });

    return totalPrice;
  }
}
