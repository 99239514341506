import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener, Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import {NgbCalendar, NgbDate, NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import {DateService} from "../../services/date.service";
import { GtmService } from "../../services/gtm.service";

@Component({
  selector: 'app-datepicker-range',
  templateUrl: './datepicker-range.component.html',
  styleUrls: ['./datepicker-range.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatepickerRangeComponent),
      multi: true
    }
  ]
})
export class DatepickerRangeComponent implements ControlValueAccessor {

  @Input() hasReset = true;
  @ViewChild('datepicker') datePicker: any;
  @Output() emitValueChange = new EventEmitter();
  @Output() emitRangeChange = new EventEmitter();

  hoveredDate: NgbDate | null = null;

  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  maxDate: NgbDate | null;

  selectedDateRange: string;

  constructor(private calendar: NgbCalendar,
              public formatter: NgbDateParserFormatter,
              private eRef: ElementRef,
              private dateService: DateService,
              private gtmService: GtmService) {
    // this.fromDate = calendar.getNext(calendar.getToday(), 'd', -10);
    // this.toDate = calendar.getToday();
    // this.maxDate = calendar.getToday();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void { }

  writeValue(dataRange: string): void {
    if (dataRange) {
      const dates = dataRange.split('/');
      this.fromDate = this.parseDate(dates[0]);
      this.toDate = this.parseDate(dates[1]);
      this.selectedDateRange = dataRange;
    } else {
      this.fromDate = null;
      this.toDate = null;
    }
  }

  private parseDate(dateStr: string): NgbDate {
    const [year, month, day] = dateStr.split('-').map(Number);
    return new NgbDate(year, month, day);
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  onDateSelection(date: NgbDate): void {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && (date.after(this.fromDate) || date.equals(this.fromDate))) {
      this.toDate = date;
      this.updateDateRange();
    } else {
      this.toDate = null;
      this.fromDate = date;
    }

    this.emitRangeChange.emit({ fromDate: this.fromDate, toDate: this.toDate });
  }

  private updateDateRange(): void {
    this.selectedDateRange = `${this.formatDate(this.fromDate)}/${this.formatDate(this.toDate)}`;
    this.onChange(this.selectedDateRange);
    this.datePicker.close();
  }

  private formatDate(date: NgbDate): string {
    return date ? `${date.year}-${date.month}-${date.day}` : '';
  }

  isHovered(date: NgbDate): boolean {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate): boolean {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate): boolean {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  resetPeriod(event: any): void {
    this.fromDate = null;
    this.toDate = null;
    this.selectedDateRange = '';
    this.emitValueChange.emit('');
    this.onChange(this.selectedDateRange);
    event.stopPropagation();
  }

  onInputChange(input: string): void {
    // Remove all spaces from the input
    let normalizedInput = input.replace(/\s+/g, '');

    // Check if the input contains exactly two dates in the form 'DD-MM-YY-DD-MM-YY'
    const matchTwoDates = normalizedInput.match(/^(\d{2}-\d{2}-\d{2})-?(\d{2}-\d{2}-\d{2})$/);

    if (matchTwoDates) {
      normalizedInput = `${matchTwoDates[1]} - ${matchTwoDates[2]}`;
    } else {
      // For all other cases, normalize input by replacing any amount of space around the dash with a single dash
      normalizedInput = normalizedInput.replace(/\s*-\s*/g, ' - ');
    }

    // Split the input into two parts
    const dateRange = normalizedInput.split(' - ');

    if (dateRange.length === 2) {
      const fromDateString = dateRange[0].trim();
      const toDateString = dateRange[1].trim();

      const parsedFromDate = this.dateService.formatDate(fromDateString);
      const parsedToDate = this.dateService.formatDate(toDateString);

      if (parsedFromDate.isValid() && parsedToDate.isValid()) {
        this.fromDate = new NgbDate(parsedFromDate.year(), parsedFromDate.month() + 1, parsedFromDate.date());
        this.toDate = new NgbDate(parsedToDate.year(), parsedToDate.month() + 1, parsedToDate.date());
        this.updateDateRange();
        this.emitRangeChange.emit({ fromDate: this.fromDate, toDate: this.toDate });
        this.gtmService.addEvent('EnteredFullDateRange');
      }
    }
  }

  onRangeChanged(range: string) {
    this.onInputChange(range);
  }
}
