<ng-container *ngIf="baggageAllowance; else dash">
  <div class="baggage-container" [class.pdf]="isPdf" *ngIf="!isCollapsed;">
    <ng-container *ngIf="baggageCheckedList?.length > 0">
      <div class="icon-container" *ngIf="!isPdf">
        <a class="icon-link text-primary"
          [ngbPopover]="baggageCheckedInfo"
          [triggers]="'hover'"
          container="body"
          popoverClass="{{baggageCheckedList[0].status === BAGGAGE_ALLOWANCE_STATUS.ALLOWED ? 'allowed-popover': 'disallowed-popover'}}">
          <ng-container [ngSwitch]="baggageCheckedList[0].status">
            <img *ngSwitchCase="BAGGAGE_ALLOWANCE_STATUS.ALLOWED" [ngStyle]="{'width': iconSize + 'px'}" src="./assets/img/illustrations/checkedBaggageAvailable.svg">
            <img *ngSwitchCase="BAGGAGE_ALLOWANCE_STATUS.DISALLOWED" [ngStyle]="{'width': iconSize + 'px'}" src="../../../../assets/img/illustrations/checkedBaggageUnavailable.svg">
          </ng-container>
        </a>
      </div>
      <ng-container [ngTemplateOutlet]="baggageCheckedInfo" *ngIf="isPdf"></ng-container>
    </ng-container>

    <ng-container *ngIf="baggageCarryOnList?.length > 0">
      <div class="icon-container" *ngIf="!isPdf">
        <a class="icon-link text-primary"
           [ngbPopover]="baggageCarryOnInfo"
           [triggers]="'hover'"
           container="body"
           popoverClass="{{baggageCarryOnList[0].status === BAGGAGE_ALLOWANCE_STATUS.ALLOWED ? 'allowed-popover': 'disallowed-popover'}}">
          <ng-container [ngSwitch]="baggageCarryOnList[0].status">
            <img *ngSwitchCase="BAGGAGE_ALLOWANCE_STATUS.ALLOWED" [ngStyle]="{'width': iconSize + 'px'}" src="./assets/img/illustrations/carryOnAvailable.svg">
            <img *ngSwitchCase="BAGGAGE_ALLOWANCE_STATUS.DISALLOWED" [ngStyle]="{'width': iconSize + 'px'}" src="./assets/img/illustrations/carryOnUnavailable.svg">
          </ng-container>
        </a>
      </div>
      <ng-container [ngTemplateOutlet]="baggageCarryOnInfo" *ngIf="isPdf"></ng-container>
    </ng-container>
  </div>

  <div *ngIf="isCollapsed" class="baggage-collapsed-container">
    <div class="flex-container text-left" *ngIf="baggageCheckedList?.length > 0">
      <div class="icon-container">
        <ng-container [ngSwitch]="baggageCheckedList[0].status">
          <img *ngSwitchCase="BAGGAGE_ALLOWANCE_STATUS.ALLOWED" [ngStyle]="{'width': iconSize + 'px'}" src="./assets/img/illustrations/checkedBaggageAvailable.svg">
          <img *ngSwitchCase="BAGGAGE_ALLOWANCE_STATUS.DISALLOWED" [ngStyle]="{'width': iconSize + 'px'}" src="../../../../assets/img/illustrations/checkedBaggageUnavailable.svg">
        </ng-container>
      </div>
      <div class="grey-color">
        Checked
      </div>
      <ng-container [ngTemplateOutlet]="baggageListCollapsedTemplate"
                    [ngTemplateOutletContext]="{baggageList: baggageCheckedList}">
      </ng-container>
    </div>

    <div class="flex-container text-left" *ngIf="baggageCarryOnList?.length > 0">
      <div class="icon-container">
        <ng-container [ngSwitch]="baggageCarryOnList[0].status">
          <img *ngSwitchCase="BAGGAGE_ALLOWANCE_STATUS.ALLOWED" [ngStyle]="{'width': iconSize + 'px'}" src="./assets/img/illustrations/carryOnAvailable.svg">
          <img *ngSwitchCase="BAGGAGE_ALLOWANCE_STATUS.DISALLOWED" [ngStyle]="{'width': iconSize + 'px'}" src="./assets/img/illustrations/carryOnUnavailable.svg">
        </ng-container>
      </div>
      <div class="grey-color">
        Carryon
      </div>
      <ng-container [ngTemplateOutlet]="baggageListCollapsedTemplate"
                    [ngTemplateOutletContext]="{baggageList: baggageCarryOnList}">
      </ng-container>
    </div>
  </div>
</ng-container>

<ng-template #baggageCheckedInfo>
  <div class="popover-body" [class.pdf]="isPdf">
    <div class="name">
      <ng-container [ngSwitch]="baggageCheckedList[0].status">
        <img *ngSwitchCase="BAGGAGE_ALLOWANCE_STATUS.ALLOWED" [ngStyle]="{'width': '28px'}" src="./assets/img/illustrations/checkedBaggageAvailable.svg">
        <img *ngSwitchCase="BAGGAGE_ALLOWANCE_STATUS.DISALLOWED" [ngStyle]="{'width': '28px'}" src="../../../../assets/img/illustrations/checkedBaggageUnavailable.svg">
      </ng-container>
      <b>BAGGAGE CHECKED</b>
    </div>
    <ng-container *ngFor="let baggage of baggageCheckedList">
      <ng-container *ngIf="baggage">
        <ng-container *ngIf="baggage.status === BAGGAGE_ALLOWANCE_STATUS.ALLOWED ||
                             (baggage.status === BAGGAGE_ALLOWANCE_STATUS.DISALLOWED && baggageCheckedList?.length > 1); else disallowed">
          <b class="baggage-header" *ngIf="baggage.chooseType === OPTION_CHOOSE_TYPE.AND && baggage.options?.length > 1">Multiple allowed items from list below:</b>
          <b class="baggage-header" *ngIf="baggage.chooseType === OPTION_CHOOSE_TYPE.OR && baggage.options?.length > 1">One of items from list below allowed:</b>
          <div class="baggage-info-container">
            <div class="baggage-info">
              <div *ngFor="let option of baggage.options;let i = index">
                <div class="baggage-info-text">
                  <ng-container *ngIf="!isPdf">
                    <i *ngIf="baggage.status === BAGGAGE_ALLOWANCE_STATUS.ALLOWED" class="material-icons allowed-color">done_all</i>
                    <i *ngIf="baggage.status === BAGGAGE_ALLOWANCE_STATUS.DISALLOWED" class="material-icons disallowed-color">do_not_disturb_on</i>
                  </ng-container>
                  <span>{{ option.text }}</span>
                </div>
                <ng-container *ngIf="option.properties?.length > 0">
                  <div class="property-container">
                    <div *ngFor="let property of option.properties">
                      <span>{{property.text}}</span>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="passenger-info">
              <div>
                x
              </div>
              <div>
                <div *ngFor="let passengerInfo of baggage.passengersInfo">{{passengerInfo}}</div>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <ng-template #disallowed>
        <div class="disallowed-text">
          <i class="material-icons disallowed-color" *ngIf="!isPdf">do_not_disturb_on</i>
          <div *ngFor="let option of baggage.options">
            {{option.text}}
          </div>
        </div>
      </ng-template>
    </ng-container>
  </div>
</ng-template>

<ng-template #baggageCarryOnInfo>
  <div class="popover-body" [class.pdf]="isPdf">
    <div class="name">
      <ng-container [ngSwitch]="baggageCarryOnList[0].status">
        <img *ngSwitchCase="BAGGAGE_ALLOWANCE_STATUS.ALLOWED" [ngStyle]="{'width': '28px'}" src="./assets/img/illustrations/carryOnAvailable.svg">
        <img *ngSwitchCase="BAGGAGE_ALLOWANCE_STATUS.DISALLOWED" [ngStyle]="{'width': '28px'}" src="./assets/img/illustrations/carryOnUnavailable.svg">
      </ng-container>
      <b>BAGGAGE CARRYON</b>
    </div>
    <ng-container *ngFor="let baggage of baggageCarryOnList">
      <ng-container *ngIf="baggage">
        <ng-container *ngIf="baggage.status === BAGGAGE_ALLOWANCE_STATUS.ALLOWED ||
                             (baggage.status === BAGGAGE_ALLOWANCE_STATUS.DISALLOWED && baggageCarryOnList?.length > 1); else disallowed">
          <b class="baggage-header" *ngIf="baggage.chooseType === OPTION_CHOOSE_TYPE.AND && baggage.options?.length > 1">Multiple allowed items from list below:</b>
          <b class="baggage-header" *ngIf="baggage.chooseType === OPTION_CHOOSE_TYPE.OR && baggage.options?.length > 1">One of items from list below allowed:</b>
          <div class="baggage-info-container">
            <div class="baggage-info">
              <div *ngFor="let option of baggage.options;let i = index">
                <div class="baggage-info-text">
                  <ng-container *ngIf="!isPdf">
                    <ng-container [ngSwitch]="baggage.type">
                      <ng-container *ngSwitchCase="BAGGAGE_ALLOWANCE_TYPE.BAGGAGE_HANDBAG">
                        <img *ngIf="baggage.status === BAGGAGE_ALLOWANCE_STATUS.ALLOWED" width="28" src="./assets/img/illustrations/handBagAvailable.svg">
                        <img *ngIf="baggage.status === BAGGAGE_ALLOWANCE_STATUS.DISALLOWED" width="28" src="./assets/img/illustrations/handBagUnavailable.svg">
                      </ng-container>
                      <ng-container *ngSwitchDefault>
                        <i *ngIf="baggage.status === BAGGAGE_ALLOWANCE_STATUS.ALLOWED" class="material-icons allowed-color">done_all</i>
                        <i *ngIf="baggage.status === BAGGAGE_ALLOWANCE_STATUS.DISALLOWED" class="material-icons disallowed-color">do_not_disturb_on</i>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                  <span>{{ option.text }}</span>
                </div>
                <ng-container *ngIf="option.properties?.length > 0">
                  <div class="property-container">
                    <div *ngFor="let property of option.properties">
                      <span>{{property.text}}</span>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="passenger-info">
              <div>
                x
              </div>
              <div>
                <div *ngFor="let passengerInfo of baggage.passengersInfo">{{passengerInfo}}</div>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <ng-template #disallowed>
        <div class="disallowed-text">
          <i class="material-icons disallowed-color" *ngIf="!isPdf">do_not_disturb_on</i>
          <div *ngFor="let option of baggage.options">
            {{option.text}}
          </div>
        </div>
      </ng-template>
    </ng-container>
  </div>
</ng-template>

<ng-template #baggageListCollapsedTemplate let-baggageList="baggageList">
  <ng-container *ngFor="let baggage of baggageList">
    <ng-container *ngIf="baggage.status === BAGGAGE_ALLOWANCE_STATUS.ALLOWED ||
                           (baggage.status === BAGGAGE_ALLOWANCE_STATUS.DISALLOWED && baggageList?.length > 1); else disallowed">
      <b *ngIf="baggage.chooseType === OPTION_CHOOSE_TYPE.AND && baggage.options?.length > 1">Multiple allowed items from list below:</b>
      <b *ngIf="baggage.chooseType === OPTION_CHOOSE_TYPE.OR && baggage.options?.length > 1">One of items from list below allowed:</b>
      <div class="flex-container">
        <div class="flex-container">
          <div *ngFor="let option of baggage.options;let i = index" class="flex-container">
            <div class="flex-container">
              <span>{{ option.text | lowercase | capitalizeFirstLetter }}</span>
            </div>
            <ng-container *ngIf="option.properties?.length > 0">
              <div class="grey-color">
                <div *ngFor="let property of option.properties">
                  <span>{{property.text}}</span>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="d-flex gap-2">
          <div>
            x
          </div>
          <div class="text-left">
            <div *ngFor="let passengerInfo of baggage.passengersInfo">{{passengerInfo}}</div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-template #disallowed>
      <div class="d-flex flex-column gap-5">
        <div *ngFor="let option of baggage.options">
          {{option.text | lowercase | capitalizeFirstLetter}}
        </div>
      </div>
    </ng-template>
  </ng-container>

</ng-template>

<ng-template #dash>
  <span *ngIf="iconSize <= 32">ー</span>
</ng-template>
