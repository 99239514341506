<div class="umbrella-loader" *ngIf="!errorMessage">
  <div class="loader-header">Umbrella Authentication.</div>
    <app-loader></app-loader>
</div>

<div class="container">
  <div *ngIf="errorMessage" class="row">
    <div class="col alert-danger ml-3 mt-6 mr-3 p-3 rounded">
      {{ errorMessage }}
    </div>
  </div>
</div>

