<div class="mt-4">
  <div class="row mb-4">
    <div class="col search-traveler" *ngIf="_profiles.length" [formGroup]="searchForm">
      <div class="input-group">
        <span class="input-group-prepend">
          <div class="input-group-text border-right-0"><i class="fa fa-search" [ngClass]="{'focused-search-icon': isSearchInputFocused}"></i></div>
        </span>
        <input [formControlName]="searchFormFields.travelerSearch"
               class="form-control py-2 pl-0 border-left-0 border"
               type="text"
               autocomplete="off"
               [placeholder]="'Search ' + role"
               (focus)="isSearchInputFocused = true"
               (blur)="isSearchInputFocused = false"
        />
      </div>
    </div>
    <div class="col">
      <button class="btn btn-primary rounded float-right"
              [title]="'Add new ' + role"
              (click)="open(addProfileModal, 'lg'); setRole(role)">
        <i class='fa fa-plus'></i>
      </button>
    </div>
  </div>

  <div class="table-responsive" *ngIf="filteredProfiles.length">
    <table class="table table-default table-hover mb-0">
      <thead>
      <tr>
        <th scope="col">Name</th>
        <th scope="col">Email</th>
        <th scope="col">Portal Access</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let p of filteredProfiles | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize; let idx = index"
          (click)="helpers.go('/corporates/'+corporateID+'/travelers/' + p.id);">
        <td>
          <div>{{p.name + ' ' + p.surname}}</div>
        </td>
        <td>{{p.email}}</td>
        <td>
          <i class="material-icons" *ngIf="p.account?.status">
            done
          </i>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <div class="row" *ngIf="!filteredProfiles.length">
    <div class="col">
      No profiles found
    </div>
  </div>

  <ngb-pagination *ngIf="filteredProfiles.length > pageSize"
                  class="d-flex justify-content-end mt-4"
                  [collectionSize]="filteredProfiles.length"
                  [(page)]="page"
                  [pageSize]="pageSize"
                  [maxSize]="7"
                  [boundaryLinks]="true"
                  (pageChange)="onPageChange()"
  ></ngb-pagination>

</div>

<ng-template #addProfileModal let-c="close" let-d="dismiss">
  <app-profile-detail
    [corporateID]="corporateID"
    [isModal]="true"
    (emitClose)="c('ok')">
  </app-profile-detail>
</ng-template>
