<div class="mb-4">
  <app-breadcrumbs [items]="breadcrumbs"></app-breadcrumbs>
</div>

<ngb-tabset #tabs="ngbTabset" [destroyOnHide]="false" [activeId]="selectedTab" (tabChange)="selectTab($event?.nextId)">
  <ngb-tab id="details" title="Details">
    <ng-template ngbTabContent>
      <ng-container *ngIf="isCorporateLoaded">
        <app-add-or-edit-corporate
          [isModal]="false"
          [corporate]="corporate"
          (emitSaveCorporate)="onSaveCorporate()">
        </app-add-or-edit-corporate>
      </ng-container>
      <ng-container *ngIf="!isCorporateLoaded">
        <div class="card">
          <div class="card-body">
            <div class="loader-wrapper">
              <app-loader></app-loader>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-template>
  </ngb-tab>
  <ngb-tab id="programs" title="Programs">
    <ng-template ngbTabContent>
      <app-discounts-form
        [discounts]="discounts"
        [showAddedEditedLoader]="showAddedEditedLoaderInDiscountForm"
        [showRemovedLoader]="showRemovedLoaderInDiscountForm"
        (emitAddDiscount)="addDiscount($event)"
        (emitEditDiscount)="editDiscount($event)"
        (emitRemoveDiscount)="removeDiscount($event)"
      ></app-discounts-form>
    </ng-template>
  </ngb-tab>
  <ngb-tab id="managers" title="Managers">
    <ng-template ngbTabContent>
      <app-profile-list
        *ngIf="corporate.id"
        [corporateID]="corporate.id"
        [profiles]="managers"
        [role]="'manager'">
      </app-profile-list>
    </ng-template>
  </ngb-tab>
  <ngb-tab id="travelers" title="Travelers">
    <ng-template ngbTabContent>
      <app-profile-list
        *ngIf="corporate.id"
        [corporateID]="corporate.id"
        [profiles]="travelers"
        [role]="'traveler'">
      </app-profile-list>
    </ng-template>
  </ngb-tab>
</ngb-tabset>
