<div class="group-bar-wp" *ngIf="showOptions">
  <div class="loader-wrapper" *ngIf="showLoader">
    <app-loader></app-loader>
  </div>
  <div class="group-wp-inner" *ngIf="!showLoader">
    <div class="table-group-wp">
      <div id="scrolltable">
        <table class="table text-center">
          <tr>
            <th style="width: 100px">
              <div>Leg</div>
            </th>
            <th style="width: 100px">
              <div>Cabin</div>
            </th>
            <th style="width: 120px">
              <div>Fare</div>
            </th>
            <th style="width: 60px">
              <div>Seats</div>
            </th>
            <th style="width: 80px">
              <div>Type</div>
            </th>
            <th class="text-nowrap" style="width: 106px">
              <div>Fare Rules</div>
            </th>
            <th style="width: 130px">
              <div>Baggage</div>
            </th>
            <th style="width: 170px">
              <div>Disclosures</div>
            </th>
            <th>
              <div>Price</div>
            </th>
            <th></th>
            <th [style.width.px]="columnsToShow.action ? 80 : null"></th>
          </tr>
          <tr class="spacer"></tr>
          <tbody>
            <ng-container *ngFor="let sOffer of simpleOffers; index as i;">
              <tr #row
                  (mouseover)="mouseOver(i)"
                  (mouseleave)="mouseLeave(i)"
                  *ngFor="let fare of sOffer.fares; index as j; first as first; last as last;"
                  (click)="setSelectedOffer(sOffer.offerID, isReshop); collapse(i)"
                  class="grouped-offers-table-row"
                  [ngClass]="showSelectedOffer && (sOffer.offerID === desireOffer?.offerID || offerService.isSelectedOfferFromUpsell(sOffer, desireOffer)) ?
                  sOffer.fares.length > 1 ? first ? 'selected-row-first' : last ? 'selected-row-last' : 'selected-row-middle' : 'selected-row-single' : ''"
                  [class.bordered]="last"
                  [class.collapsed]="isCollapsed[i]"
                >
                <td class="text-nowrap">
                  <span [ngbPopover]="!isCollapsed[i] ? routeInfo: null" [triggers]="'hover'" popoverClass="fare-info-popover">
                    {{ sOffer.fares[j].route }}
                  </span>
                </td>
                <ng-template #routeInfo>
                  <div *ngFor="let segmentInfo of sOffer.flightInfo[j].segmentsInfo" class="text-center">
                    {{segmentInfo.segmentRoute.departureAirportCode}} - {{segmentInfo.segmentRoute.arrivalAirportCode}}
                  </div>
                </ng-template>
                <td> {{ sOffer.cabins[j] }}</td>
                <td>
                  <div class="d-flex flex-column gap-15"
                       [style.text-align]="isCollapsed[i] ? 'left' : null"
                       [ngbPopover]="!isCollapsed[i] ? segmentsFareInfo : null"
                       [triggers]="'hover'"
                       popoverClass="fare-info-popover">
                    {{sOffer.fares[j].airlineNamesPerLeg | joinArrayPipe}}
                    <div *ngIf="isCollapsed[i]" class="d-flex flex-column gap-20">
                      <div *ngFor="let segmentInfo of sOffer.flightInfo[j].segmentsInfo">
                        <div *ngIf="sOffer.flightInfo[j].segmentsInfo?.length > 1">
                          {{segmentInfo.segmentRoute.departureAirportCode}} → {{segmentInfo.segmentRoute.arrivalAirportCode}}
                        </div>
                        <div>
                          <div class="label">Class:</div>
                          <div>{{segmentInfo.class.code}}</div>
                        </div>
                        <div>
                          <div class="label">FareBasisCode:</div>
                          <div>{{segmentInfo.fareBasisCode}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
                <ng-template #segmentsFareInfo>
                  <div *ngFor="let segmentInfo of sOffer.flightInfo[j].segmentsInfo" class="text-center">
                    Class: {{segmentInfo.class.code}}, FareBasisCode: {{segmentInfo.fareBasisCode}}
                  </div>
                </ng-template>
                <td>
                  <div *ngFor="let flightInfo of sOffer.flightInfo[j].segmentsInfo">
                    {{flightInfo.seatsLeft}}
                  </div>
                </td>
                <td [attr.rowspan]="sOffer.fares.length" *ngIf="j === 0" [class.border-bottom]="j === 0">
                  <div class="d-flex flex-column gap-5">
                    <span class="badge badge-rectangle option-fare-info"
                          *ngIf="sOffer.preparedFareTypeInfo"
                          [ngStyle]="{ 'background-color': sOffer.preparedFareTypeInfo.fareTypesCategory.backgroundColor }"
                          [ngbPopover]="!isCollapsed[i] ? sOffer.preparedFareTypeInfo.fareTooltipText : null"
                          [triggers]="'hover'">
                      {{sOffer.preparedFareTypeInfo.fareTypesCategory.groupName}}
                    </span>
                    <ng-container *ngIf="isCollapsed[i]">
                      <div>{{sOffer.preparedFareTypeInfo.commonFareType.fareCode}}</div>
                      <div class="label">{{sOffer.preparedFareTypeInfo.commonFareType.fareType}}</div>
                    </ng-container>
                  </div>
                </td>
                <td [attr.rowspan]="sOffer.fares.length" *ngIf="j === 0" [class.border-bottom]="j === 0">
                  <app-fare-rules-capacity-info
                    [mode]="{default: true}"
                    [fareRulesCapacity]="sOffer.fareRulesCapacity"
                    [isCollapsed]="isCollapsed[i]">
                  </app-fare-rules-capacity-info>
                </td>
                <td>
                  <app-baggage-allowance-info
                    [baggageAllowance]="sOffer.baggageAllowance[j]"
                    [passengers]="sOffer.passengersData"
                    [iconSize]="32"
                    [isCollapsed]="isCollapsed[i]">
                  </app-baggage-allowance-info>
                </td>
                <td class="text-nowrap">
                  <app-disclosures
                    [disclosures]="sOffer.disclosures[j]"
                    [isCollapsed]="isCollapsed[i]">
                  </app-disclosures>
                </td>
                <td [attr.rowspan]="sOffer.fares.length" class="font-weight-normal"
                    *ngIf="j === 0" [class.border-bottom]="j === 0">
                  <div class="d-flex flex-column justify-content-between"
                       [class.h-100]="isCollapsed[i]">
                    <div class="d-flex justify-content-center">
                      <div class="d-flex gap-3"
                           [ngbPopover]="sOffer.paymentTimeLimit && !isCollapsed[i] ? paymentTimeLimit: null"
                           [triggers]="'hover'"
                           popoverClass="fare-info-popover">
                        <span class="fs-15">{{sOffer.price | number :'1.2-2'}}</span>
                        <span class="align-self-end">{{sOffer.currency}}</span>
                      </div>
                    </div>
                    <div class="d-flex flex-column align-items-end text-right" *ngIf="isCollapsed[i]">
                      <ng-container [ngTemplateOutlet]="paymentTimeLimit"></ng-container>
                    </div>
                  </div>
                </td>
                <ng-template #paymentTimeLimit>
                  <span class="label">Ticketing Time Limit: </span>
                  <app-date-with-popover [date]="sOffer.paymentTimeLimit"></app-date-with-popover>
                </ng-template>
                <td class="td-spacer" [attr.rowspan]="sOffer.fares.length" *ngIf="j === 0 && columnsToShow.action"></td>
                <td [attr.rowspan]="sOffer.fares.length" *ngIf="j === 0 && columnsToShow.action"
                    style="padding: 0"
                    [ngClass]="{'border-bottom': j === 0, 'd-none': !columnsToShow.action}">
                  <div class="btn btn-tonal-primary open-button h-100"
                       [ngClass]="{'disabled': isOfferExpired, 'collapsed': isCollapsed[i]}"
                       (click)="setSelectedOffer(sOffer.offerID, !isReshop); $event.stopPropagation();">
                    <div class="d-flex align-items-center">
                      {{isReshop ? 'Select' : 'Open' }}
                      <i *ngIf="!isReshop" class="material-icons">open_in_new</i>
                    </div>
                  </div>
                </td>
              </tr>
              <tr class="spacer"></tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
