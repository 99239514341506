<ng-container *ngIf="!forbiddenLimits.includes(limit)">
  <div class="expirate-template-wp" *ngIf="!isAlreadyExpired">
    <div class="clock-label">
      {{label}}
    </div>
    <div class="clock-wp" >
      <div *ngIf="clock.monthDiff" class="clock-item">
        <span class="months item-container">{{clock.monthDiff | padStart:2:'0'}}</span>
        <div class="smalltext">months</div>
      </div>
      <div class="clock-item">
        <span class="days item-container">{{clock.dayDiff | padStart:2:'0'}}</span>
        <div class="smalltext">days</div>
      </div>
      <div class="clock-item">
        <span class="hours item-container">{{clock.hourDiff | padStart:2:'0'}}</span>
        <div class="smalltext">hours</div>
      </div>
      <div class="clock-item">
        <span class="minutes item-container">{{clock.minuteDiff | padStart:2:'0'}}</span>
        <div class="smalltext">minutes</div>
      </div>
      <div class="clock-item">
        <span class="seconds item-container">{{clock.secondDiff | padStart:2:'0'}}</span>
        <div class="smalltext">seconds</div>
      </div>
    </div>
  </div>

  <div class="expired-message" *ngIf="isAlreadyExpired">
    {{onExpiredMessage}}
  </div>

</ng-container>
