<div class="d-flex align-items-center gap-5" *ngIf="uniqueTaxes.length">
  <i
    *ngIf="!isPDF"
    [ngbPopover]="taxesInfoTooltip"
    [autoClose]="'outside'"
    placement="bottom"
    class="material-icons taxes-info-icon"
    container="body"
    #popover="ngbPopover"
  >
    {{buttonName}}
  </i>

  <ng-content></ng-content>
</div>

<ng-template #taxesInfoTooltip>
  <div class="taxes-info-tooltip-wp">
    <h4>Taxes info</h4>
    <ul>
      <li *ngFor="let tax of uniqueTaxes">
        <span class="tax-info-code" *ngIf="tax.code">{{tax.code}}: </span>
        <span class="tax-info-price" *ngIf="tax.total">{{tax.total | number :'1.2-2'}} {{tax.currency || currency}}</span>
      </li>
    </ul>
  </div>
</ng-template>
