<div class="select-wp">
  <button class="custom-select-btn row-up"
          (click)="toggleOptionList($event)"
          [ngClass]="{'row-up': showOptionList}">
      <span class="select-btn-text-wp" *ngIf="multiSelect" [innerHTML]="value?.length ? (value | joinArrayPipe) : placeHolder"></span>
      <span class="select-btn-text-wp" *ngIf="!multiSelect" [innerHTML]="mapObject ? mapObject[value] : (value ? value : placeHolder)"></span>
  </button>

  <ul class="options-list-wp" *ngIf="showOptionList">
    <ng-container *ngIf="options.length">
      <li class="option-item empty-option"
          *ngIf="(placeHolder && multiSelect && value?.length) || (placeHolder && !multiSelect && value !== '')"
          (click)="onChangeValue(multiSelect ? [] : '', $event)"
      [innerHTML]="placeHolder"></li>
      <li
        class="option-item"
        *ngFor="let option of options"
        (click)="onChangeValue(option, $event)"
        [ngClass]="{'selected': multiSelect
         ? (value | containItemInArray:(option.value ? option.value : option))
          : (value === (option.value ? option.value : option))}"
      [innerHTML]="mapObject
          ? mapObject[option]
          : option.optionValue
          ? option.optionValue
          : option"></li>
    </ng-container>
  </ul>
</div>

