<div class="d-flex">
  <div class="input-date-group">
    <input class="hidden"
           name="datepicker"
           ngbDatepicker
           #datepicker="ngbDatepicker"
           [autoClose]="'false'"
           (dateSelect)="onDateSelection($event)"
           [displayMonths]="2"
           [dayTemplate]="t"
           [startDate]="fromDate!"
           [maxDate]="maxDate">
    <ng-template #t let-date let-focused="focused">
      <span class="custom-day"
            [class.focused]="focused"
            [class.range]="isRange(date)"
            [class.faded]="isHovered(date) || isInside(date)"
            (mouseenter)="hoveredDate = date"
            (mouseleave)="hoveredDate = null">
        {{ date.day }}
      </span>
    </ng-template>
  </div>
  <div class="w-100">
    <input #dpFromDate
           appDateInput
           [isRange]="true"
           [isFlightDate]="true"
           class="form-control date-input-control"
           placeholder="DD-MM-YY - DD-MM-YY"
           name="dpFromDate"
           [value]="fromDate || toDate ? (formatter.format(fromDate) + ' - ' + formatter.format(toDate)) : ''"
           (input)="onInputChange(dpFromDate.value)"
           (ngModelChange)="onRangeChanged($event)">
  </div>
  <i class="material-icons inner-icon close-icon" (click)='resetPeriod($event);' *ngIf="hasReset && fromDate">
    close
  </i>
  <i class="material-icons inner-icon" (click)="datePicker.toggle()">
    event
  </i>
</div>
