<div class="modal-header">
  <h3 class="modal-title">Airline notification</h3>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="emitClose.emit()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="alert alert-warning p-3 rounded" *ngIf="airlineNotification.message && airlineID === 'AF' || airlineID === 'KL'">
    {{airlineNotification.message}}
  </div>
  <div class="fs-14 mb-2">
    <app-date-with-popover [date]="airlineNotification.timestamp * 1000">
    </app-date-with-popover>
  </div>
  <div class="amendment-container" *ngFor="let amendment of airlineNotification.amendments">
    <div class="flex-container mb-4">
      <div class="label">Type</div>
      <div class="value">{{amendment.type}}</div>
    </div>
    <div class="flex-container mb-4" *ngIf="amendment.context || amendment.contextMeaning">
      <div class="label">Context</div>
      <div class="value">
        <ng-container *ngIf="amendment.contextMeaning">
          {{amendment.contextMeaning}}
        </ng-container>
        <ng-container *ngIf="!amendment.contextMeaning">
          {{amendment.context | getDisruptionDescription}}
        </ng-container>
      </div>
    </div>
    <div class="flex-container" *ngIf="amendment.remarks?.length">
      <div class="label">Remarks</div>
      <div class="value">
        <ng-container *ngFor="let remark of amendment.remarks">
          <div>{{remark}}</div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="d-flex mb-3" *ngIf="airlineNotification.seen">
    <div class="label">Seen by</div>
    <div class="value">{{airlineNotification.seenBy}}</div>
  </div>

  <app-notification-seen-checkbox
    [checked]="airlineNotification.seen"
    (emitNotificationChange)="onNotificationChange()">
  </app-notification-seen-checkbox>

  <div class="mt-3" *ngIf="orderNotificationSeenToggleError.message">
    <error-alert-block
      [error]="orderNotificationSeenToggleError"
    ></error-alert-block>
  </div>

  <ng-content></ng-content>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="emitClose.emit()">Close</button>
  <button type="button"
          class="btn btn-outline-primary"
          *ngIf="airlineNotification.actionRequired?.includes('Rebook')"
          (click)="emitButtonClick.emit('rebook')">
    Order Rebook
  </button>
  <button type="button"
          class="btn btn-outline-primary"
          *ngIf="airlineNotification.actionRequired?.includes('Refund')"
          (click)="emitButtonClick.emit('refund')">
    Cancel & Refund
  </button>
  <button type="button"
          class="btn btn-primary"
          *ngIf="airlineNotification.actionRequired?.includes('AcceptChange')"
          (click)="emitButtonClick.emit('acceptChange')">
    Accept changes
  </button>
</div>
