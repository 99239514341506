<div class="card"
     [ngStyle]="{'border-color': isShowGroupBar ? '#4c5d75' : ''}">
  <div class="card-header">
    <div class="row item-card-header">
      <div class="col-6 logo-class-wp">
        <div class="filter-image" *ngFor="let sOfferByProvider of simpleOffersByProvider;">
          <div [ngStyle]="{'cursor': simpleOffersByProvider.length > 1 ? 'pointer' : ''}"
               [class.selected-provider]="simpleOffersByProvider.length > 1 && offer.owner === sOfferByProvider.owner"
               [class.unselected-provider]="simpleOffersByProvider.length > 1 && offer.owner !== sOfferByProvider.owner"
               (click)="setSelectedOffer({offerID: sOfferByProvider.offerID, isOpenNewWindow: false}, true)">
            <app-airline-img
              [class.highlight-img]="simpleOffersByProvider.length > 1 "
              [airlineID]="sOfferByProvider.owner"
              [title]="sOfferByProvider.owner | getImageDescription: sOfferByProvider.flights"
            ></app-airline-img>
          </div>
        </div>
      </div>
      <div class="col-6 d-flex justify-content-end">
        <div class="price-header-wp search-offer">
          <div class="text-right" *ngIf="isDiscountExists">
            <span class="old-price">{{oldTotalPrice | number :'1.2-2'}} {{offer.price.consumer.currency}}</span>
            <span class="ml-2 red-color">-{{discountPercentage}}%</span>
          </div>
          <div class="d-flex">
            <div class="mt-1 mr-3">
              <app-fare-type [fareTypeCategories]="groupNames" [mainFareType]="fareTypeInfo">
              </app-fare-type>
            </div>
            <div>
              <app-show-pax-breakdown-info
                [consumerPrice]="offer.price.consumer"
                [passengers]="offer.passengers">
                <div class="bigger-price"
                     [class.new-price-red]="isDiscountExists">
                  <span class="font-weight-bold">{{offer.price.consumer.total | number :'1.2-2'}}</span> <span> {{offer.price.consumer.currency}} </span>
                </div>
              </app-show-pax-breakdown-info>
              <div class="smaller-price text-right">
                Taxes: {{offer.price.consumer.tax.total | number :'1.2-2'}} {{offer.price.consumer.currency}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body" [ngClass]="{'hasTwoFare': fareNames[1] && fareNames[1].length}">
    <ng-container *ngFor="let flightInfo of flightsInfo; index as index">
      <div class="d-flex">
        <div [ngClass]="checkObject && checkObject['originalDestination-'+index] ? 'unlock' : 'lock'"
             (click)="selectLeg(index)">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" class="icon-unlock cursor-pointer">
            <path d="M7.84442 11.4737V6.21053C7.84442 5.09383 8.28227 4.02286 9.06164 3.23323C9.84101 2.44361 10.8981 2 12.0003 2C13.1025 2 14.1595 2.44361 14.9389 3.23323C15.7183 4.02286 16.1561 5.09383 16.1561 6.21053M4.72754 13.5789C4.72754 13.0206 4.94646 12.4851 5.33615 12.0903C5.72583 11.6955 6.25436 11.4737 6.80546 11.4737H17.1951C17.7462 11.4737 18.2747 11.6955 18.6644 12.0903C19.0541 12.4851 19.273 13.0206 19.273 13.5789V19.8947C19.273 20.4531 19.0541 20.9886 18.6644 21.3834C18.2747 21.7782 17.7462 22 17.1951 22H6.80546C6.25436 22 5.72583 21.7782 5.33615 21.3834C4.94646 20.9886 4.72754 20.4531 4.72754 19.8947V13.5789ZM10.9613 16.7368C10.9613 17.016 11.0708 17.2838 11.2656 17.4812C11.4605 17.6786 11.7247 17.7895 12.0003 17.7895C12.2758 17.7895 12.5401 17.6786 12.7349 17.4812C12.9298 17.2838 13.0392 17.016 13.0392 16.7368C13.0392 16.4577 12.9298 16.1899 12.7349 15.9925C12.5401 15.7951 12.2758 15.6842 12.0003 15.6842C11.7247 15.6842 11.4605 15.7951 11.2656 15.9925C11.0708 16.1899 10.9613 16.4577 10.9613 16.7368Z" stroke="#590000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" class="icon-lock cursor-pointer">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.45516 12.0903C5.07244 12.4851 4.85742 13.0206 4.85742 13.5789V19.8947C4.85742 20.4531 5.07244 20.9886 5.45516 21.3834C5.83789 21.7782 6.35698 22 6.89824 22H17.1023C17.6436 22 18.1627 21.7782 18.5454 21.3834C18.9281 20.9886 19.1431 20.4531 19.1431 19.8947V13.5789C19.1431 13.0206 18.9281 12.4851 18.5454 12.0903C18.1627 11.6955 17.6436 11.4737 17.1023 11.4737H6.89824C6.35698 11.4737 5.83789 11.6955 5.45516 12.0903ZM8.93905 16.7368C8.93905 17.5744 9.26158 18.3776 9.83567 18.9698C10.4098 19.562 11.1884 19.8947 12.0003 19.8947C12.8122 19.8947 13.5908 19.562 14.1649 18.9698C14.739 18.3776 15.0615 17.5744 15.0615 16.7368C15.0615 15.8993 14.739 15.0961 14.1649 14.5039C13.5908 13.9117 12.8122 13.5789 12.0003 13.5789C11.1884 13.5789 10.4098 13.9117 9.83567 14.5039C9.26158 15.0961 8.93905 15.8993 8.93905 16.7368Z" fill="#590000"/>
            <path d="M7.91865 11.4737V6.21053C7.91865 5.09383 8.34867 4.02286 9.11413 3.23323C9.87958 2.44361 10.9178 2 12.0003 2C13.0828 2 14.121 2.44361 14.8864 3.23323C15.6519 4.02286 16.0819 5.09383 16.0819 6.21053V11.4737M4.85742 13.5789C4.85742 13.0206 5.07244 12.4851 5.45516 12.0903C5.83789 11.6955 6.35698 11.4737 6.89824 11.4737H17.1023C17.6436 11.4737 18.1627 11.6955 18.5454 12.0903C18.9281 12.4851 19.1431 13.0206 19.1431 13.5789V19.8947C19.1431 20.4531 18.9281 20.9886 18.5454 21.3834C18.1627 21.7782 17.6436 22 17.1023 22H6.89824C6.35698 22 5.83789 21.7782 5.45516 21.3834C5.07244 20.9886 4.85742 20.4531 4.85742 19.8947V13.5789ZM9.83567 18.9698C9.26158 18.3776 8.93905 17.5744 8.93905 16.7368C8.93905 15.8993 9.26158 15.0961 9.83567 14.5039C10.4098 13.9117 11.1884 13.5789 12.0003 13.5789C12.8122 13.5789 13.5908 13.9117 14.1649 14.5039C14.739 15.0961 15.0615 15.8993 15.0615 16.7368C15.0615 17.5744 14.739 18.3776 14.1649 18.9698C13.5908 19.562 12.8122 19.8947 12.0003 19.8947C11.1884 19.8947 10.4098 19.562 9.83567 18.9698Z" stroke="#590000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
        <app-flight-info
          [flightInfo]="flightInfo"
          [showRow]="false"
        ></app-flight-info>
      </div>
      <hr class="leg-splitter" *ngIf="(offer.flights.length > 1) && (index !== offer.flights.length - 1)">
    </ng-container>
  </div>
  <div class="card-footer" [ngStyle]="{'padding-bottom': isShowGroupBar && !showLoader ? 0 : ''}">
    <div class="row align-items-center">
      <div class="col-md-3"></div>
      <div class="col font-weight-bold text-center">
        <app-offer-expiration [time]="offer.createdAt" [expiresAt]="offer.offerExpiration" (emitOfferExpiration)="onOfferExpired($event)"></app-offer-expiration>
      </div>
      <div class="col-md-4 button-container">
        <button class="btn btn-primary"
                *ngIf="simpleOffers.length"
                (click)="toggleGroupBar()">
          {{isShowGroupBar ? '-' : '+'}} Fares
          <span *ngIf="simpleOffers.length > 1"> ({{simpleOffers.length}}) </span>
        </button>
      </div>
    </div>

    <app-options
      [showOptions]="isShowGroupBar"
      [simpleOffers]="simpleOffers"
      [desireOffer]="offer"
      [isReshop]="isReshop"
      [showLoader]="showLoader"
      (onSelectedOffer)="setSelectedOffer($event)"
      [isOfferExpired]="offerExpired">
    </app-options>
  </div>
</div>

