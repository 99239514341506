<div class="row">
  <div class="col mt-3 p-3">
    <div class="alert-warning p-3 rounded" *ngIf="!metas">
      Metas is empty
    </div>
    <div class="background p-3" *ngIf="metas">
      <div class="meta-line" *ngFor="let key of metas | keys">
        <div class="key">{{key}}</div>
        <div class="double">:</div>
        <div class="value">{{metas[key]}}</div>
      </div>
    </div>
  </div>
</div>
