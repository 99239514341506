<span
  class="badge status-field text-uppercase"
  [class.badge-gray]="status == ORDER_STATUS.PENDING"
  [class.badge-primary]="status == ORDER_STATUS.TICKETED"
  [class.badge-warning]="status == ORDER_STATUS.ISSUING"
  [class.badge-dark]="status == ORDER_STATUS.DONE"
  [class.badge-success]="status == ORDER_STATUS.STARTED"
  [class.badge-danger]="redStatuses.includes(status)"
  [class.badge-dark-red]="status === ORDER_STATUS.UNUSED">
  {{ statusText }}
</span>

<ng-container *ngIf="!isPdf && (isNotificationsExists || !!disruptionStatus)">
  <span
    class="status-exclamation-mark"
    [ngbPopover]="!!disruptionStatus && !isOpened ? (disruptionStatus | addSpaceBeforeCapitalLetters) : null"
    popoverClass="popover-wp"
    triggers="hover"
    container="body"
    (click)="openTab()">
    <i class="material-symbols-outlined exclamation-icon problem hovered">
      exclamation
    </i>
  </span>
</ng-container>

<ng-container *ngIf="isPdf && !!disruptionStatus">
  <span class="ml-1 red-color"> {{disruptionStatus}} </span>
</ng-container>

