<div class="change-order-wp" *ngIf="selectedTab !== 'payment' && selectedTab !== 'changeSuccess' && selectedTab !== 'repriceSuccess'">
  <h4 class="m-3" *ngIf="selectedTab === 'offer'">Current order flight details</h4>
  <h4 class="m-3" *ngIf="selectedTab === 'details'">Selected destination for date change</h4>
  <div class="flight-details row">
    <div class="col flight-details-inner" [ngStyle]="{'margin-bottom' : isFirstSearch ? '1rem' : ''}">
      <ng-container *ngFor="let flightInfo of flightInfos; last as last; let idx = index">
        <app-flight-info
          [flightInfo]="flightInfo"
          [showBaggage]="false"
          [isDisabled]="startedFlightsFlags[idx]"
          [showRow]="!availableOffersForViewTable.length"
        >
        </app-flight-info>
        <app-destination-choose-item
          [destinationData]="originDestinationsData[idx]"
          [originDestinations]="originDestinationsData"
          [id]="idx"
          [isAirportsDisabled]="!order.allowedRequests.ticketed.OrderReshopRouteChange"
          [invalidDate]="invalidDate[idx]"
          [minDates]="minDates"
          [maxDates]="maxDates"
          [currentDate]="currentDate"
          [isDisabled]="startedFlightsFlags[idx]"
          [isFirstSearch]="isFirstSearch"
          (emitChangedModel)="onChangeModel($event, idx)"
          (emitChangedValue)="onChangeDropdownValue($event, idx)">
        </app-destination-choose-item>
        <hr *ngIf="!last">
      </ng-container>
    </div>
  </div>
</div>

<div class="" [ngSwitch]="selectedTab">
  <ng-container *ngSwitchCase="'offer'">
    <div class="change-date-wp">
      <div class="loader-wrapper" *ngIf="this.dataTransferService.changeDateOrderData.type == 'order'">
        <app-loader></app-loader>
      </div>
      <ng-container *ngIf="this.dataTransferService.changeDateOrderData.type == 'offers'">
        <ng-container *ngIf="isFirstSearch">
          <div class="first-search">
            <div class="loader-wrapper loader-wrapper-small" *ngIf="getOffersByDateLoader">
              <app-loader></app-loader>
            </div>
            <ng-container [ngTemplateOutlet]="searchButton"></ng-container>
          </div>
        </ng-container>
        <ng-container *ngIf="!isFirstSearch">
          <h3>New Itinerary:</h3>
          <div class="multi-search-wp">
            <div class="multi-search-item-wp">
              <ng-container *ngFor="let destination of originDestinationsData; let index = index">
                <app-destination-choose-item
                  [destinationData]="destination"
                  [originDestinations]="originDestinationsData"
                  [id]="index"
                  [isAirportsDisabled]="!order.allowedRequests.ticketed.OrderReshopRouteChange"
                  [invalidDate]="invalidDate[index]"
                  [minDates]="minDates"
                  [maxDates]="maxDates"
                  [currentDate]="currentDate"
                  [isDisabled]="startedFlightsFlags[index]"
                  [isFirstSearch]="false"
                  [isNextSearch]="true"
                  [bookingType]="order.bookingType"
                  (emitChangedModel)="onChangeModel($event, index)"
                  (emitChangedValue)="onChangeDropdownValue($event, index)"
                  (emitChangedOriginDestinations)="onChangeOriginDestinations($event)"
                  (emitSelectedDateRange)="disableSearchButton = $event"
                >
                </app-destination-choose-item>
              </ng-container>
            </div>
            <div class="search-wp ml-4">
              <ng-container [ngTemplateOutlet]="searchButton"></ng-container>
              <div class="loader-wrapper loader-wrapper-small" *ngIf="getOffersByDateLoader">
                <app-loader></app-loader>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-template #searchButton>
          <button
            class="btn search-btn"
            (click)="allowSearch ? sendNewDate() : actionAbort()"
            [class.btn-primary]="allowSearch"
            [class.btn-danger]="!allowSearch"
            [disabled]="itinReshopModelValid || disableSearchButton || disableSearch()"
          >{{allowSearch ? 'Search' : 'Abort'}}
          </button>
        </ng-template>
      </ng-container>
      <div *ngIf="itinReshopWarnings.length" class="mb-3 mt-3">
        <app-show-warnings [warnings]="itinReshopWarnings"></app-show-warnings>
      </div>
      <div class="mt-3">
        <error-alert-block
          [error]="itinReshopError"
        ></error-alert-block>
      </div>
      <div *ngIf="!getOffersByDateLoader && availableOffersForViewTable.length">
        <app-offer-grid
          [offers]="filteredOffers"
          [showGrid]="false"
          (emitGridChange)="handleGridChange($event)"
        ></app-offer-grid>
      </div>

    </div>

    <div class="available-offers-wp" *ngIf="availableOffersForView.length; else isNotAppropriateOffers">
      <div class="pagination-wp row">
        <div class="available-offers-title col-5">
          <span class="available-offers-info">
            Available offers: <span>{{availableOffersForView.length}}</span>
          </span>
          <ng-container *ngIf="selectedOrderTypeTab === 'oneWay'">
            <div class="sort-select-wp mb-3">
              <label>Sort By: </label>
              <app-custom-select
                class="sort-strategy-select"
                [(ngModel)]="sortStrategy"
                [options]="sortOptions | keys"
                [mapObject]="sortOptions"
                (emitValueChange)="onSelectedSortStrategy($event)">
              </app-custom-select>
            </div>
          </ng-container>

        </div>
        <ng-container *ngIf="selectedOrderTypeTab !== 'perSegment'">
          <div class="avaliable-offers-pagination col-7">
            <ngb-pagination *ngIf="groupedOffersByFlight?.length > pageSize"
                            [collectionSize]="groupedOffersByFlight.length"
                            [(page)]="page" [pageSize]="pageSize" [maxSize]="10" [boundaryLinks]="true"
                            (pageChange)="search()"
                            class="d-flex justify-content-end"
            ></ngb-pagination>
          </div>
        </ng-container>
      </div>
      <div class="available-offers-container">
        <ng-container *ngIf="this.originDestinationsData?.length === 1">
          <ng-container *ngIf="itinReshopRepriceErrors.message">
            <error-alert-block
              [error]="itinReshopRepriceErrors"
            ></error-alert-block>
          </ng-container>
          <ng-container
            *ngFor="let offer of groupedOffersByFlight | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize, let index = index;">
            <div class="row available-offer-row">
              <div class="col">
                <app-offer-info
                  [offerCandidate]="offer"
                  [isReshop]="true"
                  (emitOfferForReshop)="onSelectedOrder($event, index)"
                >
                </app-offer-info>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="originDestinationsData?.length > 1">
          <ng-container>
            <ngb-tabset (tabChange)="onTabSelected($event?.nextId)">
              <ngb-tab id="perSegment" title="Per Segment">
                <ng-template ngbTabContent>
                  <div class="mt-5">
                    <ng-container *ngIf="itinReshopRepriceErrors.message">
                      <error-alert-block
                        [error]="itinReshopRepriceErrors"
                      ></error-alert-block>
                    </ng-container>
                    <app-offer-builder
                      [allOffers]="availableOffersForView"
                      [originDestinations]="originDestinationForPerSegment"
                      [isReshop]="true"
                      [searchTypeOption]="originDestinationsData?.length === 2 ? 'Round-Trip' : 'Multi-City'"
                      (emitOfferForReshop)="onSelectedOrder($event, 0)"
                      [autoSelectFlightIndex]="autoSelectFlightIndex"
                      [allOptions]="allOptions"
                      [allowToRemoveOD]="false"
                    >
                    </app-offer-builder>
                  </div>
                </ng-template>
              </ngb-tab>
<!--              <ngb-tab id="fullTrip" title="Full Trip" *ngIf="order.status !== 'Started'">-->
<!--                <ng-template ngbTabContent>-->
<!--                  <div class="mt-5">-->
<!--                    <div class="d-flex sort-select-wp justify-content-end mb-3">-->
<!--                      <label>Sort By: </label>-->
<!--                      <app-custom-select-->
<!--                        class="sort-strategy-select"-->
<!--                        [(ngModel)]="sortStrategy"-->
<!--                        [options]="sortOptions | keys"-->
<!--                        [mapObject]="sortOptions"-->
<!--                        (emitValueChange)="onSelectedSortStrategy($event)">-->
<!--                      </app-custom-select>-->
<!--                    </div>-->
<!--                    <ng-container-->
<!--                      *ngFor="let offer of availableOffersForView | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize, let index = index;">-->
<!--                      <div class="row available-offer-row">-->
<!--                        <div class="col">-->
<!--                          <app-offer-info-->
<!--                            [offerCandidate]="offer"-->
<!--                            [isSearchPage]="true"-->
<!--                            [isReshop]="true"-->
<!--                            (emitOfferForReshop)="onSelectedOrder($event, index)"-->
<!--                          >-->
<!--                          </app-offer-info>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </ng-container>-->
<!--                  </div>-->
<!--                </ng-template>-->
<!--              </ngb-tab>-->
            </ngb-tabset>
          </ng-container>
        </ng-container>
      </div>
      <ng-container *ngIf="selectedOrderTypeTab !== 'perSegment'">
        <div class="row bottom-pagination-wp">
          <div class="col-12">
            <ngb-pagination *ngIf="groupedOffersByFlight?.length > pageSize"
                            [collectionSize]="groupedOffersByFlight.length"
                            [(page)]="page" [pageSize]="pageSize" [maxSize]="10" [boundaryLinks]="true"
                            (pageChange)="search()"
                            class="d-flex justify-content-end"
            ></ngb-pagination>
          </div>
        </div>
      </ng-container>
    </div>
    <ng-template #isNotAppropriateOffers>
      <div class="d-flex justify-content-center align-items-center" *ngIf="isShowShowAllButton && !getOffersByDateLoader">
        <b class="mr-2">
          No offers found with the search criteria
        </b>
        <button class="btn btn-primary" (click)="onShowAllOffers()" *ngIf="allAppropriateOffer.length">Show all offers</button>
      </div>
    </ng-template>
  </ng-container>

  <ng-container *ngSwitchCase="'details'">
    <h4 class="m-3">Selected offer</h4>
    <div class="mt-3">
      <div class="offering card selected-offer-card-body" *ngIf="offerFromItinReshopReprice">
        <div class="card-header">
          <app-offer-price-header-view
            [offer]="offerFromItinReshopReprice">
          </app-offer-price-header-view>
        </div>
        <div class="card-body">
          <div class="row" *ngIf="offerPriceError || changeOrderDateErrors">
            <div class="col">
              <error-alert-block
                [error]="offerPriceError || changeOrderDateErrors"
              ></error-alert-block>
            </div>
          </div>
          <app-offer-price-view
            [offer]="offerFromItinReshopReprice"
            [isDateChange]="true"
            [travelers]="[]"
          ></app-offer-price-view>
        </div>
        <ng-container *ngIf="!changeOrderProcess.isProcess else changeOrderProcessTemplate">
          <div class="card-footer">
            <button *ngIf="(offerFromItinReshopReprice.allowedRequests?.SeatAvailability &&
                            (offerFromItinReshopReprice.owner === OWNERS.AF || offerFromItinReshopReprice.owner === OWNERS.KL))"
                    (click)="loadSeatAvailability(seatsModal);"
                    class="btn btn-outline-primary">Select Seats
            </button>
            <button *ngIf="(offerFromItinReshopReprice.allowedRequests?.ServiceList &&
                            (offerFromItinReshopReprice.owner === OWNERS.AF || offerFromItinReshopReprice.owner === OWNERS.KL))"
                    (click)="open(serviceListModal, 'xl')"
                    class="btn btn-outline-primary ml-2">Select Services
            </button>

            <div *ngIf="selectedTab === 'details'" style="float: right;">
              <button
                class="btn btn-secondary ml-2"
                (click)="tabSelect('offer')">
                Back
              </button>
              <button
                *ngIf="(!offerFromItinReshopReprice.allowedRequests?.ServiceList && !offerFromItinReshopReprice.allowedRequests?.SeatAvailability)"
                class="btn btn-primary ml-2"
                (click)="tabSelect('payment')">
                Payment
              </button>
              <div class="float-right"
                   [ngbPopover]="reshopPopoverMessage"
                   container="body"
                   [triggers]="'hover'"
                   [disablePopover]="!isReshopNotAllowed"
                   popoverClass="popover-wp"
              >
                <button
                  *ngIf="(offerFromItinReshopReprice.allowedRequests?.ServiceList || offerFromItinReshopReprice.allowedRequests?.SeatAvailability) && (owner === OWNERS.AF || owner === OWNERS.KL)"
                  class="btn btn-primary ml-2"
                  (click)="tabSelect('payment');"
                  [disabled]="isReshopNotAllowed">
                  Next
                </button>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>

  <ng-template #serviceListModal let-c="close" let-d="dismiss">
    <app-service-list-pre-modal
      [owner]="offerFromItinReshopReprice.owner"
      [reshopOfferID]="offerFromItinReshopReprice.offerID"
      [flights]="flightsForRebookingServices"
      [passengersData]="order.passengers"
      [passengersList]="order.passengers"
      (emitDismiss)="d('closed')"
      (emitClose)="c('ok')"
      [rebook]="true"
      (emitServicesSelected)="onServicesSelected($event)"
    ></app-service-list-pre-modal>
  </ng-template>

  <ng-template #seatsModal let-c="close" let-d="dismiss">
    <div class="modal-card card seats">
      <div class="card-header">
        <div class="row align-items-center">
          <div class="col">
            <h3 class="card-header-title">Select Seats</h3>
          </div>
          <div class="col-auto">
            <button type="button" class="close" data-dismiss="modal" (click)="d('closed');"
                    aria-hidden="true">×
            </button>
          </div>
        </div>
      </div>

      <div class="card-body">
        <div class="loader-wrapper" *ngIf="seatMapService.showSeatAvailabilityLoader">
          <app-loader></app-loader>
        </div>

        <div *ngIf="seatAvailabilityResponseWarning.length && !seatMapService.showSeatAvailabilityLoader">
          <div *ngIf="seatAvailabilityResponseWarning.length" class="alert-warning p-3 mb-3 rounded">
            <ul>
              <li *ngFor="let item of seatAvailabilityResponseWarning"
                  [innerHTML]="item | htmlToStringParser | replace: '\n':'<br\/>'"
              ></li>
            </ul>
          </div>
        </div>

        <ng-container *ngIf="!seatMapService.showSeatAvailabilityLoader">
          <error-alert-block
            [error]="seatMapService.seatAvailabilityError"
          ></error-alert-block>
        </ng-container>

        <app-seat-map *ngIf="(seatMapService.seats | json) != '{}'"
          [passengers]="order.passengers | excludePassengersPipe"
          [segments]="seatsSegments"
          [seatAvailability]="seatAvailability"
          (seatSelect)="onSeatSelect($event)"
        ></app-seat-map>
      </div>
      <div class="card-footer text-right">
        <button
          type="button"
          class="btn btn-secondary mr-2"
          (click)="d('closed');"
        >Cancel
        </button>
        <button
          type="button"
          class="btn btn-primary"
          (click)="c('ok')"
        >Save
        </button>
      </div>
    </div>
  </ng-template>

  <ng-container *ngSwitchCase="'payment'">
    <div class="loader-wrapper" *ngIf="getOfferDetailsLoader">
      <app-loader></app-loader>
    </div>
    <div class="payment-form-wp" *ngIf="!getOfferDetailsLoader">
      <app-payment-form
        [pingProceed]="pingProceed"
        (emitFormData)="onEmitFormData($event)"
        [paymentWarnings]="changeOrderDateWarnings"
        [paymentError]="offerPriceError || changeOrderDateErrors"
        [allowedPaymentMethods]="order.allowedPaymentMethods"
        [cardSurchargesMap]="offerFromItinReshopReprice?.cardSurchargesMap"
        [totalPrice]="totalOfferPrice || offerFromItinReshopReprice.price.consumer?.total"
        [showTotalPrice]="true"
        [currency]="offerPriceCurrency || offerFromItinReshopReprice.price.consumer?.currency">
      </app-payment-form>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'changeSuccess'">
    <div *ngIf="changeOrderDateWarnings.length" class="mt-3">
      <app-show-warnings [warnings]="changeOrderDateWarnings"></app-show-warnings>
    </div>
    <div class="alert alert-success mb-3 mt-3">
      Date changed successfully. <br> Order ID: <span class="font-weight-bold">{{ order.id }}</span>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'repriceSuccess'">
    <div class="alert alert-success mb-3 mt-3">
      Reprice performed successfully. <br> Order ID: <span class="font-weight-bold">{{ order.id }}</span>
    </div>
  </ng-container>

</div>


<div class="card-footer d-flex justify-content-end" *ngIf="selectedTab === 'changeSuccess' || selectedTab === 'repriceSuccess'">
  <button
    class="btn btn-primary ml-2"
    (click)="emitClose.emit(true)">
    Close
  </button>
</div>

<div class="card-footer payment-footer d-flex justify-content-end" *ngIf="selectedTab === 'payment'">
  <ng-container *ngIf="!changeOrderProcess.isProcess else changeOrderProcessTemplate">
    <button
      class="btn btn-secondary mr-2"
      (click)="tabSelect('details')">
      Back
    </button>
    <button
      class="btn btn-primary"
      (click)="getFormDataIfValid()">
      Pay
    </button>
  </ng-container>
</div>

<ng-container *ngIf="changeReshopReprice.isProcess">
  <div class="change-reshop-reprice-process">
    <app-show-processing
      [processInfo]= "changeReshopReprice.processTitle">
    </app-show-processing>
  </div>
</ng-container>

<ng-template #changeOrderProcessTemplate>
  <app-show-processing
    [processInfo]= "changeOrderProcess.processTitle">
  </app-show-processing>
</ng-template>
