<div class="notifications">
  <div *ngFor="let notification of notifications" class="notification" [ngClass]="className(notification)">
    <ng-container *ngTemplateOutlet="notificationTpl;context:{notification:notification}"></ng-container>
  </div>
</div>

<ng-template #notificationTpl let-notification="notification">

  <div class="title row align-items-center" fxLayout="row" fxLayoutAlign="space-between center" *ngIf="notification.title">
<!--    <i class="material-icons title-icon">remove_circle_outline</i>-->
    <div class="col text-center error-title">{{notification.title}}</div>
    <div class="col-auto">
      <i class="material-icons notifications-close-btn" (click)="close(notification)">close</i>
    </div>
  </div>

  <div class="message" [innerHTML]="notification.message"></div>

</ng-template>
