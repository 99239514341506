<div class="row pb-3 pt-4">
  <div *ngIf="!order.history?.length " class="col alert-warning m-3 p-3 rounded">
    Order order.history is empty
  </div>
  <div class="col-md-10" *ngIf="order.history && order.history.length">
    <div class="timeline" *ngFor="let h of order.history, let i = index; last as last" [ngClass]="{'line': !last || order.history.length === 1}">
      <div class="entry">
        <div class="title">
          <div class="date"
               *ngIf="h.eventTime && (h.eventTime | dateFormat: true : 'HH:mm') | async as date"
               [ngStyle]="{'margin-left': date.length < 16 ? '13px' : ''}">
            <app-date-with-popover
              [date]="h.eventTime">
            </app-date-with-popover>
          </div>
          <div class="icon-wrapper">
            <div class="outer-circle" *ngIf="!h.airlineNotification || h.airlineNotification?.seen">
              <div class="inner-circle"></div>
            </div>
            <i class="material-symbols-outlined exclamation-icon problem cursor-default" *ngIf="h.airlineNotification && !h.airlineNotification?.seen">
              exclamation
            </i>
          </div>
        </div>
        <div class="info" [style.margin]="last ? '0px' : '0 0 2em'">
          <div class="item">
            <span class="title">Action</span>
            <span>{{h.airlineNotification ? 'Airline Notification' : h.eventID}}</span>
          </div>

          <div class="item" *ngIf="h.agent">
            <span class="title">User</span>
            <span>{{ h.agent }}</span>
          </div>

          <div class="item" *ngIf="h.status">
            <span class="title">Status</span>
            <span>{{ h.status }}</span>
          </div>

          <div class="item" *ngIf="h.flights?.length && allowedActionsForItineraryDisplay.includes(h.eventID)">
            <span class="title"></span>
            <div class="d-flex flex-column gap-5">
              <div *ngFor="let flight of h.flights">
                <div *ngFor="let segment of flight.segments">
                  <div class="d-flex flex-wrap gap-7">
                    <div class="d-flex gap-3" style="min-width: 80px;">
                      <span [appTooltip]="{airportCode: segment.originDestination.departure?.airportCode, terminalName: segment.originDestination.departure?.terminalName}">
                        {{segment.originDestination.departure.airportCode}}
                      </span>
                      <span>→</span>
                      <span [appTooltip]="{airportCode: segment.originDestination.arrival?.airportCode, terminalName: segment.originDestination.arrival?.terminalName}">
                        {{segment.originDestination.arrival.airportCode}}
                      </span>
                    </div>

                    <span>
                      {{(segment.originDestination.departure.date | dateFormat) | async}} {{segment.originDestination.departure.time}} - {{(segment.originDestination.arrival.date | dateFormat) | async}} {{segment.originDestination.arrival.time}}
                    </span>

                    <span *ngIf="segment.flightNumber">
                      {{segment.flightNumber}}
                    </span>

                    <span *ngIf="segment.detail.classOfService?.code" class="mw-10">
                      {{segment.detail.classOfService.code}}
                    </span>

                    <span *ngIf="segment.detail.classOfService?.fare?.marketingName">
                      {{segment.detail.classOfService.fare.marketingName}}
                    </span>

                    <span *ngIf="segment.equipment?.aircraftCode">
                      {{segment.equipment.aircraftCode}}
                    </span>

                    <span *ngIf="segment.detail?.segmentType">
                      {{segment.detail.segmentType}}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="item" *ngIf="h.eventID === 'OrderCanceled' && h.transactionType">
            <span class="title">Type</span>
            <span>{{h.transactionType}}</span>
          </div>

          <ng-container *ngIf="h.eventID == 'OrderDisrupted'">
            <div class="item">
              <span class="title">Type</span>
              <span>{{ h.details && h.details.code ? h.details.code : 'ScheduledTimeChange' }}</span>
            </div>

            <ng-container *ngIf="h.details && !h.details.flight_segment_diff">
              <div class="item" *ngIf="h.details && h.details.old">
                <span class="title">Old</span>
                <ul>
                  <li *ngFor="let route of h.details.old;">
                    <div *ngFor="let fs of route.segments">
                      {{ fs.originDestination.departure.airportCode }} → {{ fs.originDestination.arrival.airportCode}} -
                      {{(fs.originDestination.departure.date | dateFormat) | async}} {{fs.originDestination.departure.time}}
                    </div>
                  </li>
                </ul>
              </div>

              <div class="item" *ngIf="h.details && h.details.new">
                <span class="title">New</span>
                <ul>
                  <li *ngFor="let route of h.details.new;">
                    <div *ngFor="let fs of route.segments">
                      {{ fs.originDestination.departure.airportCode }} → {{ fs.originDestination.arrival.airportCode}} -
                      {{(fs.originDestination.departure.date | dateFormat) | async}} {{fs.originDestination.departure.time}}
                    </div>
                  </li>
                </ul>
              </div>
            </ng-container>

            <ng-container *ngIf="h.details && h.details.flight_segment_diff">
              <div *ngFor="let diff of h.details.flight_segment_diff;">
                <div class="item" *ngIf="diff.description">
                  <span class="title">Descr.</span>
                  <span>{{ diff.description }}</span>
                </div>

                <div class="item" *ngIf="diff.departure && diff.arrival">
                  <span class="title">Segment</span>
                  <span>{{ diff.departure }} → {{ diff.arrival }}</span>
                </div>

                <div class="item" *ngIf="diff.old">
                  <span class="title">Old</span>
                    <span class="old">
                      <b>{{ diff.old.flight_number }}</b>
                      {{ (diff.old.departure_date | dateFormat) | async }} {{ diff.old.departure_time }} -
                      {{ diff.old.departure_date != diff.old.arrival_date ? ((diff.old.arrival_date | dateFormat) | async) : ''  }}
                      {{ diff.old.arrival_time }}
                    </span>
                </div>

                <div class="item" *ngIf="diff.new">
                  <span class="title">New</span>
                  <span class="new">
                    <b>{{ diff.new.flight_number }}</b>
                    {{ (diff.new.departure_date | dateFormat) | async }} {{ diff.new.departure_time }} -
                    {{ diff.new.departure_date != diff.new.arrival_date ? ((diff.new.arrival_date | dateFormat) | async) : ''  }}
                    {{ diff.new.arrival_time }}
                  </span>
                </div>
              </div>
            </ng-container>
          </ng-container>

          <div class="item" *ngIf="['OrderTicketed', 'OrderCanceled'].indexOf(h.eventID) !== -1 && h.price">
            <span class="title">Price</span>
            <span>{{ h.price?.consumer?.total }} {{ h.price?.consumer?.currency }}</span>
          </div>

          <ng-container *ngIf="h.details && h.details.type">
            <div class="item" *ngIf="h.details.type !== h.eventID">
              <span class="title">Type</span>
              <span>{{ h.details.type }}</span>
            </div>

            <div class="item">
              <span class="title">Price</span>
              <span>{{ h.details.totalAmount }} {{ h.details.totalAmountCurrency }}</span>
            </div>

            <div class="item" *ngIf="['SeatsAddition', 'SeatsRemoval'].indexOf(h.details.type) !== -1">
              <span class="title">Items</span>
              <span>
                <ul *ngFor="let item of h.details.items;">
                  <li>
                    <span class="midnight-color"><b>Seat:</b></span>
                    {{item.seats[0]}} for {{item.segmentRef}},
                    <span class="midnight-color"><b>Passengers:</b></span> {{ getPassengersNameByRef(item.passengerRef) | joinArrayPipe }}
                  </li>
                </ul>
              </span>
            </div>

            <div class="item" *ngIf="h.details.changedRouteDetails">
              <span class="title">Changed</span>
              <span>
                <ul>
                  <li *ngFor="let route of h.details.changedRouteDetails;">
                    <span class="midnight-color"><b>Itinerary: </b></span> {{ route.departure.airportCode + ' → ' + route.arrival.airportCode }}
                    <br>
                    <span class="midnight-color"><b>Departure Date: </b></span> {{(route.departure.date | dateFormat) | async}},
                    <span class="midnight-color"><b>Time: </b></span> {{route.departure.time}}
                    <br>
                    <span class="midnight-color"><b>Arrival Date: </b></span> {{(route.arrival.date | dateFormat) | async}},
                    <span class="midnight-color"><b>Time: </b></span> {{route.arrival.time}}
                  </li>
                </ul>
              </span>
            </div>

            <ng-container *ngIf="h.details.type == 'DatesChange'">
              <div class="item">
                <span class="title">Type</span>
                <span>{{ h.details.type }}</span>
              </div>

              <div class="item">
                <span class="title">New</span>
                <span>
                  <ul>
                    <li *ngFor="let route of h.details.changedRouteDetails;">
                      <span class="midnight-color"><b>{{ route.departure.airportCode }} → {{ route.arrival.airportCode}}</b></span>
                      - {{(route.departure.date | dateFormat) | async}} {{route.departure.time}}
                    </li>
                  </ul>
                </span>
              </div>
            </ng-container>

            <div class="item" *ngIf="['SeatsAddition', 'SeatsRemoval', 'ItineraryChange'].indexOf(h.details.type) === -1">
              <span class="title">Items</span>
              <div>
                <ul *ngFor="let item of h.details.items;">
                  <li>
                    <span class="midnight-color"><b>Name:</b></span> {{ item.name }},
                    <span class="midnight-color"><b>Passengers:</b></span> {{ getPassengersNameByRef(item.passengerRef) | joinArrayPipe }}
                    <br>
                    <span class="midnight-color"><b>Details:</b></span>
                    <ng-container *ngIf="h.eventID === 'OrderSplit'; else descriptions">
                      <div *ngFor="let description of item.descriptions">
                        {{getDescriptionDetails(description).order}}:
                        <a class="red-color" (click)="openInNewTab(getDescriptionDetails(description).link)">{{getDescriptionDetails(description).id}}</a>
                      </div>
                    </ng-container>
                    <ng-template #descriptions>
                      <div *ngFor="let description of item.descriptions">{{ description }}</div>
                    </ng-template>
                  </li>
                </ul>
              </div>
            </div>
          </ng-container>

          <div class="item" *ngIf="h.tickets && h.tickets.length">
            <span class="title">Tickets</span>
            <span>
              <ul *ngFor="let ticket of h.tickets">
                <li>
                  <span class="midnight-color">
                    <b>{{ticket.ticketNumber | getTicketNumber}}</b>
                  </span> - {{ ticket.typeCodeDefinition }} for {{ getPassengersNameByRef(ticket.passengerRef) | joinArrayPipe }}
                </li>
              </ul>
            </span>
          </div>

          <ng-container *ngIf="h.airlineNotification">
            <ng-container *ngFor="let amendment of h.airlineNotification.amendments">
              <div class="item">
                <span class="title">Type</span>
                <span>{{amendment.type}}</span>
              </div>
              <div class="item" *ngIf="amendment.context || amendment.contextMeaning">
                <span class="title">Context</span>
                <span>
                  <ng-container *ngIf="amendment.contextMeaning">
                    {{amendment.contextMeaning}}
                  </ng-container>
                  <ng-container *ngIf="!amendment.contextMeaning">
                    {{amendment.context | getDisruptionDescription}}
                  </ng-container>
                </span>
              </div>
              <div class="item" *ngIf="amendment.remarks?.length">
                <span class="title">Remarks</span>
                <span>
                   <ul *ngFor="let remark of amendment.remarks">
                    <li>
                      {{remark}}
                    </li>
                   </ul>
                </span>
              </div>
              <div class="item" *ngIf="h.airlineNotification.seen">
                <span class="title">Seen by</span>
                <span>{{h.airlineNotification.seenBy}}</span>
              </div>
            </ng-container>
            <div class="mt-3">
              <app-notification-seen-checkbox
                [checked]="h.airlineNotification.seen"
                (emitNotificationChange)="onNotificationChange(h.airlineNotification)">
              </app-notification-seen-checkbox>
            </div>
          </ng-container>

        </div>
      </div>
    </div>
  </div>

  <div class="col-md-2" *ngIf="order.history?.length > 1">
    <button class="btn sort-btn" (click)="toggleSort()">
      Date
      <i class="material-symbols-outlined" [@rotate]="isDefaultSort">
        arrow_upward
      </i>
    </button>
  </div>
</div>

<ng-template #helpPopover>
  <div class="d-flex gap-3">
    <span>Mark as seen to hide warning and</span>
    <i class="material-symbols-outlined exclamation-icon problem">
      exclamation
    </i>
    <span>for this notification</span>
  </div>
</ng-template>
