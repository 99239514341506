import { Directive, HostListener, ElementRef, Input, EventEmitter, Output } from "@angular/core";
import { DateService } from '../services/date.service';
import moment from "moment";
import { GtmService } from "../services/gtm.service";
import { NgControl } from "@angular/forms";

@Directive({
  selector: '[appDateInput]'
})
export class DateInputDirective {

  @Input() isRange = false;
  @Input() isFlightDate = false;
  @Output() ngModelChange = new EventEmitter<string>();

  private hasEnteredDate = false; // Flag to track if "EnteredDate" event has been sent
  private hasEnteredFullDate = false; // Flag to track if "EnteredFullDate" event has been sent

  constructor(private el: ElementRef,
              private dateService: DateService,
              private gtmService: GtmService,
              private control: NgControl) { }

  // HostListener to select all text inside the input on focus
  @HostListener('focus', ['$event'])
  onFocus(event: FocusEvent): void {
    const inputElement = event.target as HTMLInputElement;
    inputElement.select();
  }

  @HostListener('input', ['$event'])
  onInputChange(event: Event) {
    const input = this.el.nativeElement as HTMLInputElement;

    // Allow only letters, digits, -, /, . and space characters
    let value = input.value.toUpperCase().replace(/[^A-Z0-9\/\-. ]/g, '');

    // Set the validated value back to the input
    input.value = value;

    // Set maximum input length based on the selected format
    this.setMaxLength();

    // Send "EnteredDate" event only once
    if (!this.hasEnteredDate) {
      this.gtmService.addEvent('EnteredDate');
      this.hasEnteredDate = true; // Set the flag to prevent multiple events
    }

    // Send "EnteredFullDate" event when control is valid, only once
    if (!this.isRange && this.control && this.control.value && this.control.valid && !this.hasEnteredFullDate) {
      this.gtmService.addEvent('EnteredFullDate');
      this.hasEnteredFullDate = true; // Set the flag to prevent multiple events
    }
  }

  @HostListener('blur', ['$event'])
  onBlur(event: FocusEvent) {
    this.hasEnteredDate = false;
    this.hasEnteredFullDate = false; // Reset flags when input is unfocused

    if (this.isFlightDate) {
      const input = this.el.nativeElement as HTMLInputElement;
      let value = input.value;

      if (this.isRange) {
        // Match two dates in the form of "DDMMM-DDMMM" or "DD MMM - DD MMM" (with optional spaces)
        const matchTwoDates = value.match(/^(\d{1,2}[ ]?[A-Z]{3})(?:\d{2})?\s*-\s*(\d{1,2}[ ]?[A-Z]{3})(?:\d{2})?$/i);

        if (matchTwoDates) {
          const fromDateStr = matchTwoDates[1];
          const toDateStr = matchTwoDates[2];

          // Add year if missing for each date
          const fromDate = this.addYearToDateStr(fromDateStr);
          const toDate = this.addYearToDateStr(toDateStr);

          // Update the input with formatted range
          value = `${fromDate} - ${toDate}`;
          input.value = value.toUpperCase();

          // Emit the updated range to trigger ngModelChange
          this.ngModelChange.emit(input.value);

          this.gtmService.addEvent('EnteredDateRangeWithoutYears');
        }
      } else {
        // Check if input matches "DD MMM" or "DDMMM" format
        const match = value.match(/^(\d{1,2})[ ]?([A-Z]{3})$/);
        if (match) {
          const day = match[1];
          const month = match[2];
          value = this.addYearToDate(day, month);
          input.value = value.toUpperCase();

          // Emit the updated value to trigger ngModelChange
          this.ngModelChange.emit(input.value);

          this.gtmService.addEvent('EnteredDateWithoutYear');
        }
      }
    }
  }

  private addYearToDateStr(dateStr: string): string {
    // Extract day and month, allowing for either "DDMMM" or "DD MMM" formats
    const day = dateStr.slice(0, 2);
    const month = dateStr.slice(2).trim();
    return this.addYearToDate(day, month);
  }

  private addYearToDate(day: string, month: string): string {
    const currentDate = moment();
    const inputDate = moment(`${day} ${month} ${currentDate.year()}`, 'DD MMM YYYY', true);

    // If input date is before current month, assume next year
    if (inputDate.isBefore(currentDate, 'month')) {
      inputDate.add(1, 'year');
    }
    return inputDate.format('DD MMM YY');
  }

  private setMaxLength() {
    if (!this.dateService.dateFormat) { return; }

    let maxLength = this.calculateMaxLength(this.dateService.dateFormat);
    this.el.nativeElement.maxLength = maxLength;
  }

  private calculateMaxLength(format: string): number {
    return !this.isRange ? format.length : (3 + format.length * 2);
  }
}
