import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterNonDeprecatedFlights'
})
export class FilterNonDeprecatedFlightsPipe implements PipeTransform {

  transform(flights: any[]): any[] {
    return flights.filter(flight => !flight.deprecated);
  }

}
