<div class="row py-4" *ngIf="!isEditAllowed">
  <div class="col text-center">
    <div ngbDropdown class="template-dropdown" [class.readonly]="isSelectTemplateDisabled">
      <button class="btn btn-outline-primary d-inline-flex" id="dropdownBasic1" ngbDropdownToggle>{{remarkName || 'Select template'}}</button>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
        <button ngbDropdownItem *ngFor="let template of templates" (click)="selectTemplate(template.name)">{{template.name}}</button>
      </div>
    </div>
  </div>
</div>

<div class="pb-3"
     [class.pt-4]="isEditAllowed"
     *ngIf="showTextArea">
  <textarea [(ngModel)]="remark.result"
            readonly
            [rows]="rows"
            autofocus>
  </textarea>
</div>

<div class="wrapper pb-4 sentry-mask"
     *ngIf='selectedTemplate && isButtonVisible.save'
     [ngStyle]="{'pointer-events': orderInfo.status !== 'Pending' && remark?.result ? 'none' : ''}">
    <div class='remarks' [formGroup]="remarkFormGroup">
      <div *ngFor="let remarkLine of parsedRemarks"
           class='line'>
        <ng-container *ngFor="let field of remarkLine; let idx = index;">
          <div>
            <span *ngIf="field.type === 0"
                  [ngStyle]="{'font-weight': field.content.startsWith('#') || field.content.toLowerCase().startsWith('passenger') ? 'bold': 'unset'}">
              {{field.content}}
            </span>
            <div (click)="enableForm($event)"
                 [ngbPopover]="fieldToDisable.includes(field.typeOfContent) ? 'Automatically generated' : ''"
                 [triggers]="'hover'"
                 popoverClass="disabled-field-popover-wp"
                 placement="top">
              <ng-container *ngIf="isButtonVisible.save">
                <input
                  *ngIf="field.type === 1 && field.typeOfContent !== 'list'"
                  [type]="field.typeOfContent === 'float' || field.typeOfContent === 'int' ? 'number' : 'text'"
                  [formControl]="remarkFormGroup.get(field.placeholder)"
                  [validate]="remarkFormGroup.get(field.placeholder).invalid || remarkFormGroup.get(field.placeholder).dirty || validateTriggered"
                  [onFocusListener]="true"
                  [minLength]="field.minLength"
                  [maxLength]="field.maxLength"
                  placeholder="{{field.placeholder.includes('price') ? 'price' : field.placeholder}}"
                  class="form-control sentry-mask"
                  [class.disabled]="!isButtonVisible.save || fieldToDisable.includes(field.typeOfContent)"
                  [readonly]="fieldToDisable.includes(field.typeOfContent)"
                  [ngStyle]="{'width.px': field.placeholder.includes('passenger') ? '75' : null}"
                  (keydown)="onKeyDown($event, field.typeOfContent)">
                <select
                  *ngIf="field.type === 1 && field.typeOfContent === 'list'"
                  class="form-control"
                  [formControl]="remarkFormGroup.get(field.placeholder)"
                  [validate]="remarkFormGroup.get(field.placeholder).invalid || remarkFormGroup.get(field.placeholder).dirty || validateTriggered"
                  [onFocusListener]="true"
                  (click)="enableForm($event)"
                  [style.color]="!remarkFormGroup.get(field.placeholder).value ? '#9e9e9e' : ''"
                  (change)="onSelectChange($event.target.value, field.placeholder)">
                  <option value="" hidden>{{field.placeholder}}</option>
                  <option value="" style="color: #414a4c;">- Clear -</option>
                  <option *ngFor="let option of field.list" [value]="option">{{option}}</option>
                </select>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
</div>
