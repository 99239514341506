import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'specialServicesExist'
})
export class SpecialServicesExistPipe implements PipeTransform {

  transform(passengersInfo: any[], includedSpecialServices: any): boolean {
    let specialServicesExist = false;
    passengersInfo?.forEach(passenger => {
      if (passenger.specialServices?.length > 0 || (includedSpecialServices && includedSpecialServices[passenger.passengerType]?.length)) {
        specialServicesExist = true;
      }
    });
    return specialServicesExist;
  }

}
