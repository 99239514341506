import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from "./shared/guards/auth.guard";
import {PrivacyPolicyComponent} from "./privacy-policy/privacy-policy.component";
import {BookingComponent} from "./booking/booking.component";
import {NotAuthorizedComponent} from "./not-authorized/not-authorized.component";
import {NotFoundComponent} from "./not-found/not-found.component";
import {MaintenanceComponent} from "./maintenance/maintenance.component";
import {KeycloakGuard} from "./shared/guards/keycloak-guard";
import { NotFoundRedirectGuard } from "./shared/guards/not-found-redirect.guard";

const routes: Routes = [
  // {
  //   path: 'search',
  //   canActivate: [AuthGuard],
  //   loadChildren: './dashboard/dashboard.module#DashboardModule',
  // },
  // {
  //   path: 'orders',
  //   canActivate: [AuthGuard],
  //   loadChildren: './order/order.module#OrderModule',
  // },
  // {
  //   path: 'subagencies',
  //   canActivate: [AuthGuard],
  //   loadChildren: './order/order.module#OrderModule',
  // },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
  },
  {
    path: 'offer/:offerID',
    canActivate: [AuthGuard, KeycloakGuard],
    component: BookingComponent
  },
  {
    path: 'offer/:offerID/:travelers/:od',
    canActivate: [AuthGuard, KeycloakGuard],
    component: BookingComponent
  },
  {
    path: 'offer/:owner/:offerID/:travelers/:od',
    canActivate: [AuthGuard, KeycloakGuard],
    component: BookingComponent
  },
  {
    path: 'not-authorized',
    canActivate: [AuthGuard],
    component: NotAuthorizedComponent
  },
  {
    path: 'not-found',
    canActivate: [AuthGuard],
    component: NotFoundComponent
  },
  {
    path: 'maintenance',
    component: MaintenanceComponent
  },
  { path: '**', component: NotFoundComponent, canActivate: [NotFoundRedirectGuard] },
];


@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
