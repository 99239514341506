<div class="icon-link"
     [ngClass]="isCollapsed ? 'collapsed' : 'default'"
     [ngbPopover]="descriptions"
     [triggers]="'hover'"
     placement="top"
     container="body"
     popoverClass="fare-info-popover"
>
  <ng-container *ngIf="!disclosureCategory.toLowerCase().includes('carryon'); else carryOn">
    <i class="material-symbols-outlined">
      {{disclosureCategory | getMaterialIconsPipe}}
    </i>
  </ng-container>
  <ng-template #carryOn>
    <img class="d-flex cursor-pointer" src="../../../../assets/img/illustrations/carryOnDarkGray.svg">
  </ng-template>
  <div *ngIf="isCollapsed" class="text-left label mr-2">
    {{disclosureCategory | replace: '_' : ' ' | titlecase}}
  </div>
</div>

<ng-template #descriptions>
  <b class="description-category">{{disclosureCategory.replace('_', ' ')}}</b>
  <div class="description-text" *ngFor="let disclosure of disclosures">
    <div [innerHTML]="disclosure.text | replace: '\n':'<br\/>'"></div>
  </div>
</ng-template>
