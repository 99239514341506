<div class="dropdown-container">
    <div class="custom-dropdown" ngbDropdown #dropdown="ngbDropdown">
        <button class="dropdown-button" ngbDropdownToggle><div>{{ label }}</div></button>
        <div ngbDropdownMenu>
            <div class="option-container" *ngFor="let option of options; first as first">
                <a
                    [class.clear]="first"
                    ngbPopover="{{ option.description }}"
                    popoverClass="popover"
                    triggers="mouseenter:mouseleave"
                    placement="right"
                    (click) = "selectOptions(option); dropdown.close()"
                    ngbDropdownItem>
                    {{ option.value }}
                </a>
            </div>
        </div>
      </div>
</div>
