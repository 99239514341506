<ng-container *ngIf="isCardView">
  <div class="list-wrapper" *ngIf="flyerNumbers.length">
    <div class="list-item list-header">
      <div class="one">Airline</div>
      <div class="two">Member Number</div>
      <div class="three">Action</div>
    </div>
    <div class="list-container">
      <div *ngFor="let fn of flyerNumbers; let idx = index"
           class="list-item"
           [class.selected-to-edit]="idx === editIdx"
      >
        <div class="one">{{fn.alliance}}</div>
        <div class="two">{{fn.member_number}}</div>
        <div class="three">
          <div class="c-btn-wrapper" style="justify-content: flex-start">
            <div class="c-btn">
              <button
                class="btn btn-sm btn-secondary remove-btn"
                (click)="openRemoveModal(removeModal, fn, idx, '400px')">Remove</button>
            </div>
            <div class="c-btn ml-10">
              <button
                class="btn btn-sm btn-outline-primary"
                (click)="startEditFrequentNumber(idx, fn)">Edit</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="isShowForm || showAddedEditedLoader">
    <div class="c-form" [formGroup]="frequentFlyerNumberForm">
      <div class="c-form-row">
        <div class="form-group form-group-item col-md-5">
          <div class="label-title">
            <label><span class="text-primary"><b>*</b></span> Airline </label>
          </div>
          <div class="item-control">
            <app-select-airline
              placeholder="Select Airline"
              [formControlName]="formControlNames.alliance"
              [validate]="validateTriggered"
              [isDisabled]="editIdx > -1">
            </app-select-airline>
          </div>
        </div>

        <div class="form-group form-group-item col-md-5" >
          <div class="label-title">
            <label><span class="text-primary"><b>*</b></span> Member Number</label>
          </div>
          <div class="text-left item-control">
            <input [validate]="validateTriggered"
                   type="text"
                   [formControlName]="formControlNames.member_number"
                   class="form-control"
                   autocomplete="false"
                   placeholder="Type in member number..."
                   required/>
          </div>
        </div>
      </div>
      <div class="form-group-item form-btn">
        <app-loader *ngIf="showAddedEditedLoader"></app-loader>
        <button (click)="cancelFrequentNumber()" class="btn btn-secondary">Cancel</button>
        <button [disabled]="frequentFlyerNumberForm.invalid" (click)="this.editIdx > -1 ? finishEditFrequentNumber() : addFrequentNumber()" class="btn btn-primary">{{this.editIdx > -1 ? 'Save' : 'Add'}}</button>
      </div>
    </div>
  </ng-container>

  <div class="d-flex align-items-center"
       [ngClass]="flyerNumbers.length ? 'justify-content-end  mt-4' : 'justify-content-between'"
       *ngIf="!isShowForm">
    <div class="no-lists" *ngIf="!flyerNumbers.length">
      No frequent flyer numbers yet
    </div>
    <div class="header-nav">
      <span (click)="showForm()" *ngIf="!showAddedEditedLoader" class="btn btn-primary float-right">Add frequent flyer number</span>
    </div>
  </div>
</ng-container>

<ng-template #removeModal let-c="close" let-d="dismiss">
  <div class="modal-card card">
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="card-header-title">Remove discount code</h3>
        </div>
      </div>
    </div>
    <div class="card-body">
      Are you sure you want to remove {{ removeFrequentNumberInfo.member_number }} frequent flyer number for {{ removeFrequentNumberInfo.alliance }} airline ?
    </div>
    <div class="card-footer">
      <div class="btn-wrapper">
        <button (click)="removeFrequentNumber(c);" class="btn btn-primary float-right ml-3">Remove</button>
        <button (click)="d('closed')" class="btn btn-tonal-primary float-right"
                [disabled]="showRemovedLoader">Cancel</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-container *ngIf="!isCardView">
  <div class="form-row align-items-center">
    <div class="form-group form-group-sm col-4">
      <h3 class="mb-0">Frequent Flyer Numbers</h3>
    </div>
    <div class="form-group form-group-sm col-8">
      <button class="btn add-btn" (click)="toggleFFN()">
        <i class="material-symbols-outlined" [@rotate]="animationState">
          add
        </i>
      </button>
    </div>

    <ng-container [formGroup]="frequentFlyerNumberForm" *ngIf="isShowForm">
      <div class="form-group-sm col-md-3">
        <div class="label-title">
          <label><span class="text-primary"><b>*</b></span> Airline </label>
        </div>
        <div class="item-control">
          <app-select-airline
            placeholder="Select Airline"
            [formControlName]="formControlNames.alliance"
            [validate]="validateTriggered"
            [isDisabled]="editIdx > -1">
          </app-select-airline>
        </div>
      </div>

      <div class="form-group-sm col-md-3">
        <div class="label-title">
          <label><span class="text-primary"><b>*</b></span> Member Number</label>
        </div>
        <div class="text-left item-control">
          <input [validate]="validateTriggered"
                 type="text"
                 [formControlName]="formControlNames.member_number"
                 class="form-control"
                 autocomplete="false"
                 placeholder="Type in member number..."
                 required/>
        </div>
      </div>

      <div class="form-group-sm col-md-2">
        <label>&nbsp;</label>
        <button class="btn btn-tonal-primary w-100"
                [disabled]="frequentFlyerNumberForm.invalid"
                (click)="addFrequentNumber(); animationState = !animationState">
          Add
        </button>
      </div>
    </ng-container>
  </div>

  <div class="form-row">
    <div class="form-group-sm col-md-12">
      <div class="d-flex gap-15">
        <ng-container *ngFor="let fn of flyerNumbers; let idx = index">
          <div class="flyer-number">
            {{fn.alliance}}{{fn.member_number}}
            <i class="material-icons" (click)="removeFrequentNumberInfo = fn; removeFrequentNumber()">close</i>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>

