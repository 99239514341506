import {Pipe, PipeTransform} from "@angular/core";

@Pipe({ name: 'getSegmentRouteByIDPipe' })
export class GetSegmentRouteByIDPipe implements PipeTransform {
  transform(list: any[], segID: string, segmentList = false) {
    let result;
    let segmentsArr = [];
    let segmentIdsArr = segID.split(' ');

    if (list && list.length) {
      if (segmentList) {
        list.map(seg => {
          if (seg.segmentID === segID) {
            result = `${seg.originDestination.departure.airportCode} → ${seg.originDestination.arrival.airportCode}`;
          }
        });
      } else if (segmentIdsArr && segmentIdsArr.length > 1) {
        list.map(flight => {
          flight.segments.map(seg => {
            segmentIdsArr.map(t => {
              if (t === seg.segmentID) {
                let flightSegment = `${seg.originDestination.departure.airportCode} → ${seg.originDestination.arrival.airportCode}`;
                segmentsArr.push(flightSegment)
              }
            });
          });
        });
        result = segmentsArr.join('\n');
      } else {
        list.map(flight => {
          flight.segments.map(seg => {
            if (seg.segmentID === segID) {
              result = `${seg.originDestination.departure.airportCode} → ${seg.originDestination.arrival.airportCode}`;
            }
          });
        });
      }
    }

    return result;
  }
}
