<ng-container *ngIf="mode.default">
  <div class="fare-rules-default-container-not-collapsed" *ngIf="!isCollapsed">
    <ng-container [ngTemplateOutlet]="changeStatusIcon" [ngTemplateOutletContext]="{size: 32, showPopover: true}"></ng-container>
    <ng-container [ngTemplateOutlet]="cancelStatusIcon" [ngTemplateOutletContext]="{size: 32, showPopover: true}"></ng-container>
  </div>

  <div class="fare-rules-default-container-collapsed" *ngIf="isCollapsed">
    <div class="fare-rules-status" *ngIf="fareRulesStatus.changeStatus">
      <ng-container [ngTemplateOutlet]="changeStatusIcon" [ngTemplateOutletContext]="{size: 32}"></ng-container>
      <div class="label">Change</div>
      <div>{{fareRulesStatus.changeDescription}}</div>
    </div>

    <div class="fare-rules-status" *ngIf="fareRulesStatus.cancelStatus">
      <ng-container [ngTemplateOutlet]="cancelStatusIcon" [ngTemplateOutletContext]="{size: 32}"></ng-container>
      <div class="label">Cancel</div>
      <div>{{fareRulesStatus.cancelDescription}}</div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="mode.simple">
  <div class="status-section" *ngIf="fareRulesCapacity">
    <div *ngIf="fareRulesStatus.changeStatus">
      <div [ngbPopover]="popoverChange" [triggers]="'hover'" placement="top" container="body"
        popoverClass="fare-info-popover">
        <div class="fare-rules-simple-status" [ngStyle]="{
                    'background' : 'linear-gradient(180deg , ' + fareRulesStatus.changeColor.secondaryColor +' 0%, ' + fareRulesStatus.changeColor.primaryColor +' 100%)'
                    }">
        </div>
      </div>
    </div>
    <div *ngIf="fareRulesStatus.cancelStatus" [ngbPopover]="popoverCancel" [triggers]="'hover'" placement="top"
      container="body" popoverClass="fare-info-popover">
      <div class="fare-rules-simple-status"
        [ngStyle]="{
                    'background' : 'linear-gradient(180deg , ' + fareRulesStatus.cancelColor.secondaryColor +' 0%, ' + fareRulesStatus.cancelColor.primaryColor +' 100%)'}">
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="mode.perLeg">
  <div class="fare-rules-per-leg-container">
    <div *ngIf="fareRulesStatus.changeStatus">
      <div class="label">Change</div>
      <div class="status-container">
        <ng-container [ngTemplateOutlet]="changeStatusIcon" [ngTemplateOutletContext]="{size: 26}"></ng-container>
        <div class="status-value">{{fareRulesStatus.changeStatus}}</div>
      </div>
    </div>
    <div *ngIf="fareRulesStatus.cancelStatus">
      <div class="label">Cancel</div>
      <div class="status-container">
        <ng-container [ngTemplateOutlet]="cancelStatusIcon" [ngTemplateOutletContext]="{size: 26}"></ng-container>
        <div class="status-value">{{fareRulesStatus.cancelStatus}}</div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="mode.entireTrip">
  <hr *ngIf="showLines && (fareRulesStatus.changeStatus || fareRulesStatus.cancelStatus)">
  <div class="fare-rules-entire-trip-container" *ngIf="fareRulesCapacity">
    <div *ngIf="fareRulesStatus.changeStatus" class="status-container">
      <div class="label">Change</div>
      <ng-container [ngTemplateOutlet]="changeStatusIcon" [ngTemplateOutletContext]="{size: 32}"></ng-container>
      <div class="status-value">{{fareRulesStatus.changeStatus}}</div>
    </div>
    <div *ngIf="fareRulesStatus.cancelStatus" class="status-container">
      <div class="label">Cancel</div>
      <ng-container [ngTemplateOutlet]="cancelStatusIcon" [ngTemplateOutletContext]="{size: 32}"></ng-container>
      <div class="status-value">{{fareRulesStatus.cancelStatus}}</div>
    </div>
  </div>
  <hr *ngIf="showLines && (fareRulesStatus.changeStatus || fareRulesStatus.cancelStatus)">
</ng-container>

<ng-template #changeStatusIcon let-size="size" let-showPopover="showPopover">
  <div [ngSwitch]="fareRulesStatus.changeStatus"
       [ngbPopover]="showPopover ? popoverChange: null"
       [triggers]="'hover'"
       placement="top"
       container="body"
       popoverClass="fare-info-popover">
    <img *ngSwitchCase="FARE_RULES_STATUS.FEE" [ngStyle]="{'width': size + 'px'}" src="./assets/img/illustrations/changeFee.svg">
    <img *ngSwitchCase="FARE_RULES_STATUS.ALLOWED" [ngStyle]="{'width': size + 'px'}" src="./assets/img/illustrations/changeYes.svg">
    <img *ngSwitchCase="FARE_RULES_STATUS.DISALLOWED" [ngStyle]="{'width': size + 'px'}" src="./assets/img/illustrations/changeNo.svg">
  </div>
</ng-template>

<ng-template #cancelStatusIcon let-size="size" let-showPopover="showPopover">
  <div [ngSwitch]="fareRulesStatus.cancelStatus"
       [ngbPopover]="showPopover ? popoverCancel: null"
       [triggers]="'hover'"
       placement="top"
       container="body"
       popoverClass="fare-info-popover">
    <img *ngSwitchCase="FARE_RULES_STATUS.FEE" [ngStyle]="{'width': size + 'px'}" src="./assets/img/illustrations/cancelFee.svg">
    <img *ngSwitchCase="FARE_RULES_STATUS.ALLOWED" [ngStyle]="{'width': size + 'px'}" src="./assets/img/illustrations/cancelYes.svg">
    <img *ngSwitchCase="FARE_RULES_STATUS.DISALLOWED" [ngStyle]="{'width': size + 'px'}" src="./assets/img/illustrations/cancelNo.svg">
  </div>
</ng-template>

<ng-template #popoverCancel>
  <div><strong>Cancel: </strong> {{ fareRulesStatus.cancelDescription }}</div>
</ng-template>

<ng-template #popoverChange>
  <div><strong>Change: </strong>{{ fareRulesStatus.changeDescription }}</div>
</ng-template>

