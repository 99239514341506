<div class="row pb-5" *ngIf="corporate?.name">
  <div class="col">
    <div class="d-flex align-items-center justify-content-between mb-4">
      <div class="d-flex align-items-center gap-10">
        <span class="material-symbols-outlined blue-color" *ngIf="!isPDFExtract">
          corporate_fare
        </span>
        <h3 class="mb-0 h3-label">Corporate</h3>
      </div>
      <div class="expand-icon-wrapper" (click)="isCorporateOpened = !isCorporateOpened" *ngIf="!isPDFExtract">
        <span class="material-symbols-outlined" [@rotate]="!isCorporateOpened">
          expand_less
        </span>
      </div>
    </div>
    <div class="info-card" [@collapse]="!isCorporateOpened">
      <div class="info-card-body">
        <div class="blue-container"></div>
        <div class="info-container">{{corporate.name}}</div>
      </div>
    </div>
  </div>
</div>
