import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HubAgencyService } from '../../shared/services/hub-agency.service';
import { NotificationService } from '../..//shared/services/notification.service';
import { Agent } from '../../shared/types/agent';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {LocalStorageService} from "../../shared/services/local-storage.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Agency} from "../../shared/types/agency";
import { ErrorAlert } from '../../shared/models/error-alert';
import { HelpersService } from '../../shared/services/helpers.service';

@Component({
  selector: 'app-agent-list',
  templateUrl: './agent-list.component.html',
  styleUrls: ['./agent-list.component.scss']
})
export class AgentListComponent implements OnInit, OnDestroy {
  @Input() agents: Agent[] = [];
  @Input() copiedAgents: Agent[] = [];
  @Input() isAddNewAgentAvailable = true;
  @Input() agency: Agency;
  @Output() emitUpdateAgents = new EventEmitter();
  @ViewChild('removeAgentInfoModal', {static: true}) removeAgentInfoModal: ElementRef;

  private ngUnsubscribe$: Subject<void> = new Subject<void>();
  selectedAgent: Agent;
  agentsError: ErrorAlert = new ErrorAlert();
  errors = {
    email: '',
    role: '',
    error: {summary: ''}
  };
  model: any = {
    email: '',
    role: ''
  };

  agentsRoles = ['Issuer', 'Manager', 'Booker', 'Consultant'];

  notifications = {
    allData: 'Agent data successfully updated',
  };

  agentHasChanges = {};

  // pagination
  page = 1;
  pageSize = 15;

  constructor(public agencyService: HubAgencyService,
              public modalService: NgbModal,
              private _notificationSvc: NotificationService,
              public ls: LocalStorageService,
              private route: ActivatedRoute,
              private router: Router,
              private helpers: HelpersService
  ) {}

  ngOnInit(): void {
    this.parseUrl();
  }

  openModal(content) {
    this.modalService.open(content).result.then((result) => {

    }, (reason) => {

    });
  }

  onStatusChange(value: boolean, agent: Agent) {
    agent.status = !!value ? 'active' : 'inactive';
  }

  onChange(index: number) {
    this.agentHasChanges[index] = true;
  }

  addAgent(c) {
    let body = {...this.model};
    this.agencyService.addAgent(body)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((response: any) => {
        let res = response.body;
        if (res.status === 'OK') {
          this._notificationSvc.success('Success', `Agent ${this.model.email} has been added`,  0);
          c('ok');
          this.emitUpdateAgents.emit();
        } else {
          this.errors = res;
        }
      }, error => this.agentsError = this.helpers.getError(error));
  }

  onRemoveAgentClick(agent: Agent) {
    if (this.ls.isRemoveAgentInfoModalSkipped) {
      this.removeAgent(agent);
    } else {
      this.selectedAgent = agent;
      this.openModal(this.removeAgentInfoModal);
    }
  }

  removeAgent(agent: Agent) {
    let body: any = {
      id: agent.id
    };
    if (this.agency) {
      body.agency_id = this.agency.id;
    }

    this.agencyService.remove(body)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((response: any) => {
        this.successSave('Agent removed successfully');
        this.emitUpdateAgents.emit();
      }, err => this._notificationSvc.error(err.error?.status, err.error?.error, err.status));
  }

  saveChanges(agent, idx: number) {
    let body: any = {
      id: agent.id,
      agent_id: agent.agent_id,
      role: agent.role,
      status: agent.status
    };
    if (this.agency) {
      body.agency_id = this.agency.id;
    }

    this.agencyService.update(body)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((response: any) => {
        let res = response.body;
        if (res.status === 'OK') {
          const initialAgent = this.copiedAgents.find(initialAgent => initialAgent.id === agent.id);
          if (initialAgent) {
            Object.assign(initialAgent, agent);
          }
          this.agentHasChanges[idx] = false;
          this.successSave(this.notifications.allData);
        } else {
          this.errors = res;
          if (this.errors?.error?.summary) {
            this._notificationSvc.error('Error', this.errors.error.summary);
          }
        }
      }, (err) => {
        this._notificationSvc.error(err.error?.status, err.error?.error, err.status);
      });
  }

  successSave(message: string) {
    this._notificationSvc.clearNotifications();
    this._notificationSvc.success('Success', `${message}`);
  }

  cancelChanges(agent: Agent, idx: number) {
    const initialAgent = this.copiedAgents.find(initialAgent => initialAgent.id === agent.id);
    if (initialAgent) {
      Object.assign(agent, initialAgent);
    }
    this.agentHasChanges[idx] = false;
  }

  parseUrl() {
    let params = this.route.snapshot.queryParams;
    this.page = parseInt(params.page) || 1;
    this.pageSize = parseInt(params.pageSize) || 15;
  }

  onPageChange() {
    let params = {};
    params['page'] = this.page;
    params['pageSize'] = this.pageSize;
    this.router.navigate([], {queryParams: params});
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

}
