<ng-container>
  <div class="select-container">
    <div>
      <input type="text"
             [value]="value"
             [placeholder]="placeholder"
             [attr.disabled]="isDisabled ? true : null"
             (keyup)="onKeyup($event.target.value)"
             (focus)="openDropdown()"
             (blur)="onBlur()">
    </div>
    <ng-container *ngIf="isShowDropdown">
      <cdk-virtual-scroll-viewport itemSize="50" class="dropdown-airlines" minBufferPx="20" maxBufferPx="30">
        <div
          class="dropdown-row"
          *cdkVirtualFor="let airline of airlines | filterAirlines: searchPhrase : isProviders; last as last"
          [ngClass]="{'border-bottom': !last}"
          (click)="selectAirline(airline)"
        >
          <div class="airline-logo">
            <app-airline-img
              [airlineID]="airline.id"
            ></app-airline-img>
          </div>
          <div class="airline">{{airline.id}}</div>
          <div class="airline-name">{{airline.name}}</div>
        </div>
      </cdk-virtual-scroll-viewport>
    </ng-container>
  </div>
</ng-container>
