<div class="container" *ngIf="frontendSelectedDependency?.showPrivacy">
  <div class="row">
    <div class="col-lg-12 d-flex justify-content-between mb-3">
      <div class="version">{{ currentVersion || versionService.version}} <ng-container *ngIf="ls.appVersion">(API Version: {{ls.appVersion}})</ng-container></div>
      <a class="info-link" href="/privacy-policy">Privacy policy</a>
    </div>
  </div>
</div>

<div class="container">
  <div class="row relative">
    <div class="col text-center copy">
      Powered by <a href="https://airgateway.com/">AirGateway</a>
    </div>
  </div>
</div>


