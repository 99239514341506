<ng-container *ngIf="useNewDesign">
  <div class="row pb-5">
    <div class="col">
      <app-itinerary-details
        [flights]="offer.flights"
        [disclosures]="offer.disclosures"
        [fareNames]="fareNames"
        [passengers]="offer.passengers">
        <div class="pt-4" *ngIf="offer.fareRules">
          <app-fare-rules-capacity-info
            [mode]="{entireTrip: true}"
            [fareRulesCapacity]="offer.fareRules"
            [showLines]="false">
          </app-fare-rules-capacity-info>
        </div>
      </app-itinerary-details>
    </div>
  </div>
</ng-container>

<div class="row" style="max-width: 800px; margin: auto" *ngIf="!useNewDesign">
  <div class="col flight-detail-group" [ngClass]="{'no-bottom-border': !(offer.disclosures && offer.disclosures.length && isDateChange)}">
    <ng-container *ngFor="let od of offer.flights; let index=index">
      <div class="row pb-3">
        <div class="col">
          <span class="flight-route-lg" [appTooltip]="{airportCode: od.departure.airportCode, terminalName:od.departure.terminalName}">
            {{od.departure.airportCode}}
          </span>
          <span>→</span>
          <span class="flight-route-lg" [appTooltip]="{airportCode: od.arrival.airportCode, terminalName:od.arrival.terminalName }">
            {{od.arrival.airportCode}}
          </span>
          <div class="small-text" *ngIf="od.duration">
            Travel time: <span class="flight-route-duration">{{ od.duration | parseDuration }}</span>
          </div>
        </div>
        <div class="col-8">
          <app-main-flight-segment
            *ngFor="let flightSegment of od.segments; index as segIdx"
            [flightIdx]="index"
            [segIdx]="segIdx"
            [flightSegment]="flightSegment"
            [fareNames]="fareNames"
            [flightKey]="od.key"
            [disclosures]="offer.disclosures"
            [oldFlightSegment]="oldOffer ? oldOffer.flights[index].segments[segIdx] : null"
            [oldFareNames]="oldFareNames"
          ></app-main-flight-segment>
          <ng-container *ngIf="od.baggageAllowance">
            <div class="baggage-container">
              <app-baggage-allowance-info
                [baggageAllowance]="od.baggageAllowance"
                [passengers]="offer.passengers">
              </app-baggage-allowance-info>
            </div>
          </ng-container>
        </div>
      </div>
      <hr class="leg-splitter" *ngIf="(offer.flights.length > 1) && (index !== offer.flights.length - 1)">
    </ng-container>
    <ng-container *ngIf="offer.fareRules">
      <app-fare-rules-capacity-info
        [mode]="{entireTrip: true}"
        [fareRulesCapacity]="offer.fareRules">
      </app-fare-rules-capacity-info>
    </ng-container>
  </div>
</div>

<div class="row pb-5" *ngIf="offer.otherOffers">
  <div class="col-lg-12">
    <div class="d-flex align-items-center justify-content-between mb-4">
      <div class="d-flex align-items-center gap-10">
        <span class="material-symbols-outlined blue-color">
          airplane_ticket
        </span>
        <h3 class="mb-0 h3-label">Fares</h3>
      </div>
      <div class="expand-icon-wrapper" (click)="isFaresOpened = !isFaresOpened">
        <span class="material-symbols-outlined" [@rotate]="!isFaresOpened">
          expand_less
        </span>
      </div>
    </div>
  </div>
  <div class="col-lg-12" [@collapse]="!isFaresOpened">
    <app-options
      [showOptions]="true"
      [simpleOffers]="offer.otherOffers | filterSimpleOffer"
      [desireOffer]="offer"
      [isReshop]="true"
      (onSelectedOffer)="onSelectedOffer($event)"
    ></app-options>
  </div>
</div>

<ng-container *ngIf="fareRules?.length">
  <app-fare-rules
    [fareRules]="fareRules"
    [offer]="offer"
    [useNewDesign]="true">
  </app-fare-rules>
</ng-container>

<ng-container *ngIf="isShowDisclosureTable && offer.disclosures && offer.flights">
  <app-disclosure-table
    [disclosures]="offer.disclosures"
    [flights]="offer.flights"
    [useNewDesign]="true"
  >
  </app-disclosure-table>
</ng-container>

<ng-container *ngIf="!!seatsPerSegment">
  <div class="row pl-2 pr-2 mt-2">
    <div class="col-12 mt-2">
      <h4 class="seats-h4">Seats</h4>
      <ng-container *ngFor="let segmentSeats of (seatsPerSegment | pairs)">
        <div><span class="segment-name">{{ segmentSeats[1].name }}:</span>
          <ul class="seats-ul">
            <li *ngFor="let seat of segmentSeats[1].seats">
              <span class="seat-number">{{seat.location.column + seat.location.row}}</span>
              - <span class="seat-price">{{seat?.price?.consumer?.total | number :'1.2-2'}} {{seat?.price?.consumer?.currency}}</span>
              <span class="flight-number" *ngIf="seat.segment"> ({{seat.segment}})</span>
              <a href="#"
                 class="material-icons seat-remove-icon my-auto"
                 (click)="$event.preventDefault(); removeSeat(segmentSeats[0], seat)"
              >close</a>
            </li>
          </ul>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
